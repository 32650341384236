import { useAuth, useOrganization } from '@clerk/clerk-react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { fetchResolve, postResolve } from './helpers'

import type { LicenseInfo } from '@/types/LicenseInfo'

interface GetCheckoutUrlParams {
  quantity: number
  promotekitReferral?: string
}

export const useGetCheckoutUrl = () => {
  const { getToken } = useAuth()
  const { organization } = useOrganization()

  return useMutation({
    mutationFn: async (params: GetCheckoutUrlParams) => {
      const response = await postResolve(
        `/organization/${organization!.id}/billing/checkout`,
        params,
        getToken,
      )
      return response.url as string
    },
  })
}

export const useGetPortalUrl = () => {
  const { getToken } = useAuth()
  const { organization } = useOrganization()

  return useMutation({
    mutationFn: async () => {
      const response = await postResolve(
        `/organization/${organization!.id}/billing/portal`,
        {},
        getToken,
      )
      return response.url as string
    },
  })
}

export const useFetchLicenseInfo = () => {
  const { getToken } = useAuth()
  const { organization } = useOrganization()

  return useQuery<LicenseInfo>({
    queryKey: ['useFetchLicenseInfo', organization?.id],
    queryFn: async () =>
      fetchResolve(
        `/organization/${organization!.id}/billing/license-info`,
        getToken,
      ),
  })
}
