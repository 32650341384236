import { useState } from 'react'
import moment from 'moment'
import { DateRange } from 'react-day-picker'

import { Button } from '@/components/ui/button'
import { AvatarWithFallback } from '@/components/ui/avatar'
import { Muted, H5 } from '@/components/ui/typography'
import { Loading } from '@/components/ui/loading'
import { DatePicker } from '@/components/common/date-picker'
import { FeedbackSessionModal } from '@/components/feedback-session-modal'
import { TooltipWithIcon } from '@/components/common/tooltip'
import { LayoutTile } from '@/components/layout'
import { LayoutHeader } from '@/components/common/layout-header'
import { FeedbackPointsSheet } from '@/components/feedback-points-sheet'

import { useFetchFeedbackSessions } from '@/services/api/feedbackSessions.api'

import { FeedbackSessionOverview } from '@/types/FeedbackSessions'
import { ExternalLink } from 'lucide-react'
import {
  getUserAvatarFallback,
  getUserDisplayName,
} from '@/services/utils/formatters'
import { useLocalStorage } from '@/hooks/useLocalStorage'
import { ErrorTile } from '@/components/common/error-tile'
import { getCurrentDateRange, StoredDateState } from '@/types/DateState'

interface FeedbackSessionOverviewProps {
  data: FeedbackSessionOverview
  dateRange: DateRange
}

const FeedbackSessionOverviewItem = ({
  data,
  dateRange,
}: FeedbackSessionOverviewProps) => {
  const { user } = data
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isSheetOpen, setIsSheetOpen] = useState(false)

  const handleOpenClick = async () => {
    setIsModalOpen(true)
  }

  return (
    <div className="feedback-session-row flex flex-col sm:flex-row sm:items-center justify-between py-4 space-y-4 sm:space-y-0 border-b">
      <div className="flex items-center space-x-4">
        <AvatarWithFallback
          className="h-12 w-12 border-2 border-primary"
          image={user.image}
          fallback={getUserAvatarFallback(data.user)}
        />
        <div>
          <H5>{getUserDisplayName(data.user)}</H5>
          <div className="flex items-center pt-1 text-sm">
            <Muted>Complete feedback:&nbsp;</Muted>
            <span
              className={
                data.feedbackPointsFromMe < 3
                  ? 'text-red-300'
                  : 'text-green-500'
              }
            >
              {data.feedbackPointsFromMe} from you
              {data.canIncludeAll && (
                <>, {data.feedbackPointsFromOthers} from others</>
              )}
            </span>
            <span className="ml-1">
              <TooltipWithIcon
                content="We recommend at least 3 feedback points for an optimal report."
                type="info"
              />
            </span>
          </div>
        </div>
      </div>
      <div className="flex items-center gap-2">
        {data.canIncludeAll && (
          <Button
            onClick={() => setIsSheetOpen(true)}
            variant="ghost"
            size="sm"
          >
            View Stats
          </Button>
        )}
        <Button
          onClick={handleOpenClick}
          className="w-full sm:w-auto"
          disabled={
            !(data.feedbackPointsFromOthers + data.feedbackPointsFromMe)
          }
        >
          Open Report
        </Button>
      </div>
      <FeedbackSessionModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        feedbackSessionOverview={data}
        title={`Feedback Session for ${getUserDisplayName(data.user)}`}
      />
      <FeedbackPointsSheet
        isOpen={isSheetOpen}
        setIsOpen={setIsSheetOpen}
        user={data.user}
        dateRange={dateRange}
      />
    </div>
  )
}

export const FeedbackSessionsPage = () => {
  const defaultDateState: StoredDateState = {
    dateRange: {
      from: moment().startOf('quarter').toDate(),
      to: new Date(),
    },
    preset: 'this-quarter',
    isAllTime: false,
  }

  const [storedDateState, setStoredDateState] =
    useLocalStorage<StoredDateState>(
      'feedback-sessions.date-state',
      defaultDateState,
    )

  const dateRange = getCurrentDateRange(storedDateState)
  const {
    data: feedbackSessions = [],
    isLoading,
    error,
  } = useFetchFeedbackSessions({
    from: moment(dateRange.from).valueOf(),
    to: moment(dateRange.to).valueOf(),
  })

  if (error) return <ErrorTile message={error.message} />

  return (
    <LayoutTile>
      <LayoutHeader
        className="feedback-sessions-title"
        title="Your Feedback Sessions"
        description={
          <>
            Generate a report to be used in a feedback session (e.g.,
            performance review). If you are the manager/director of the person,
            you can also see the feedback from other colleagues.{' '}
            <a
              href="https://youtu.be/0SRBCCF-NaM"
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary hover:underline inline-flex items-center"
            >
              Watch our tutorial
              <ExternalLink size={16} className="ml-1" />
            </a>
          </>
        }
        rightSide={
          <DatePicker
            value={getCurrentDateRange(storedDateState)}
            selectedPreset={storedDateState.preset}
            onChange={(dateRange, preset, isAllTime) => {
              setStoredDateState({
                dateRange,
                preset,
                isAllTime,
              })
            }}
          />
        }
      />
      {isLoading ? (
        <div className="flex-1 w-full justify-center items-center flex">
          <Loading />
        </div>
      ) : !feedbackSessions.length ? (
        <div className="flex-1 w-full justify-center items-center flex">
          <Muted>No Feedback Sessions Available</Muted>
        </div>
      ) : (
        <div className="feedback-sessions-list">
          {feedbackSessions.map((fs) => (
            <FeedbackSessionOverviewItem
              key={`fs-${fs.user.id}`}
              data={fs}
              dateRange={dateRange}
            />
          ))}
        </div>
      )}
    </LayoutTile>
  )
}
