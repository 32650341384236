import { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { useAuth } from '@clerk/clerk-react'

import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/ui/form'
import { Textarea } from '@/components/ui/textarea'
import { H5, H6, Muted } from '@/components/ui/typography'
import { Icon } from '@/components/ui/icon'
import { SavedBadge } from '@/components/common/saved-badge'

import { useDebounce } from '@/hooks/useDebouncer'
import {
  useFetchSelfAssessment,
  useUpdateSelfAssessment,
} from '@/services/api/performanceReview.api'
import { SelfAssessmentSchema } from '@/lib/schema/performance-review.schema'

import { PerformanceReview, SelfAssessment } from '@/types/PerformanceReview'
import { PerformanceCycle } from '@/types/PerformanceCycle'

type SelfAssessmentSchemaType = z.infer<typeof SelfAssessmentSchema>

interface MeReviewSelfAssessmentProps {
  cycle: PerformanceCycle
  review: PerformanceReview | null
}

const SelfAssessmentViewer = ({
  assessment,
}: {
  assessment: SelfAssessment
}) => (
  <div className="space-y-4">
    <div>
      <H6>Objectives</H6>
      <Muted className="whitespace-pre-line">{assessment.objectives}</Muted>
    </div>
    <div>
      <H6>Values</H6>
      <Muted className="whitespace-pre-line">{assessment.values}</Muted>
    </div>
  </div>
)

export function MeReviewSelfAssessment({
  cycle,
  review,
}: MeReviewSelfAssessmentProps) {
  const [isEditing, setIsEditing] = useState(false)
  const [showSaved, setShowSaved] = useState(false)
  const { userId } = useAuth()

  const { data: selfAssessment, isLoading } = useFetchSelfAssessment(cycle.id)
  const { mutate: updateSelfAssessment } = useUpdateSelfAssessment()

  const form = useForm<SelfAssessmentSchemaType>({
    resolver: zodResolver(SelfAssessmentSchema),
    defaultValues: {
      performanceCycleId: cycle.id,
      objectives: selfAssessment?.objectives ?? '',
      values: selfAssessment?.values ?? '',
      userId: userId ?? '',
    },
  })

  const handleSubmitSelfAssessment = (values: SelfAssessmentSchemaType) => {
    setShowSaved(true)
    updateSelfAssessment({
      performanceCycleId: cycle.id,
      userId: userId!,
      objectives: values.objectives.trim(),
      values: values.values.trim(),
    })
  }

  const showForm = !selfAssessment || isEditing

  const debouncedSubmit = useDebounce(handleSubmitSelfAssessment, 200)
  useEffect(() => {
    const subscription = form.watch(() => {
      const values = form.getValues()
      form.trigger().then((isValid) => {
        if (isValid) {
          debouncedSubmit(values)
        }
      })
    })
    return () => subscription.unsubscribe()
  }, [form, debouncedSubmit])

  if (isLoading || review?.status === 'published') {
    return null
  }

  return (
    <div className="mb-6">
      <div className="flex justify-between items-center mb-2">
        <div className="flex gap-4 items-center">
          <H5>Self Assessment</H5>
          <SavedBadge show={showSaved} setShow={setShowSaved} />
        </div>
        {!!selfAssessment && (
          <Button
            variant="outline"
            size="sm"
            onClick={() => setIsEditing((prev) => !prev)}
          >
            <Icon
              name={isEditing ? 'Check' : 'Pencil'}
              className="h-4 w-4 mr-2"
            />
            {isEditing ? 'Done' : 'Edit'}
          </Button>
        )}
      </div>
      {showForm ? (
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(handleSubmitSelfAssessment)}
            className="space-y-4"
          >
            <FormField
              control={form.control}
              name="objectives"
              render={({ field }) => (
                <FormItem>
                  <H6>Objectives</H6>
                  <FormControl>
                    <Textarea
                      placeholder="Describe your achievements for every objective assigned to you. Be self-critical and honest."
                      rows={5}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="values"
              render={({ field }) => (
                <FormItem>
                  <H6>Values</H6>
                  <FormControl>
                    <Textarea
                      placeholder="Evaluate yourself in each of the company values, from 1-5, with a brief description of why you believe it is the right evaluation."
                      rows={5}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </form>
        </Form>
      ) : selfAssessment ? (
        <SelfAssessmentViewer assessment={selfAssessment} />
      ) : null}
    </div>
  )
}
