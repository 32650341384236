import { z } from 'zod'

export const TeamObjectiveValueSchema = z.object({
  value: z.number().nullish(),
  week: z.number().int().min(0),
  year: z.number().int().min(0),
})

export const TeamObjectiveOwnerSchema = z.object({
  id: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  emails: z.array(z.string()),
  image: z.string().nullable(),
})

export const TeamObjectiveTargetSchema = z.object({
  target: z.number().nullish().optional(),
  month: z.number().int().min(0).max(11),
  year: z.number().int().min(0),
})

export const TeamObjectivePatchValueSchema = TeamObjectiveValueSchema

export const TeamObjectiveCreateSchema = z.object({
  title: z.string().min(2).max(50),
  description: z.string().optional(),
  type: z.enum(['average', 'lastColumn', 'sum']),
  owners: z.array(TeamObjectiveOwnerSchema),
  performanceCycleId: z.string(),
})

export const TeamObjectivePatchSchema = TeamObjectiveCreateSchema.partial()

export type TeamObjectiveCreateSchemaType = z.infer<
  typeof TeamObjectiveCreateSchema
>
export type TeamObjectiveTargetSchemaType = z.infer<
  typeof TeamObjectiveTargetSchema
>
export type TeamObjectiveValueSchemaType = z.infer<
  typeof TeamObjectiveValueSchema
>
