import { useEffect, useRef, useState } from 'react'

import { Button } from '@/components/ui/button'
import { Icon } from '@/components/ui/icon'

interface RightBarProps {
  isVisible: boolean
  handleClose: () => void
  children?: React.ReactNode
}

export const RightBar = ({
  children,
  isVisible,
  handleClose,
}: RightBarProps) => {
  const rightBarRef = useRef<HTMLDivElement>(null)
  const [shouldRenderChildren, setShouldRenderChildren] = useState(isVisible)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as Node

      // Check if click is inside a popover
      const isInsidePopover = document
        .querySelector('[role="dialog"]')
        ?.contains(target)

      if (
        isVisible &&
        rightBarRef.current &&
        !rightBarRef.current.contains(target) &&
        !isInsidePopover
      ) {
        handleClose()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isVisible, handleClose])

  useEffect(() => {
    let timeoutId: NodeJS.Timeout

    if (isVisible) {
      setShouldRenderChildren(true)
    } else {
      timeoutId = setTimeout(() => {
        setShouldRenderChildren(false)
      }, 300)
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [isVisible])

  return (
    <div
      ref={rightBarRef}
      className={`
        z-50
        w-[300px] flex flex-col gap-4 bg-white border-l p-4
        fixed right-0 top-0 h-full
        transform transition-transform duration-300 ease-in-out
        ${isVisible ? 'translate-x-0' : 'translate-x-full'}
      `}
    >
      <Button
        className="absolute top-2 right-2"
        variant="ghost"
        size="icon"
        onClick={handleClose}
      >
        <Icon name="X" className="h-4 w-4" />
      </Button>
      {shouldRenderChildren && children}
    </div>
  )
}
