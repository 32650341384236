import { useState, useEffect, useMemo } from 'react'
import { useLocalStorage } from '@/hooks/useLocalStorage'

import { Loading } from '@/components/ui/loading'
import { Button } from '@/components/ui/button'
import { NewObjectiveModal } from '@/components/objectives/objective-modal'
import { Dialog } from '@/components/common/dialog'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { LayoutTile } from '@/components/layout'
import { ErrorTile } from '@/components/common/error-tile'
import { LayoutHeader } from '@/components/common/layout-header'
import { ObjectivesTable } from '@/components/objectives/objectives-table'
import { ScrollArea, ScrollBar } from '@/components/ui/scroll-area'
import { Icon } from '@/components/ui/icon'

import {
  useFetchTeamObjectives,
  useCreateObjective,
} from '@/services/api/objective.api'
import { useUserRole } from '@/hooks/useUserRole'
import { useFetchOrganizationPerformanceCycles } from '@/services/api/organization.api'
import { NoPerformanceCycleTile } from '@/components/common/no-performance-cycle'
import { useToast } from '@/hooks/use-toast'
import { TeamObjectiveCreateSchemaType } from '@/lib/schema/objective-form.schema'

interface StoredObjectivesState {
  selectedPerformanceCycle: string | undefined
}

export const ObjectivesPage = () => {
  const [addNewFormOpen, setAddNewFormOpen] = useState(false)
  const [copyModalOpen, setCopyModalOpen] = useState(false)
  const [isCopying, setIsCopying] = useState(false)
  const [storedState, setStoredState] = useLocalStorage<StoredObjectivesState>(
    'objectives.page-state',
    {
      selectedPerformanceCycle: undefined,
    },
  )
  const selectedPerformanceCycle = storedState.selectedPerformanceCycle
  const { isAdmin } = useUserRole()
  const { toast } = useToast()
  const { mutateAsync: createObjective } = useCreateObjective(
    selectedPerformanceCycle,
  )

  const {
    data: performanceCycles = [],
    isLoading: isLoadingPerformanceCycles,
  } = useFetchOrganizationPerformanceCycles()
  const {
    data: objectives = [],
    isLoading: isLoadingObjectives,
    error,
  } = useFetchTeamObjectives(selectedPerformanceCycle)

  const performanceCycle = useMemo(
    () =>
      performanceCycles.find((cycle) => cycle.id === selectedPerformanceCycle),
    [performanceCycles, selectedPerformanceCycle],
  )

  const previousCycle = useMemo(() => {
    const currentCycleIndex = performanceCycles.findIndex(
      (cycle) => cycle.id === selectedPerformanceCycle,
    )
    return currentCycleIndex > 0
      ? performanceCycles[currentCycleIndex - 1]
      : undefined
  }, [performanceCycles, selectedPerformanceCycle])

  const { data: previousObjectives = [] } = useFetchTeamObjectives(
    previousCycle?.id,
  )

  useEffect(() => {
    if (!selectedPerformanceCycle && performanceCycles.length > 0) {
      setStoredState({
        ...storedState,
        selectedPerformanceCycle: performanceCycles.at(-1)?.id,
      })
    } else if (
      selectedPerformanceCycle &&
      performanceCycles.length > 0 &&
      !performanceCycles.some((pc) => pc.id === selectedPerformanceCycle)
    ) {
      // If stored cycle ID doesn't exist in current cycles, reset to latest
      setStoredState({
        ...storedState,
        selectedPerformanceCycle: performanceCycles.at(-1)?.id,
      })
    }
  }, [performanceCycles, selectedPerformanceCycle, storedState, setStoredState])

  const handleNewObjective = () => {
    setAddNewFormOpen(true)
  }

  const handleCopyObjectives = async () => {
    try {
      setIsCopying(true)
      if (!previousCycle) {
        toast({
          title: 'Error!',
          description: 'No previous Performance Cycle found',
          variant: 'destructive',
        })
        return
      }

      // Copy each objective to the current cycle
      for (const objective of previousObjectives) {
        const newObjective: TeamObjectiveCreateSchemaType = {
          title: objective.title,
          description: objective.description,
          type: objective.type,
          owners: objective.owners,
          performanceCycleId: selectedPerformanceCycle!,
        }
        await createObjective(newObjective)
      }

      toast({
        title: 'Success',
        description: 'Objectives copied successfully',
      })
      setCopyModalOpen(false)
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to copy Objectives',
        variant: 'destructive',
      })
    } finally {
      setIsCopying(false)
    }
  }

  if (isLoadingPerformanceCycles) {
    return (
      <LayoutTile>
        <Loading containerClassName="w-full h-full flex justify-center items-center" />
      </LayoutTile>
    )
  }

  if (!performanceCycle) {
    return <NoPerformanceCycleTile />
  }

  if (error) return <ErrorTile message={error.message} />

  return (
    <LayoutTile>
      <LayoutHeader
        className="objectives-title"
        title="Objectives"
        description={
          <>
            Track and update your (or your team&apos;s) objectives.{' '}
            <a
              href="https://youtu.be/FtZNA67-A1o"
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary hover:underline inline-flex items-center"
            >
              Watch our tutorial
              <Icon name="ExternalLink" className="w-4 h-4 ml-1" />
            </a>
            <br />
            You can also send us your list of objectives in CSV/Excel format to{' '}
            <a
              href="mailto:hey@fidforward.com"
              className="text-primary hover:underline"
            >
              hey@fidforward.com
            </a>
            .
            <br />
            If you are not tracking your objectives weekly, you can use the Last
            Column aggregation and fill in only the last week.
          </>
        }
        rightSide={
          <Select
            value={selectedPerformanceCycle}
            onValueChange={(value) =>
              setStoredState({
                ...storedState,
                selectedPerformanceCycle: value,
              })
            }
          >
            <SelectTrigger className="w-[130px]">
              <SelectValue placeholder="Select cycle" />
            </SelectTrigger>
            <SelectContent>
              {performanceCycles.map((cycle) => (
                <SelectItem key={cycle.id} value={cycle.id}>
                  {cycle.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        }
      />
      <div className="flex gap-2">
        <Button onClick={handleNewObjective} className="w-fit">
          Add new Objective
        </Button>
        {isAdmin && (
          <Dialog
            isOpen={copyModalOpen}
            setIsOpen={setCopyModalOpen}
            trigger={
              <Button variant="outline" className="w-fit">
                Copy from previous Cycle
              </Button>
            }
            title="Copy Objectives"
            description="This will copy all objectives from the previous performance cycle to the current one. Weekly values and targets will not be copied. Are you sure you want to continue?"
            onConfirm={handleCopyObjectives}
            confirmText={isCopying ? 'Copying...' : 'Copy Objectives'}
            isConfirmDisabled={isCopying}
          />
        )}
      </div>
      {isLoadingObjectives ? (
        <Loading containerClassName="w-full h-full flex justify-center items-center" />
      ) : (
        <div className="relative max-w-full">
          <ScrollArea>
            <div className="min-w-[800px]">
              <ObjectivesTable
                objectives={objectives}
                performanceCycle={performanceCycle}
              />
            </div>
            <ScrollBar orientation="horizontal" />
          </ScrollArea>
        </div>
      )}
      {addNewFormOpen && (
        <NewObjectiveModal
          isOpen={addNewFormOpen}
          setIsOpen={setAddNewFormOpen}
          performanceCycleId={selectedPerformanceCycle!}
        />
      )}
    </LayoutTile>
  )
}
