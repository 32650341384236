import { getMonthId, MonthlyProgress } from '@/types/Objective'
import { TableHead, TableHeader, TableRow } from '@/components/ui/table'
import { ChevronDown, ChevronRight } from 'lucide-react'
import { Button } from '@/components/ui/button'
import { cn } from '@/lib/utils'

interface ObjectivesHeaderProps {
  months: MonthlyProgress[]
  expandedMonths: string[]
  onToggleMonth: (_year: number, _month: number) => void
}

export function ObjectivesHeader({
  months,
  expandedMonths,
  onToggleMonth,
}: ObjectivesHeaderProps) {
  return (
    <TableHeader>
      <TableRow>
        <TableHead className="w-[300px]">Objective</TableHead>
        <TableHead className="w-[100px]">Owner(s)</TableHead>
        <TableHead className="w-[100px]">Type</TableHead>
        {months.map((month) => {
          const isExpanded = expandedMonths.includes(
            getMonthId(month.year, month.month),
          )

          return (
            <TableHead
              key={month.month}
              className={cn(
                'transition-all duration-200',
                isExpanded ? 'min-w-[200px]' : 'w-[100px]',
              )}
            >
              <div className="flex items-center justify-between">
                <span>{month.label}</span>
                <Button
                  variant="ghost"
                  size="icon"
                  className="h-6 w-6"
                  onClick={() => onToggleMonth(month.year, month.month)}
                >
                  {isExpanded ? (
                    <ChevronDown className="h-4 w-4" />
                  ) : (
                    <ChevronRight className="h-4 w-4" />
                  )}
                </Button>
              </div>
              {isExpanded && (
                <div className="flex gap-0.5">
                  {month.weeks.map((week) => (
                    <div
                      key={week.week}
                      className="w-[55px] text-center text-xs text-muted-foreground"
                    >
                      W{week.week}
                    </div>
                  ))}
                </div>
              )}
            </TableHead>
          )
        })}
      </TableRow>
    </TableHeader>
  )
}
