import { H4 } from '@/components/ui/typography'
import { Modal } from '@/components/common/modal'
import { ObjectiveForm } from '@/components/objectives/objective-form'

import { useCreateObjective } from '@/services/api/objective.api'

import type { SetState } from '@/types/globals'
import type { TeamObjectiveCreateSchemaType } from '@/lib/schema/objective-form.schema'

interface NewObjectiveModalProps {
  isOpen: boolean
  setIsOpen: SetState<boolean>
  performanceCycleId: string
}

export const NewObjectiveModal = ({
  isOpen,
  setIsOpen,
  performanceCycleId,
}: NewObjectiveModalProps) => {
  const { mutate: createObjective, isPending } =
    useCreateObjective(performanceCycleId)

  function onSubmit(values: TeamObjectiveCreateSchemaType) {
    createObjective(values, {
      onSuccess: () => setIsOpen(false),
    })
  }

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className="flex flex-col gap-2 min-h-[500px]">
        <H4 className="font-semibold">Add new Objective</H4>
        <div className="p-2">
          <ObjectiveForm
            onSubmit={onSubmit}
            defaultValues={{
              type: 'average',
              owners: [],
              performanceCycleId,
            }}
            isSaving={isPending}
          />
        </div>
      </div>
    </Modal>
  )
}
