import { cn } from '@/lib/utils'
import { WeekValue } from '@/components/objectives/week-value'
import { TableCell } from '@/components/ui/table'
import { Tooltip } from '@/components/common/tooltip'
import { AvatarWithFallback } from '@/components/ui/avatar'

import {
  MetricType,
  MonthlyProgress,
  type ObjectiveUI,
  type ObjectiveType,
  TYPE_LABELS,
  ObjectiveValue,
  getMonthId,
} from '@/types/Objective'
import {
  formatNumber,
  getUserAvatarFallback,
  getUserDisplayName,
} from '@/services/utils/formatters'
import moment from 'moment'

interface ObjectiveRowProps {
  objective: ObjectiveUI
  level?: number
  expandedMonths: string[]
  onClickWeek: (_value: ObjectiveValue) => void
}

function getLevelBackground(level: number): string {
  return level % 2 === 0 ? 'bg-white' : 'bg-muted/50'
}

export const formatValue = (
  value: number | undefined,
  metricType: MetricType,
) => {
  switch (metricType) {
    case 'currency':
      return `$${formatNumber(value)}`
    case 'percentage':
      return `${formatNumber(value)}%`
    default:
      return formatNumber(value)
  }
}

export function ObjectiveRow({
  objective,
  level = 0,
  expandedMonths,
  onClickWeek,
}: ObjectiveRowProps) {
  const handleRowClick = () => {
    const currentDate = moment()
    const currentMonth = objective.monthlyProgress.find(
      (month) =>
        month.year === currentDate.year() &&
        month.month === currentDate.month() + 1,
    )

    if (currentMonth?.weeks.length) {
      const currentWeek =
        currentMonth.weeks.find(
          (week) =>
            week.week === currentDate.week() &&
            week.year === currentDate.year(),
        ) ?? currentMonth.weeks.at(-1)

      onClickWeek(currentWeek!)
    } else {
      const lastMonth =
        objective.monthlyProgress[objective.monthlyProgress.length - 1]
      if (lastMonth?.weeks.length) {
        onClickWeek(lastMonth.weeks.at(-1)!)
      }
    }
  }

  const getProgressColor = (achieved: number, goal?: number) => {
    if (!goal) {
      return 'text-neutral-400'
    }

    const percentage = (achieved / goal) * 100
    if (percentage >= 100) return 'text-green-600'
    if (percentage >= 70) return 'text-yellow-600'
    return 'text-red-600'
  }

  const getMonthTarget = (month: number, year: number) => {
    return objective.targets?.find((t) => t.month === month && t.year === year)
      ?.target
  }

  const getMonthAchievement = (month: MonthlyProgress, type: ObjectiveType) => {
    if (type === 'average') {
      const nonNullValues = month.weeks.filter((w) => w.value !== null)
      return nonNullValues.length > 0
        ? month.weeks.reduce((sum, w) => sum + (w.value ?? 0), 0) /
            nonNullValues.length
        : 0
    }
    if (type === 'lastColumn') {
      // Find the last non-null value in the month
      const lastValue = [...month.weeks]
        .reverse()
        .find((w) => w.value !== null)?.value
      return lastValue ?? 0
    }
    // Sum type
    return month.weeks.reduce((sum, w) => sum + (w.value ?? 0), 0)
  }

  return (
    <tr
      className={cn(
        'border-b transition-colors hover:bg-muted/50 cursor-pointer',
        getLevelBackground(level),
      )}
      onClick={handleRowClick}
    >
      <TableCell
        className="p-2 pl-4"
        style={{ paddingLeft: `${level * 2 + 1}rem` }}
      >
        <div className="flex items-center gap-2">{objective.title}</div>
      </TableCell>
      <TableCell className="p-2 w-[100px]">
        <div className="flex items-center gap-1">
          {objective.owners.map((owner) => (
            <Tooltip key={owner.id} content={getUserDisplayName(owner)}>
              <AvatarWithFallback
                className="h-6 w-6"
                image={owner.image}
                fallback={getUserAvatarFallback(owner)}
              />
            </Tooltip>
          ))}
        </div>
      </TableCell>
      <TableCell className="p-2 w-[100px]">
        {TYPE_LABELS[objective.type]}
      </TableCell>
      {objective.monthlyProgress.map((month) => {
        const isExpanded = expandedMonths.includes(
          getMonthId(month.year, month.month),
        )

        return (
          <TableCell
            key={month.month}
            className={cn(
              'p-2 transition-all duration-200',
              isExpanded ? 'min-w-[200px]' : 'w-[100px]',
            )}
          >
            <div className={cn('flex flex-col', isExpanded)}>
              {isExpanded ? (
                <div className="flex items-center">
                  <div className="flex gap-0.5">
                    {month.weeks.map((week) => (
                      <WeekValue
                        key={week.week}
                        value={week.value}
                        metricType={objective.metricType}
                        onClick={() => {
                          onClickWeek(week)
                        }}
                      />
                    ))}
                  </div>
                  <span
                    className={cn(
                      'text-sm ml-2',
                      getProgressColor(
                        getMonthAchievement(month, objective.type),
                        getMonthTarget(month.month, month.year),
                      ),
                    )}
                  >
                    {formatValue(
                      getMonthAchievement(month, objective.type),
                      objective.metricType,
                    )}
                    /
                    {formatValue(
                      getMonthTarget(month.month, month.year),
                      objective.metricType,
                    )}
                  </span>
                </div>
              ) : (
                <div className="flex justify-end items-center">
                  <span
                    className={cn(
                      'text-sm',
                      getProgressColor(
                        getMonthAchievement(month, objective.type),
                        getMonthTarget(month.month, month.year),
                      ),
                    )}
                  >
                    {formatValue(
                      getMonthAchievement(month, objective.type),
                      objective.metricType,
                    )}
                    /
                    {formatValue(
                      getMonthTarget(month.month, month.year),
                      objective.metricType,
                    )}
                  </span>
                </div>
              )}
            </div>
          </TableCell>
        )
      })}
    </tr>
  )
}
