import { useEffect } from 'react'

import { Badge } from '@/components/ui/badge'

import { cn } from '@/lib/utils'

import { SetState } from '@/types/globals'

interface SavedBadgeProps {
  show: boolean
  setShow: SetState<boolean>
}

export const SavedBadge = ({ show, setShow }: SavedBadgeProps) => {
  useEffect(() => {
    setTimeout(() => setShow(false), 2000)
  }, [show, setShow])

  return (
    <div
      className={cn('transition-opacity duration-300', {
        'opacity-0': !show,
        'opacity-100': show,
      })}
    >
      <Badge variant="secondary">Saved</Badge>
    </div>
  )
}
