export const formatDecimalToPercentage = (value: number, decimalPlaces = 2) => {
  if (Number.isNaN(value)) {
    return '--'
  }

  return `${numberWithCommas(value * 100, decimalPlaces)}%`
}

export function numberWithCommas(value?: number, decimalPlaces = 2) {
  if (value == undefined) {
    return '--'
  }

  const isWholeNumber = value === Math.floor(value)

  const options = {
    minimumFractionDigits: isWholeNumber ? 0 : decimalPlaces,
    maximumFractionDigits: isWholeNumber ? 0 : decimalPlaces,
  }
  return Number(value).toLocaleString('us', options)
}

export const toProperCase = (txt: string) => {
  return txt.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

export const getUserAvatarFallback = (user: {
  firstName: string | null
  lastName: string | null
}) => {
  return `${user.firstName?.[0] ?? ''}${user.lastName?.[0] ?? ''}`
}

export const getUserDisplayName = (
  user?: {
    firstName: string | null
    lastName: string | null
  } | null,
) => {
  if (!user) {
    return ''
  }

  if (user.lastName?.length) {
    return `${user.firstName ?? ''} ${user.lastName}`
  }
  return `${user.firstName ?? ''}`
}

export function formatNumber(value?: number | null): string {
  if (value === undefined || value === null) return '-'

  if (value < 1000) {
    return Number(value)
      .toFixed(2)
      .replace(/\.?0+$/, '')
  }

  const tiers = [
    { threshold: 1e9, suffix: 'B' },
    { threshold: 1e6, suffix: 'M' },
    { threshold: 1e3, suffix: 'k' },
  ]

  for (const { threshold, suffix } of tiers) {
    if (value >= threshold) {
      const scaled = value / threshold
      return scaled < 100
        ? `${Number(scaled.toFixed(2)).toString()}${suffix}`
        : `${Math.round(scaled)}${suffix}`
    }
  }

  return value.toString()
}
