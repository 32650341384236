import { z } from 'zod'

export const PerformanceCycleSchema = z
  .object({
    label: z.string().min(5),
    startDate: z.string().datetime({ message: 'Start date is required' }),
    endDate: z.string().datetime({ message: 'End date is required' }),
  })
  .refine(
    (data) => {
      // Add refinement to ensure end date is after start date
      if (!data.startDate || !data.endDate) return true
      return new Date(data.endDate) >= new Date(data.startDate)
    },
    {
      message: 'End date must be after start date',
      path: ['endDate'],
    },
  )

export type PerformanceCycleSchemaType = z.infer<typeof PerformanceCycleSchema>
