import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { useState } from 'react'
import { DateRange } from 'react-day-picker'

import { Label } from '@/components/ui/label'
import { H5, Muted } from '@/components/ui/typography'
import { Button } from '@/components/ui/button'
import { Loading } from '@/components/ui/loading'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'

import {
  useCreateOrganizationPerformanceCycle,
  useDeleteOrganizationPerformanceCycle,
  useFetchOrganizationPerformanceCycles,
  usePatchOrganizationPerformanceCycle,
} from '@/services/api/organization.api'

import { PerformanceCycle } from '@/types/PerformanceCycle'
import { Separator } from './ui/separator'
import moment from 'moment'
import {
  PerformanceCycleSchema,
  PerformanceCycleSchemaType,
} from '@/lib/schema/performance-cycle'
import { RightBar } from './common/right-bar'
import { DatePicker } from './common/date-picker'

export const PerformanceCycleEditor = () => {
  const [createMode, setCreateMode] = useState(false)

  const { data: performanceCycles = [], isLoading } =
    useFetchOrganizationPerformanceCycles()
  const { mutate: patchPerformanceCycle, isPending: isCreating } =
    useCreateOrganizationPerformanceCycle()

  const handleOnSubmit = (data: PerformanceCycleSchemaType) => {
    patchPerformanceCycle(data, { onSuccess: () => setCreateMode(false) })
  }

  return (
    <div className="w-full flex flex-col gap-1">
      <Label htmlFor="performance-cycle">Performance Cycles</Label>
      <div className="flex flex-col gap-2">
        {isLoading ? (
          <Loading />
        ) : performanceCycles.length ? (
          <PerformanceCyclesContainer performanceCycles={performanceCycles} />
        ) : (
          <Muted className="text-center">No Performance Cycle available</Muted>
        )}
        <Button variant="outline" onClick={() => setCreateMode(true)}>
          Add New
        </Button>
      </div>
      <RightBar isVisible={createMode} handleClose={() => setCreateMode(false)}>
        <PerformanceCycleForm
          onSubmit={handleOnSubmit}
          onCancel={() => setCreateMode(false)}
          isPending={isCreating}
        />
      </RightBar>
    </div>
  )
}

interface PerformanceCyclesContainerProps {
  performanceCycles: PerformanceCycle[]
}

const PerformanceCyclesContainer = ({
  performanceCycles,
}: PerformanceCyclesContainerProps) => {
  return (
    <div>
      {performanceCycles.map((cycle, index) => (
        <div key={cycle.id}>
          <PerformanceCycleItem cycle={cycle} />
          {index < performanceCycles.length - 1 && <Separator />}
        </div>
      ))}
    </div>
  )
}

interface PerformanceCycleItemProps {
  cycle: PerformanceCycle
}

const PerformanceCycleItem = ({ cycle }: PerformanceCycleItemProps) => {
  const [editMode, setEditMode] = useState(false)
  const { mutate: patchPerformanceCycle, isPending: isPendingPatch } =
    usePatchOrganizationPerformanceCycle(cycle.id)
  const { mutate: deletePerformanceCycle, isPending: isPendingDelete } =
    useDeleteOrganizationPerformanceCycle(cycle.id)

  const handleOnSubmit = (data: PerformanceCycleSchemaType) => {
    patchPerformanceCycle(data, {
      onSuccess: () => {
        setEditMode(false)
      },
    })
  }

  const handleOnDelete = () => {
    deletePerformanceCycle(undefined, {
      onSuccess: () => {
        setEditMode(false)
      },
    })
  }

  return (
    <>
      <div className="flex flex-row justify-between p-2">
        <div>
          <H5 className="text-green-600">{cycle.label}</H5>
          <Muted>{`${moment(cycle.startDate).format('LL')} to ${moment(cycle.endDate).format('LL')}`}</Muted>
        </div>
        <Button onClick={() => setEditMode(true)} variant="outline">
          Edit
        </Button>
      </div>
      <RightBar isVisible={editMode} handleClose={() => setEditMode(false)}>
        <PerformanceCycleForm
          defaultValues={{
            label: cycle.label,
            startDate: cycle.startDate,
            endDate: cycle.endDate,
          }}
          onSubmit={handleOnSubmit}
          onDelete={handleOnDelete}
          onCancel={() => setEditMode(false)}
          isPending={isPendingPatch || isPendingDelete}
        />
      </RightBar>
    </>
  )
}

interface PerformanceCycleFormProps {
  defaultValues?: PerformanceCycleSchemaType
  onSubmit: (_values: PerformanceCycleSchemaType) => void
  onCancel: () => void
  onDelete?: () => void
  isPending?: boolean
}

const PerformanceCycleForm = ({
  defaultValues,
  onSubmit,
  onCancel,
  onDelete,
  isPending,
}: PerformanceCycleFormProps) => {
  const form = useForm<PerformanceCycleSchemaType>({
    resolver: zodResolver(PerformanceCycleSchema),
    defaultValues: {
      label: defaultValues?.label ?? '',
      startDate: defaultValues?.startDate ?? '',
      endDate: defaultValues?.endDate ?? '',
    },
  })

  const dateRange: DateRange = {
    from: form.watch('startDate')
      ? new Date(form.watch('startDate'))
      : undefined,
    to: form.watch('endDate') ? new Date(form.watch('endDate')) : undefined,
  }

  const handleDateRangeChange = (dateRange: DateRange) => {
    if (dateRange.from) {
      form.setValue('startDate', dateRange.from.toISOString(), {
        shouldValidate: true,
      })
    } else {
      form.setValue('startDate', '', { shouldValidate: true })
    }

    if (dateRange.to) {
      form.setValue('endDate', dateRange.to.toISOString(), {
        shouldValidate: true,
      })
    } else {
      form.setValue('endDate', '', { shouldValidate: true })
    }
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-4"
      >
        <H5>
          {defaultValues ? `Edit Performance Cycle` : `New Performance Cycle`}
        </H5>
        <FormField
          control={form.control}
          name="label"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Label</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="startDate"
          render={() => (
            <FormItem>
              <FormLabel>Date Range</FormLabel>
              <DatePicker
                value={dateRange}
                onChange={handleDateRangeChange}
                includePresets={false}
              />
              <FormMessage>
                {form.formState.errors.startDate?.message ||
                  form.formState.errors.endDate?.message}
              </FormMessage>
            </FormItem>
          )}
        />
        {!!onDelete && (
          <Button
            variant="destructive"
            disabled={isPending}
            onClick={(e) => {
              e.preventDefault()
              onDelete()
            }}
          >
            Delete
          </Button>
        )}
        <div className="flex gap-4">
          <Button variant="default" disabled={isPending}>
            Save
          </Button>
          <Button
            type="button"
            variant="outline"
            onClick={(e) => {
              e.preventDefault()
              onCancel()
            }}
          >
            Cancel
          </Button>
        </div>
      </form>
    </Form>
  )
}
