import {
  Table as UITable,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import { cn } from '@/lib/utils'

interface Column<T> {
  header: string
  accessor: keyof T | ((_row: T) => React.ReactNode)
  className?: string
}

interface TableProps<T> {
  data: T[]
  columns: Column<T>[]
  className?: string
  rowClassName?: (_row: T) => string
  emptyState?: React.ReactNode
}

export const Table = <T extends object>({
  data,
  columns,
  className,
  rowClassName,
  emptyState = 'No data available',
}: TableProps<T>) => {
  return (
    <UITable className={className}>
      <TableHeader>
        <TableRow>
          {columns.map((column, index) => (
            <TableHead key={index} className={column.className}>
              {column.header}
            </TableHead>
          ))}
        </TableRow>
      </TableHeader>
      <TableBody>
        {data.length === 0 ? (
          <TableRow>
            <TableCell colSpan={columns.length} className="text-center">
              {emptyState}
            </TableCell>
          </TableRow>
        ) : (
          data.map((row, rowIndex) => (
            <TableRow
              key={rowIndex}
              className={cn(
                rowClassName?.(row),
                // eslint-disable-next-line
                (row as any)?.userId === 'separator' && 'h-1',
              )}
            >
              {columns.map((column, colIndex) => (
                <TableCell
                  key={colIndex}
                  className={cn(
                    typeof column.className === 'string' && column.className,
                  )}
                >
                  {typeof column.accessor === 'function'
                    ? column.accessor(row)
                    : String(row[column.accessor])}
                </TableCell>
              ))}
            </TableRow>
          ))
        )}
      </TableBody>
    </UITable>
  )
}
