import { z } from 'zod'

export const PerformanceReviewCommentSchema = z.object({
  text: z.string().max(500),
  performanceReviewId: z.string(),
})

export type PerformanceReviewCommentType = z.infer<
  typeof PerformanceReviewCommentSchema
>
