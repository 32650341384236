import { useMemo, useState, useEffect } from 'react'
import { useLocalStorage } from '@/hooks/useLocalStorage'
import { useUserRole } from '@/hooks/useUserRole'

import { Muted } from '@/components/ui/typography'
import { ExternalLink, Download, Scale } from 'lucide-react'
import { Loading } from '@/components/ui/loading'
import { Select } from '@/components/common/select'
import { Button } from '@/components/ui/button'
import { Alert, AlertTitle, AlertDescription } from '@/components/ui/alert'
import NineBoxGrid from '@/components/ninebox-grid'
import { TeamReviewOverviewItem } from '@/components/team-review-overview-item'
import { TeamReviewEditModal } from '@/components/team-review-edit-modal'
import { ErrorTile } from '@/components/common/error-tile'
import { LayoutTile } from '@/components/layout'
import { Checkbox } from '@/components/ui/checkbox'
import { LayoutHeader } from '@/components/common/layout-header'

import { useFetchTeamMemberPerformanceReviews } from '@/services/api/performanceReview.api'
import { useFetchOrganizationPerformanceCycles } from '@/services/api/organization.api'

import {
  calculateObjectivesGrade,
  calculateValuesGrade,
} from '@/services/utils/performance-reviews'

import {
  TeamMemberPerformanceReview,
  StoredReviewVisibilityState,
} from '@/types/PerformanceReview'
import { NoPerformanceCycleTile } from '@/components/common/no-performance-cycle'

interface StoredTeamReviewState {
  employeeScope: 'directReports' | 'allUnderMe' | 'all'
  showNineBox: boolean
  selectedPerformanceCycleId?: string
}

export const MyTeamReviewsPage = () => {
  const { isAdmin } = useUserRole()
  const {
    data: performanceCycles = [],
    isLoading: isLoadingPerformanceCycles,
  } = useFetchOrganizationPerformanceCycles()

  const defaultState: StoredTeamReviewState = {
    employeeScope: 'directReports',
    showNineBox: true,
    selectedPerformanceCycleId: undefined,
  }
  const [storedState, setStoredState] = useLocalStorage<StoredTeamReviewState>(
    'team-reviews.page-state-v0.1',
    defaultState,
  )

  const [selectedTeamReview, setSelectedTeamReview] = useState<
    TeamMemberPerformanceReview | undefined
  >(undefined)

  const [hiddenStates, setHiddenStates] =
    useLocalStorage<StoredReviewVisibilityState>(
      'team-reviews.visibility-states',
      {},
    )

  useEffect(() => {
    if (
      !storedState.selectedPerformanceCycleId &&
      performanceCycles.length > 0
    ) {
      const latestCycle = performanceCycles[performanceCycles.length - 1]
      setStoredState({
        ...storedState,
        selectedPerformanceCycleId: latestCycle.id,
      })
    } else if (
      storedState.selectedPerformanceCycleId &&
      performanceCycles.length > 0 &&
      !performanceCycles.some(
        (pc) => pc.id === storedState.selectedPerformanceCycleId,
      )
    ) {
      // If stored cycle ID doesn't exist in current cycles, reset to latest
      const latestCycle = performanceCycles[performanceCycles.length - 1]
      setStoredState({
        ...storedState,
        selectedPerformanceCycleId: latestCycle.id,
      })
    }
  }, [performanceCycles, storedState, setStoredState])

  // Only fetch team reviews if we have a valid performance cycle ID
  const validCycleId =
    storedState.selectedPerformanceCycleId &&
    performanceCycles.some(
      (pc) => pc.id === storedState.selectedPerformanceCycleId,
    )
      ? storedState.selectedPerformanceCycleId
      : undefined

  const {
    data: teamReviews = [],
    isLoading,
    error,
  } = useFetchTeamMemberPerformanceReviews(
    validCycleId,
    storedState.employeeScope,
  )

  const scopeOptions = useMemo(() => {
    const options = [
      { value: 'directReports', label: 'Only direct reports' },
      { value: 'allUnderMe', label: 'All employees under me' },
    ]

    if (isAdmin) {
      options.push({ value: 'all', label: 'All employees' })
    }

    return options
  }, [isAdmin])

  const handleHideClick = (userId: string, hidden: boolean) => {
    setHiddenStates({
      ...hiddenStates,
      [userId]: hidden,
    })
  }

  const nineBoxUsers = useMemo(() => {
    return teamReviews
      .filter((tr: TeamMemberPerformanceReview) => !!tr.review)
      .filter((tr: TeamMemberPerformanceReview) => {
        const isHidden =
          tr.user.id in hiddenStates ? hiddenStates[tr.user.id] : false
        return !isHidden
      })
      .map((tr: TeamMemberPerformanceReview) => ({
        ...tr.user,
        objectivesScore: calculateObjectivesGrade(tr.review?.objectives ?? []),
        valuesScore: calculateValuesGrade(tr.review?.values ?? []),
      }))
  }, [teamReviews, hiddenStates])

  const handleExport = () => {
    const currentCycle = performanceCycles.find(
      (pc) => pc.id === storedState.selectedPerformanceCycleId,
    )
    const headers = [
      'Name',
      'Email',
      'Performance Cycle',
      'Objectives Score',
      'Values Score',
    ]
    const csvData = teamReviews
      .filter((tr: TeamMemberPerformanceReview) => {
        const isHidden =
          tr.user.id in hiddenStates ? hiddenStates[tr.user.id] : false
        return !isHidden
      })
      .map((tr: TeamMemberPerformanceReview) => [
        `${tr.user.firstName} ${tr.user.lastName}`,
        tr.user.emails[0] || '',
        currentCycle?.label || '',
        calculateObjectivesGrade(tr.review?.objectives ?? []).toFixed(2),
        calculateValuesGrade(tr.review?.values ?? []).toFixed(2),
      ])

    const csvContent = [
      headers.join(','),
      ...csvData.map((row) => row.join(',')),
    ].join('\n')

    const blob = new Blob([csvContent], { type: 'text/csv' })
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = `nine-box-data-${currentCycle?.label || 'export'}-${new Date().toISOString().split('T')[0]}.csv`
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    window.URL.revokeObjectURL(url)
  }

  const needsCalibration = useMemo(() => {
    if (!nineBoxUsers.length) return false
    if (nineBoxUsers.length <= 1) return false // No need to calibrate for 1 person

    const totalUsers = nineBoxUsers.length
    const objectivesCount = [0, 0, 0]
    const valuesCount = [0, 0, 0]

    nineBoxUsers.forEach((user) => {
      const objectivesPos = Math.floor(user.objectivesScore) - 1
      const valuesPos = Math.floor(user.valuesScore) - 1
      objectivesCount[objectivesPos]++
      valuesCount[2 - valuesPos]++
    })

    const objectivesPercentages = objectivesCount.map((count) =>
      Math.round((count / totalUsers) * 100),
    )
    const valuesPercentages = valuesCount.map((count) =>
      Math.round((count / totalUsers) * 100),
    )

    // Calculate offset based on team size (30% more lenient)
    const getOffsetRanges = (position: 'Low' | 'Mid' | 'High') => {
      if (position === 'Mid') {
        return {
          min: Math.max(0, 50 - 30), // Can't go below 0%
          max: Math.min(100, 70 + 30), // Can't exceed 100%
        }
      }
      // For Low and High
      return {
        min: Math.max(0, 10 - 30), // Can't go below 0%
        max: Math.min(100, 30 + 30), // Can't exceed 100%
      }
    }

    // Check if any distribution is suboptimal with offset
    const isSuboptimal = (
      percentage: number,
      position: 'Low' | 'Mid' | 'High',
    ) => {
      const { min, max } = getOffsetRanges(position)
      return percentage < min || percentage > max
    }

    return (
      isSuboptimal(objectivesPercentages[0], 'Low') ||
      isSuboptimal(objectivesPercentages[1], 'Mid') ||
      isSuboptimal(objectivesPercentages[2], 'High') ||
      isSuboptimal(valuesPercentages[0], 'High') ||
      isSuboptimal(valuesPercentages[1], 'Mid') ||
      isSuboptimal(valuesPercentages[2], 'Low')
    )
  }, [nineBoxUsers])

  if (isLoadingPerformanceCycles) {
    return (
      <LayoutTile>
        <Loading containerClassName="w-full h-full flex justify-center items-center" />
      </LayoutTile>
    )
  }

  if (!performanceCycles.length) {
    return <NoPerformanceCycleTile />
  }

  if (error) {
    return <ErrorTile />
  }

  return (
    <LayoutTile>
      <LayoutHeader
        className="team-reviews-title"
        title="Your Team's Reviews"
        description={
          <>
            View and manage performance reviews for your team members.{' '}
            <a
              href="https://youtu.be/yyPvyqrSdpo"
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary hover:underline inline-flex items-center"
            >
              Watch our tutorial
              <ExternalLink size={16} className="ml-1" />
            </a>
          </>
        }
        rightSide={
          <div className="flex flex-col gap-4 items-end mt-4 sm:mt-0">
            <div className="flex flex-wrap items-center justify-end gap-4 w-full order-1">
              <Select
                options={scopeOptions}
                value={storedState.employeeScope}
                onValueChange={(
                  value: 'directReports' | 'allUnderMe' | 'all',
                ) => {
                  const newValue =
                    !isAdmin && value === 'all' ? 'directReports' : value
                  setStoredState({ ...storedState, employeeScope: newValue })
                }}
              />
              <Select
                options={performanceCycles.map((pc) => ({
                  value: pc.id,
                  label: pc.label,
                }))}
                value={storedState.selectedPerformanceCycleId}
                onValueChange={(value: string) =>
                  setStoredState({
                    ...storedState,
                    selectedPerformanceCycleId: value,
                  })
                }
              />
            </div>
            <div className="flex flex-wrap items-center justify-end gap-4 w-full order-2">
              <Button
                variant="outline"
                size="sm"
                onClick={handleExport}
                className="flex items-center gap-2"
                disabled={nineBoxUsers.length === 0}
              >
                <Download size={16} />
                Export 9-Box
              </Button>
              <div className="flex items-center space-x-2">
                <Checkbox
                  id="show-nine-box"
                  checked={storedState.showNineBox}
                  onCheckedChange={(checked: boolean) =>
                    setStoredState({ ...storedState, showNineBox: checked })
                  }
                />
                <label
                  htmlFor="show-nine-box"
                  className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                >
                  Show 9-box
                </label>
              </div>
            </div>
          </div>
        }
      />
      {isLoading ? (
        <div className="flex-1 w-full justify-center items-center flex">
          <Loading />
        </div>
      ) : (
        <div>
          {needsCalibration && nineBoxUsers.length > 0 && (
            <div className="mb-6">
              <Alert
                variant="destructive"
                className="bg-orange-50 border-orange-200"
              >
                <Scale className="h-4 w-4 text-orange-600" />
                <AlertTitle className="text-orange-900">
                  Ensure your team&apos;s performance ratings are calibrated
                </AlertTitle>
                <AlertDescription className="text-orange-800">
                  Make sure to follow the recommended percentages, this will
                  save you time during the calibration session.
                </AlertDescription>
              </Alert>
            </div>
          )}
          {storedState.showNineBox && (
            <div className="w-full max-w-3xl mx-auto">
              <NineBoxGrid users={nineBoxUsers} hiddenStates={hiddenStates} />
            </div>
          )}
          <div className="mt-4 flex flex-col gap-4 team-reviews-list">
            {teamReviews.length === 0 ? (
              <Muted className="my-16">
                No Team Reviews Available for this Quarter
              </Muted>
            ) : (
              teamReviews.map((review: TeamMemberPerformanceReview) => (
                <TeamReviewOverviewItem
                  key={`team-review-${review.user.id}`}
                  data={review}
                  handleEditClick={() => setSelectedTeamReview(review)}
                  performanceCycleId={storedState.selectedPerformanceCycleId!}
                  isHidden={
                    review.user.id in hiddenStates
                      ? hiddenStates[review.user.id]
                      : false
                  }
                  onHideClick={(hidden) =>
                    handleHideClick(review.user.id, hidden)
                  }
                />
              ))
            )}
            {!!selectedTeamReview && (
              <TeamReviewEditModal
                selectedReview={selectedTeamReview}
                setSelectedReview={setSelectedTeamReview}
                performanceCycleId={storedState.selectedPerformanceCycleId!}
              />
            )}
          </div>
        </div>
      )}
    </LayoutTile>
  )
}
