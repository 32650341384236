import { useState, useEffect, useCallback } from 'react'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useFieldArray, useForm } from 'react-hook-form'

import { Modal } from '@/components/common/modal'
import { H4, H5, H6, Muted } from '@/components/ui/typography'
import { Textarea } from '@/components/ui/textarea'
import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Separator } from '@/components/ui/separator'
import { Loading } from '@/components/ui/loading'
import { Icon } from '@/components/ui/icon'
import { FeedbackSessionModalContent } from '@/components/feedback-session-modal'
import { SavedBadge } from '@/components/common/saved-badge'
import { Accordion } from '@/components/common/accordion'
import { Dialog } from '@/components/common/dialog'

import { PerformanceReviewCreateSchema } from '@/lib/schema/performance-review.schema'
import { useFetchTeamObjectivesByUserId } from '@/services/api/objective.api'
import { useFetchCompanyValues } from '@/services/api/values.api'
import { cn } from '@/lib/utils'
import {
  useCreateTeamMemberPerformanceReview,
  useUpdateTeamMemberPerformanceReview,
  useFetchSelfAssessment,
  useAnalyzeObjectives,
  useAnalyzeValues,
  useAnalyzeManagerReview,
  useAnalyzeActionPlan,
} from '@/services/api/performanceReview.api'
import { useDebounce } from '@/hooks/useDebouncer'

import { SetState } from '@/types/globals'
import {
  PerformanceReview,
  TeamMemberPerformanceReview,
} from '@/types/PerformanceReview'

interface TeamReviewEditModalProps {
  selectedReview: TeamMemberPerformanceReview
  setSelectedReview: SetState<TeamMemberPerformanceReview | undefined>
  performanceCycleId: string
}

type PerformanceReviewSchemaAlias = z.infer<
  typeof PerformanceReviewCreateSchema
>

interface AnalysisResult {
  score: number
  feedback: string
}

const ReviewButton = ({
  onClick,
  isLoading,
}: {
  onClick: () => void
  isLoading?: boolean
}) => (
  <Button
    type="button"
    variant="outline"
    size="sm"
    onClick={onClick}
    disabled={isLoading}
    className="gap-2"
  >
    {isLoading ? (
      <Loading className="h-4 w-4" />
    ) : (
      <Icon name="Sparkles" className="h-4 w-4" />
    )}
    Review with AI
  </Button>
)

const AnalysisResult = ({
  text,
  analysis,
}: {
  text: string
  analysis?: { score: number; feedback: string }
}) => {
  if (!text.trim()) return null

  // If no analysis yet, don't render anything
  if (!analysis) return null

  // Ensure score is between 0-100
  const score = Math.min(Math.max(analysis.score, 0), 100)
  const scoreColor =
    score >= 80
      ? 'text-green-500'
      : score >= 60
        ? 'text-yellow-500'
        : 'text-red-500'

  return (
    <div className="mt-1 space-y-1">
      <Muted className={cn('text-xs font-medium', scoreColor)}>
        Score: {Math.round(score)}%
      </Muted>
      <Muted className="text-xs block">{analysis.feedback}</Muted>
    </div>
  )
}

export const TeamReviewEditModal = ({
  selectedReview,
  setSelectedReview,
  performanceCycleId,
}: TeamReviewEditModalProps) => {
  const { data: userObjectives = [], isFetched: isFetchedObjectives } =
    useFetchTeamObjectivesByUserId(selectedReview.user.id, performanceCycleId)
  const { data: teamValues = [], isFetched: isFetchedTeamValues } =
    useFetchCompanyValues()
  const { data: selfAssessment } = useFetchSelfAssessment(
    performanceCycleId,
    selectedReview.user.id,
  )

  const [showSaved, setShowSaved] = useState(false)

  return (
    <Modal
      isOpen={!!selectedReview}
      setIsOpen={() => setSelectedReview(undefined)}
      className="w-[90%] max-w-[1500px] h-[95%] max-h-[800px] p-0"
    >
      <>
        <div className="h-full absolute w-1/2 overflow-auto px-4 py-8">
          <FeedbackSessionModalContent
            user={selectedReview.user}
            dateRange={selectedReview.dateRange}
            canIncludeAll
            isOpen
          />
        </div>
        <Separator
          orientation="vertical"
          className="h-[98%] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
        />
        <div className="h-full absolute w-1/2 right-0 overflow-auto">
          <div className="sticky top-0 bg-background z-10 px-4 py-3 border-b">
            <div className="flex justify-between items-center">
              <div className="flex gap-4 items-center">
                <H4>Edit Performance Review</H4>
                <SavedBadge show={showSaved} setShow={setShowSaved} />
              </div>
              <Button
                variant="ghost"
                size="icon"
                onClick={() => setSelectedReview(undefined)}
              >
                <Icon name="X" className="h-4 w-4" />
              </Button>
            </div>
          </div>
          {!isFetchedObjectives || !isFetchedTeamValues ? (
            <Loading containerClassName="w-full" className="m-auto" />
          ) : (
            <div className="p-4">
              <div className="mb-4">
                <Accordion header="Self Assessment" icon="FileText">
                  <div className="flex flex-col gap-6">
                    <div>
                      <H6>Objectives</H6>
                      <Muted
                        className={cn(
                          !selfAssessment?.objectives && 'text-red-500/70',
                        )}
                      >
                        {selfAssessment?.objectives ??
                          'No self assessment submitted yet'}
                      </Muted>
                    </div>
                    <div>
                      <H6>Values</H6>
                      <Muted
                        className={cn(
                          !selfAssessment?.values && 'text-red-500/70',
                        )}
                      >
                        {selfAssessment?.values ??
                          'No self assessment submitted yet'}
                      </Muted>
                    </div>
                  </div>
                </Accordion>
              </div>
              <PerformanceReviewForm
                defaultValues={{
                  userId: selectedReview.user.id,
                  performanceCycleId,
                  managerReview: selectedReview.review?.managerReview,
                  actionPlan: selectedReview.review?.actionPlan,
                  objectives: userObjectives.map((uo) => {
                    const performanceReviewObjective =
                      selectedReview.review?.objectives.find(
                        (o) => o.id === uo.id,
                      )

                    return {
                      id: uo.id,
                      completion: performanceReviewObjective?.completion ?? 0,
                      title: uo.title,
                      comments: performanceReviewObjective?.comments ?? '',
                    }
                  }),
                  values: teamValues.map((value) => {
                    const performanceReviewValue =
                      selectedReview.review?.values.find(
                        (v) => v.id === value.id,
                      )

                    return {
                      id: value.id,
                      rating: performanceReviewValue?.rating ?? 0,
                      label: value.label,
                      comments: performanceReviewValue?.comments ?? '',
                    }
                  }),
                }}
                pastPerformanceReview={selectedReview.pastReview}
                selectedReview={selectedReview}
                setShowSaved={setShowSaved}
              />
            </div>
          )}
        </div>
      </>
    </Modal>
  )
}

export const PerformanceReviewForm = ({
  defaultValues,
  pastPerformanceReview,
  selectedReview,
  setShowSaved,
}: {
  defaultValues: PerformanceReviewSchemaAlias
  selectedReview: TeamMemberPerformanceReview
  pastPerformanceReview?: PerformanceReview | undefined
  setShowSaved: SetState<boolean>
}) => {
  const form = useForm<PerformanceReviewSchemaAlias>({
    resolver: zodResolver(PerformanceReviewCreateSchema),
    defaultValues,
  })
  const { fields: objectives } = useFieldArray({
    control: form.control,
    name: 'objectives',
  })
  const { fields: values } = useFieldArray({
    control: form.control,
    name: 'values',
  })

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false)

  const {
    mutate: createTeamMemberPerformanceReview,
    isPending: isLoadingPost,
  } = useCreateTeamMemberPerformanceReview(defaultValues.performanceCycleId)
  const {
    mutate: updateTeamMemberPerformanceReview,
    isPending: isLoadingPatch,
  } = useUpdateTeamMemberPerformanceReview(defaultValues.performanceCycleId)
  const { mutate: analyzeObjective, isPending: isAnalyzingObjectives } =
    useAnalyzeObjectives()
  const { mutate: analyzeValue, isPending: isAnalyzingValues } =
    useAnalyzeValues()
  const { mutate: analyzeManagerReview, isPending: isAnalyzingManagerReview } =
    useAnalyzeManagerReview()
  const { mutate: analyzeActionPlan, isPending: isAnalyzingActionPlan } =
    useAnalyzeActionPlan()

  const [analyses, setAnalyses] = useState<
    Record<string, { score: number; feedback: string }>
  >({})
  const [emptyFieldErrors, setEmptyFieldErrors] = useState<
    Record<string, boolean>
  >({})

  const onSubmit = useCallback(
    (values: PerformanceReviewSchemaAlias) => {
      if (selectedReview.review) {
        setShowSaved(true)
        updateTeamMemberPerformanceReview({
          id: selectedReview.review.id,
          body: values,
        })
        return
      }

      setShowSaved(true)
      createTeamMemberPerformanceReview(values)
    },
    [
      setShowSaved,
      selectedReview,
      createTeamMemberPerformanceReview,
      updateTeamMemberPerformanceReview,
    ],
  )

  const handleConfirmSubmit = () => {
    if (!selectedReview.review) {
      return
    }

    setShowSaved(true)
    updateTeamMemberPerformanceReview({
      id: selectedReview.review.id,
      body: { status: 'submitted' },
    })
    setIsConfirmDialogOpen(false)
  }

  const handleAutoSave = useCallback(
    (values: PerformanceReviewSchemaAlias) => {
      onSubmit(values)
    },
    [onSubmit],
  )
  const debouncedAutoSave = useDebounce(handleAutoSave, 200)
  useEffect(() => {
    const subscription = form.watch(() => {
      const values = form.getValues()
      form.trigger().then((isValid) => {
        if (isValid) {
          debouncedAutoSave(values)
        }
      })
    })
    return () => subscription.unsubscribe()
  }, [form, debouncedAutoSave])

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-4"
      >
        <div className="flex flex-col gap-3">
          <div className="flex justify-between items-center">
            <H5>Objectives</H5>
            <ReviewButton
              onClick={async () => {
                setEmptyFieldErrors({})
                const objectives = form.getValues().objectives
                const filledObjectives = objectives
                  .map((objective, index) => ({ objective, index }))
                  .filter(({ objective }) => objective.comments?.trim())

                if (filledObjectives.length === 0) {
                  setEmptyFieldErrors((prev) => ({ ...prev, objectives: true }))
                  return
                }

                // Mark empty fields
                objectives.forEach((objective, index) => {
                  if (!objective.comments?.trim()) {
                    setEmptyFieldErrors((prev) => ({
                      ...prev,
                      [`objective-${index}`]: true,
                    }))
                  }
                })

                // Process each objective in series
                for (const { objective, index } of filledObjectives) {
                  const key = `objective-${index}`
                  try {
                    const result = await new Promise<AnalysisResult>(
                      (resolve, reject) => {
                        analyzeObjective(
                          {
                            text: objective.comments,
                            label: objective.title,
                          },
                          {
                            onSuccess: resolve,
                            onError: reject,
                          },
                        )
                      },
                    )
                    setAnalyses((prev) => ({ ...prev, [key]: result }))
                  } catch (error) {
                    setEmptyFieldErrors((prev) => ({ ...prev, [key]: true }))
                  }
                }
              }}
              isLoading={isAnalyzingObjectives}
            />
          </div>
          {emptyFieldErrors.objectives && (
            <Muted className="text-xs text-red-500">
              Please fill in at least one objective comment before reviewing
            </Muted>
          )}
          <div className="flex flex-col gap-4">
            {objectives.map((field, index) => (
              <div key={field.id} className="flex flex-col gap-2 px-2">
                <div className="flex justify-between items-center">
                  <H6 className="font-medium">
                    {form.getValues().objectives[index].title}
                  </H6>
                  <FormField
                    {...form.register(`objectives.${index}.completion`)}
                    render={({ field }) => (
                      <FormItem className="w-[100px]">
                        <FormControl>
                          <Input
                            {...field}
                            onChange={(event) =>
                              field.onChange(+event.target.value)
                            }
                            endAdornment={<Icon name="Percent" />}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <FormField
                  {...form.register(`objectives.${index}.comments`)}
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Textarea
                          {...field}
                          placeholder="Comments"
                          onChange={(event) =>
                            field.onChange(event.target.value)
                          }
                          rows={3}
                        />
                      </FormControl>
                      <FormMessage />
                      {emptyFieldErrors[`objective-${index}`] && (
                        <Muted className="text-xs text-red-500">
                          Please fill in this field before reviewing
                        </Muted>
                      )}
                      {field.value && (
                        <AnalysisResult
                          text={field.value}
                          analysis={analyses[`objective-${index}`]}
                        />
                      )}
                    </FormItem>
                  )}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <div className="flex justify-between items-center">
            <H5>Values</H5>
            <ReviewButton
              onClick={async () => {
                setEmptyFieldErrors({})
                const values = form.getValues().values
                const filledValues = values
                  .map((value, index) => ({ value, index }))
                  .filter(({ value }) => value.comments?.trim())

                if (filledValues.length === 0) {
                  setEmptyFieldErrors((prev) => ({ ...prev, values: true }))
                  return
                }

                // Mark empty fields
                values.forEach((value, index) => {
                  if (!value.comments?.trim()) {
                    setEmptyFieldErrors((prev) => ({
                      ...prev,
                      [`value-${index}`]: true,
                    }))
                  }
                })

                // Process each value in series
                for (const { value, index } of filledValues) {
                  const key = `value-${index}`
                  try {
                    const result = await new Promise<AnalysisResult>(
                      (resolve, reject) => {
                        analyzeValue(
                          {
                            text: value.comments,
                            label: value.label,
                          },
                          {
                            onSuccess: resolve,
                            onError: reject,
                          },
                        )
                      },
                    )
                    setAnalyses((prev) => ({ ...prev, [key]: result }))
                  } catch (error) {
                    setEmptyFieldErrors((prev) => ({ ...prev, [key]: true }))
                  }
                }
              }}
              isLoading={isAnalyzingValues}
            />
          </div>
          {emptyFieldErrors.values && (
            <Muted className="text-xs text-red-500">
              Please fill in at least one value comment before reviewing
            </Muted>
          )}
          <div className="flex flex-col gap-4">
            {values.map((field, index) => (
              <div key={field.id} className="flex flex-col gap-2 px-2">
                <div className="flex justify-between items-center">
                  <H6 className="font-medium">
                    {form.getValues().values[index].label}
                  </H6>
                  <FormField
                    {...form.register(`values.${index}.rating`)}
                    render={({ field }) => (
                      <div className="flex gap-1">
                        {[1, 2, 3, 4, 5].map((star) => (
                          <Icon
                            key={`value-${field.name}-star-${star}`}
                            name="Star"
                            className={cn('h-6 w-6 cursor-pointer', {
                              'text-yellow-400 fill-yellow-400':
                                star <= form.getValues().values[index].rating,
                              'text-gray-300':
                                star > form.getValues().values[index].rating,
                            })}
                            onClick={() => {
                              field.onChange(star)
                            }}
                          />
                        ))}
                      </div>
                    )}
                  />
                </div>
                <FormField
                  {...form.register(`values.${index}.comments`)}
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Textarea
                          {...field}
                          placeholder="Comments"
                          onChange={(event) =>
                            field.onChange(event.target.value)
                          }
                          rows={3}
                        />
                      </FormControl>
                      <FormMessage />
                      {emptyFieldErrors[`value-${index}`] && (
                        <Muted className="text-xs text-red-500">
                          Please fill in this field before reviewing
                        </Muted>
                      )}
                      {field.value && (
                        <AnalysisResult
                          text={field.value}
                          analysis={analyses[`value-${index}`]}
                        />
                      )}
                    </FormItem>
                  )}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <div className="flex justify-between items-center">
            <H5>Manager&apos;s Review</H5>
            <ReviewButton
              onClick={() => {
                const text = form.getValues().managerReview
                if (text?.trim()) {
                  analyzeManagerReview(text, {
                    onSuccess: (result) => {
                      setAnalyses((prev) => ({
                        ...prev,
                        'manager-review': result,
                      }))
                    },
                  })
                } else {
                  setEmptyFieldErrors((prev) => ({
                    ...prev,
                    'manager-review': true,
                  }))
                }
              }}
              isLoading={isAnalyzingManagerReview}
            />
          </div>
          {!!pastPerformanceReview && (
            <Accordion
              icon="History"
              header="Written in last Performance Cycle"
            >
              <Muted>{pastPerformanceReview.managerReview}</Muted>
            </Accordion>
          )}
          <FormField
            control={form.control}
            name="managerReview"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Textarea
                    placeholder="Please write your final review"
                    rows={3}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
                {emptyFieldErrors['manager-review'] && (
                  <Muted className="text-xs text-red-500">
                    Please fill in this field before reviewing
                  </Muted>
                )}
                {field.value && analyses['manager-review'] && (
                  <AnalysisResult
                    text={field.value}
                    analysis={analyses['manager-review']}
                  />
                )}
              </FormItem>
            )}
          />
        </div>
        <div className="flex flex-col gap-3">
          <div className="flex justify-between items-center">
            <H5>Action Plan</H5>
            <ReviewButton
              onClick={() => {
                const text = form.getValues().actionPlan
                if (text?.trim()) {
                  analyzeActionPlan(text, {
                    onSuccess: (result) => {
                      setAnalyses((prev) => ({
                        ...prev,
                        'action-plan': result,
                      }))
                    },
                  })
                } else {
                  setEmptyFieldErrors((prev) => ({
                    ...prev,
                    'action-plan': true,
                  }))
                }
              }}
              isLoading={isAnalyzingActionPlan}
            />
          </div>
          {!!pastPerformanceReview && (
            <Accordion
              icon="History"
              header="Written in last Performance Cycle"
            >
              <Muted>{pastPerformanceReview.actionPlan}</Muted>
            </Accordion>
          )}
          <FormField
            control={form.control}
            name="actionPlan"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Textarea
                    placeholder="Please write the action plan for the next cycle"
                    rows={3}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
                {emptyFieldErrors['action-plan'] && (
                  <Muted className="text-xs text-red-500">
                    Please fill in this field before reviewing
                  </Muted>
                )}
                {field.value && analyses['action-plan'] && (
                  <AnalysisResult
                    text={field.value}
                    analysis={analyses['action-plan']}
                  />
                )}
              </FormItem>
            )}
          />
        </div>
        <div className="mt-4">
          <Dialog
            trigger={
              <Button
                disabled={isLoadingPost || isLoadingPatch}
                className="w-full"
              >
                Submit for calibration
              </Button>
            }
            title="Confirm your submission"
            description={`Are you sure you want to submit this review for calibration? You can still edit it later.`}
            confirmText="Submit"
            onConfirm={handleConfirmSubmit}
            isOpen={isConfirmDialogOpen}
            setIsOpen={setIsConfirmDialogOpen}
          />
          <Muted className="text-xs mt-2 text-muted-foreground">
            Changes are automatically saved as a draft. Click Submit to send for
            calibration.
          </Muted>
        </div>
      </form>
    </Form>
  )
}
