import { useState } from 'react'

import { H6 } from '@/components/ui/typography'
import { Alert, AlertDescription } from '@/components/ui/alert'
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@/components/ui/collapsible'
import { Icon, IconName } from '@/components/ui/icon'

interface AccordionProps {
  header: string
  children?: JSX.Element
  icon?: IconName
}

export const Accordion = ({ header, children, icon }: AccordionProps) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Collapsible open={isOpen} onOpenChange={setIsOpen}>
      <CollapsibleTrigger>
        <Alert>
          <CollapsibleTrigger className="flex items-center justify-between w-full">
            <div className="flex items-center">
              {!!icon && <Icon name={icon} className="h-4 w-4 mr-2" />}
              <H6 className="font-medium">{header}</H6>
            </div>
            <Icon
              name="ChevronDown"
              className="h-4 w-4 transition-transform duration-200"
            />
          </CollapsibleTrigger>
          <CollapsibleContent>
            <AlertDescription className="pt-4">{children}</AlertDescription>
          </CollapsibleContent>
        </Alert>
      </CollapsibleTrigger>
    </Collapsible>
  )
}
