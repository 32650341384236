import { H4, P } from '@/components/ui/typography'
import { cn } from '@/lib/utils'

interface LayoutHeaderProps {
  title: string
  description: React.ReactNode
  rightSide?: React.ReactNode
  className?: string
}

export const LayoutHeader = ({
  title,
  description,
  rightSide,
  className,
}: LayoutHeaderProps) => {
  return (
    <div
      className={cn(
        'flex flex-col sm:flex-row sm:items-center justify-between mb-2 space-y-4 sm:space-y-0 sm:gap-8',
        className,
      )}
    >
      <div className="flex flex-col">
        <H4 className="font-semibold mb-1">{title}</H4>
        <P className="text-muted-foreground">{description}</P>
      </div>
      {rightSide && (
        <div className="flex flex-col sm:flex-row items-start sm:items-center space-y-2 sm:space-y-0 sm:space-x-4 shrink-0">
          {rightSide}
        </div>
      )}
    </div>
  )
}
