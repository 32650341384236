import { Outlet, useNavigate } from 'react-router-dom'
import { ClerkProvider } from '@clerk/clerk-react'
import { CannyProvider } from '@/lib/canny'
import { ChatwootWidget } from '@/components/chatwoot-widget'

const CLERK_PUBLISHABLE_KEY = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY
if (!CLERK_PUBLISHABLE_KEY) {
  throw new Error('Missing Publishable Key')
}

export function Root() {
  const navigate = useNavigate()

  return (
    <ClerkProvider
      routerPush={(to) => navigate(to)}
      routerReplace={(to) => navigate(to, { replace: true })}
      publishableKey={CLERK_PUBLISHABLE_KEY}
      appearance={{
        variables: { colorPrimary: '#43A047' },
      }}
    >
      <CannyProvider>
        <main className="flex text-[#1B2559] bg-light-blue h-screen w-screen">
          <Outlet />
        </main>
        <ChatwootWidget />
      </CannyProvider>
    </ClerkProvider>
  )
}
