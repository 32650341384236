import { useEffect } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'

import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'

import { useDebounce } from '@/hooks/useDebouncer'
import {
  TeamObjectiveCreateSchema,
  TeamObjectiveCreateSchemaType,
} from '@/lib/schema/objective-form.schema'

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { TeamMemberSelector } from '@/components/team-user-selector'

export const ObjectiveForm = ({
  defaultValues,
  onSubmit,
  isSaving,
  isDisabled,
  autoSave,
}: {
  defaultValues?: Partial<TeamObjectiveCreateSchemaType>
  onSubmit: (_values: TeamObjectiveCreateSchemaType) => void
  isSaving?: boolean
  isDisabled?: boolean
  autoSave?: boolean
}) => {
  const form = useForm<TeamObjectiveCreateSchemaType>({
    resolver: zodResolver(TeamObjectiveCreateSchema),
    defaultValues: defaultValues ?? {},
  })

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    form.handleSubmit(onSubmit)()
  }

  const debouncedSubmit = useDebounce(onSubmit, 200)
  useEffect(() => {
    if (!autoSave) {
      return
    }

    const subscription = form.watch(() => {
      const values = form.getValues()
      form.trigger().then((isValid) => {
        if (isValid) {
          debouncedSubmit(values)
        }
      })
    })
    return () => subscription.unsubscribe()
  }, [form, debouncedSubmit, autoSave])

  return (
    <Form {...form}>
      <form onSubmit={handleSubmit} className="space-y-3">
        <FormField
          control={form.control}
          name="title"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Objective</FormLabel>
              <FormControl>
                <Input
                  placeholder="Increase customer satisfaction"
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleSubmit(e)
                    }
                  }}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="owners"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Owner(s)</FormLabel>
              <TeamMemberSelector
                onChange={field.onChange}
                value={field.value}
                noPortal
              />
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="type"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Aggregation method</FormLabel>
              <Select value={field.value} onValueChange={field.onChange}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  <SelectItem value="average">Average</SelectItem>
                  <SelectItem value="lastColumn">Last Column</SelectItem>
                  <SelectItem value="sum">Sum</SelectItem>
                </SelectContent>
              </Select>
              <FormDescription>
                Select how you want to aggregate the data
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        {!autoSave && (
          <Button type="submit" disabled={!!isSaving || !!isDisabled}>
            {isSaving ? 'Saving...' : 'Save'}
          </Button>
        )}
      </form>
    </Form>
  )
}
