import { useMemo, useCallback, useEffect } from 'react'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { H5 } from '@/components/ui/typography'
import { Loading } from '@/components/ui/loading'

import { useDebounce } from '@/hooks/useDebouncer'
import {
  TeamObjectiveTargetSchema,
  TeamObjectiveTargetSchemaType,
} from '@/lib/schema/objective-form.schema'

import type { ObjectiveUI } from '@/types/Objective'

interface MonthlyTargetsFormProps {
  selectedObjective: ObjectiveUI
  onSubmit: (_target: TeamObjectiveTargetSchemaType) => void
  isSaving?: boolean
}

const targetsFormSchema = z.object({
  targets: z.array(TeamObjectiveTargetSchema),
})

type TargetsFormData = z.infer<typeof targetsFormSchema>

export const MonthlyTargetsForm = ({
  selectedObjective,
  onSubmit,
  isSaving,
}: MonthlyTargetsFormProps) => {
  const defaultValues = useMemo(
    () => ({
      targets: selectedObjective.monthlyProgress.map(({ month, year }) => ({
        month,
        year,
        target:
          selectedObjective.targets.find(
            (t) => t.month === month && t.year === year,
          )?.target ?? null,
      })),
    }),
    [selectedObjective],
  )

  const form = useForm<TargetsFormData>({
    resolver: zodResolver(targetsFormSchema),
    defaultValues,
  })

  // Compare current values with default values to find changes
  const handleSubmit = form.handleSubmit((data) => {
    const changedTargets = data.targets.filter((target, index) => {
      const originalTarget = defaultValues.targets[index].target
      return target.target !== originalTarget
    })

    for (const target of changedTargets) {
      onSubmit(target)
    }
  })

  const processChanges = useCallback(
    (data: TargetsFormData) => {
      const changedTargets = data.targets.filter((target, index) => {
        const originalTarget = defaultValues.targets[index].target
        return target.target !== originalTarget
      })
      for (const target of changedTargets) {
        onSubmit(target)
      }
    },
    [defaultValues.targets, onSubmit],
  )
  const debouncedSubmit = useDebounce(processChanges, 200)
  useEffect(() => {
    const subscription = form.watch(() => {
      const values = form.getValues()
      form.trigger().then((isValid) => {
        if (isValid) {
          debouncedSubmit(values)
        }
      })
    })
    return () => subscription.unsubscribe()
  }, [form, debouncedSubmit])

  return (
    <Form {...form}>
      <form onSubmit={handleSubmit} className="space-y-3">
        <div className="flex gap-2 items-center">
          <H5>Monthly Targets</H5>
          {!!isSaving && <Loading className="w-5 h-5" />}
        </div>
        <div className="grid grid-cols-3 gap-4">
          {selectedObjective.monthlyProgress.map(
            ({ label, year, month }, index) => (
              <FormField
                key={`T-${year}-${month}`}
                control={form.control}
                name={`targets.${index}.target`}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{label}</FormLabel>
                    <FormControl>
                      <Input
                        type="number"
                        {...field}
                        value={field.value ?? ''}
                        onChange={(e) => {
                          const value =
                            e.target.value === ''
                              ? null
                              : Number(e.target.value)
                          field.onChange(value)
                        }}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            ),
          )}
        </div>
      </form>
    </Form>
  )
}
