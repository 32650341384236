import { useMemo } from 'react'
import {
  Line,
  LineChart as RechartsLineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  ReferenceLine,
} from 'recharts'
import {
  ChartConfig,
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent,
} from '@/components/ui/chart'
import { cn } from '@/lib/utils'
import { ClassNameValue } from 'tailwind-merge'
import colors from 'tailwindcss/colors'
import { CurveType } from 'recharts/types/shape/Curve'
import lodash from 'lodash'
import { renameKeys } from '@/services/utils/renameKeys'
import { useLocalStorage } from '@/hooks/useLocalStorage'

type ChartDataPoint = {
  [key: string]: string | number | null
  label: string
}

export type LineChartProps = {
  data: unknown[]
  lines: string[]
  domain?: number[]
  config: ChartConfig
  type?: CurveType
  legend?: boolean
  className?: ClassNameValue
  initialHiddenLines?: string[]
  referenceLine?: {
    y: number
    stroke: string
    strokeDasharray: string
    label: { value: string; position: 'insideTopRight' | 'insideTopLeft' }
  }
  margin?: { top?: number; right?: number; bottom?: number; left?: number }
  xAxisFormatter?: (_value: number) => string
  yAxisFormatter?: (_value: number) => string
  storageKey?: string
}

export const LINE_CHART_COLORS = [
  colors.green['600'],
  colors.rose['600'],
  colors.sky['600'],
  colors.violet['600'],
  colors.yellow['600'],
]

const formatKey = (key: string) => key.toLowerCase().replace(/ /g, '_')

export default function LineChart({
  data,
  lines,
  config,
  legend,
  className,
  domain,
  type = 'monotone',
  initialHiddenLines,
  referenceLine,
  margin = { left: 10, right: 10, top: 10, bottom: 0 },
  xAxisFormatter,
  yAxisFormatter,
  storageKey,
}: LineChartProps) {
  const [hiddenLines, setHiddenLines] = useLocalStorage<string[]>(
    storageKey ?? 'default-chart-storage',
    initialHiddenLines?.map((line) => formatKey(line)) ?? [],
  )

  const keysToFormat = useMemo(
    () => lodash.fromPairs(lines.map((line) => [line, formatKey(line)])),
    [lines],
  )

  const processedData = useMemo(() => {
    return data.map((elem) => {
      const processed = renameKeys(elem, keysToFormat) as ChartDataPoint
      lines.forEach((line) => {
        const formattedKey = formatKey(line)
        if (processed[formattedKey] === 0) {
          processed[formattedKey] = null
        }
      })
      return processed
    })
  }, [data, keysToFormat, lines])

  return (
    <ChartContainer
      config={renameKeys(config, keysToFormat)}
      className={cn('min-h-[200px] max-h-[250px] w-full m-auto', className)}
    >
      <RechartsLineChart
        accessibilityLayer
        data={processedData}
        margin={margin}
      >
        <CartesianGrid vertical={false} />
        <XAxis
          dataKey="label"
          tickLine={false}
          tickMargin={10}
          axisLine={false}
          tickFormatter={xAxisFormatter}
        />
        <YAxis
          tickLine={false}
          axisLine={false}
          domain={domain || ['auto', 'auto']}
          width={35}
          tickMargin={5}
          tickFormatter={yAxisFormatter}
        />
        <ChartTooltip
          cursor={false}
          content={<ChartTooltipContent hideLabel />}
        />
        {!!legend && (
          <ChartLegend
            verticalAlign="top"
            align="center"
            content={
              <ChartLegendContent
                setHiddenEntries={setHiddenLines}
                hiddenEntries={hiddenLines.map((line) => formatKey(line))}
              />
            }
          />
        )}
        {lines
          .map((line) => formatKey(line))
          .map((line, index) => (
            <Line
              key={line}
              dataKey={line}
              stroke={LINE_CHART_COLORS[index % LINE_CHART_COLORS.length]}
              strokeWidth={2}
              type={type}
              hide={hiddenLines.includes(line)}
              dot={false}
              connectNulls={true}
            />
          ))}
        {referenceLine && (
          <ReferenceLine
            y={referenceLine.y}
            stroke={referenceLine.stroke}
            strokeDasharray={referenceLine.strokeDasharray}
            label={referenceLine.label}
          />
        )}
      </RechartsLineChart>
    </ChartContainer>
  )
}
