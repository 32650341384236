import { useAuth, useOrganization } from '@clerk/clerk-react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import moment from 'moment'
import { DateRange } from 'react-day-picker'

import { deleteResolve, fetchResolve, addQueryParams } from './helpers'
import { useToast } from '@/hooks/use-toast'
import { createServerErrorToast } from '@/lib/toast'

import { FeedbackStats } from '@/types/FeedbackStats'
import { FeedbackPoint } from '@/types/FeedbackPoint'

export interface FeedbackGiverStats {
  id: string
  firstName: string
  lastName: string
  total_feedback: number
  feedback_with_interaction: number
  is_direct_report: boolean
}

export interface UserFeedbackByGiverStats {
  direct_reports: FeedbackGiverStats[]
  others: FeedbackGiverStats[]
  total_stats: {
    total_requested: number
    total_received: number
    total_response_rate: number
    total_meets_checklist: number
    total_does_not_meet_checklist: number
  }
}

export const useFetchFeedbackPoints = () => {
  const { getToken } = useAuth()
  const { organization } = useOrganization()

  return useQuery<FeedbackPoint[]>({
    queryKey: ['useFetchFeedbackPoints', organization?.id],
    queryFn: () =>
      fetchResolve(
        `/organization/${organization?.id}/feedback/point`,
        getToken,
      ),
  })
}

export const useFetchFeedbackStats = (
  teamMemberIds: string[] | 'all' | undefined,
  dateRange?: DateRange,
) => {
  const { getToken } = useAuth()
  const { organization } = useOrganization()

  return useQuery<FeedbackStats>({
    queryKey: [
      'useFetchFeedbackStats',
      organization?.id,
      teamMemberIds,
      dateRange,
    ],
    queryFn: () => {
      const params: Record<string, string> = {}

      if (teamMemberIds) {
        params.teamMemberIds =
          teamMemberIds === 'all' ? 'all' : teamMemberIds.join(',')
      }

      if (dateRange?.from) {
        params.from = moment(dateRange.from).valueOf().toString()
      }

      if (dateRange?.to) {
        params.to = moment(dateRange.to).valueOf().toString()
      }

      const url = addQueryParams(
        `/organization/${organization?.id}/feedback/stats`,
        params,
      )

      return fetchResolve(url, getToken)
    },
  })
}

export const useDeleteFeedbackPoint = () => {
  const queryClient = useQueryClient()
  const { getToken } = useAuth()
  const { organization } = useOrganization()
  const { toast } = useToast()

  return useMutation({
    mutationFn: async ({ id }: { id: string }) =>
      await deleteResolve(
        `/organization/${organization?.id}/feedback/point/${id}`,
        getToken,
      ),
    onSuccess: (_, variables) => {
      queryClient.setQueryData(
        ['useFetchFeedbackPoints', organization?.id],
        (oldData: FeedbackPoint[]) =>
          oldData && oldData.filter((fp) => fp.id !== variables.id),
      )
    },
    onError: (error) => {
      toast(createServerErrorToast(error.message))
    },
  })
}

export const useFetchUserFeedbackStats = (
  userId: string | undefined,
  dateRange?: DateRange,
  options?: { enabled?: boolean },
) => {
  const { getToken } = useAuth()
  const { organization } = useOrganization()

  return useQuery<FeedbackGiverStats[]>({
    queryKey: [
      'useFetchUserFeedbackStats',
      organization?.id,
      userId,
      dateRange,
    ],
    queryFn: () => {
      const params: Record<string, string> = {}

      if (dateRange?.from) {
        params.from = moment(dateRange.from).valueOf().toString()
      }

      if (dateRange?.to) {
        params.to = moment(dateRange.to).valueOf().toString()
      }

      const url = addQueryParams(
        `/organization/${organization?.id}/feedback/stats/user/${userId}`,
        params,
      )

      return fetchResolve(url, getToken)
    },
    enabled: options?.enabled,
  })
}
