import { useMemo, useCallback } from 'react'

export const useDebounce = <
  T extends (..._args: Parameters<T>) => ReturnType<T>,
>(
  callback: T,
  delay: number,
) => {
  const timeoutRef = useMemo(
    () => ({ current: undefined as NodeJS.Timeout | undefined }),
    [],
  )

  return useCallback(
    (...args: Parameters<T>) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }

      timeoutRef.current = setTimeout(() => {
        callback(...args)
      }, delay)
    },
    [callback, delay, timeoutRef],
  )
}
