import { useState, useEffect, useMemo, Fragment, useRef } from 'react'
import { useUser, useOrganization } from '@clerk/clerk-react'
import { Link } from 'react-router-dom'
import { Check } from 'lucide-react'
import debounce from 'lodash/debounce'

import { LayoutTile } from '@/components/layout'
import { H3, Muted, P } from '@/components/ui/typography'
import { Button } from '@/components/ui/button'
import { Separator } from '@/components/ui/separator'
import { Skeleton } from '@/components/ui/skeleton'

import { useUserRole } from '@/hooks/useUserRole'
import { useFetchLicenseInfo } from '@/services/api/billing.api'
import {
  useFetchIntegrations,
  useFetchOrganizationMemberships,
} from '@/services/api/organization.api'
import { useFetchFeedbackPoints } from '@/services/api/feedbackPoints.api'
import { cn } from '@/lib/utils'
import { SLACK_OAUTH_URL } from '@/services/config/env'
import { useGoogleOAuth } from '@/hooks/useGoogleOAuth'
import { useFetchCompanyValues } from '@/services/api/values.api'

interface ChecklistItem {
  id: string
  label: string
  description?: string
  checked: boolean
  action?: {
    label: string
    href?: string
    onClick?: () => Promise<void>
  }
}

const getUserChecklist = (): ChecklistItem[] => [
  {
    id: 'calendar',
    label: 'Connect Google Calendar',
    description:
      'Link your Google Calendar to allow Fiddy to understand with whom you interact.',
    checked: false,
    action: {
      label: 'Connect your calendar',
      onClick: undefined,
    },
  },
  {
    id: 'language',
    label: 'Set your preferred language',
    description:
      'Choose your preferred language for Fiddy to communicate with you.',
    checked: false,
    action: {
      label: 'Go to Settings',
      href: '/settings',
    },
  },
  {
    id: 'feedback',
    label: 'Create your first feedback point',
    description:
      'Give your first feedback to a team member through our helpful Slack bot, Fiddy.',
    checked: false,
    action: {
      label: 'Give feedback to Fiddy',
      href: 'https://slack.com/app_redirect?app=A074N837FB9',
    },
  },
  {
    id: 'orgchart',
    label: 'Check your position in the org chart',
    description:
      'If you are not reporting to the right person, please contact an Admin of your organization.',
    checked: false,
    action: {
      label: 'View Chart',
      href: '/company/organizational-chart',
    },
  },
]

const getAdminChecklist = (
  organizationId: string | undefined,
): ChecklistItem[] => [
  {
    id: 'org',
    label: 'Create organization',
    description:
      'Head to the bottom left of the menu where you see "No Organization Selected" and create yours.',
    checked: false,
    action: {
      label: 'Create your organization',
      href: '/create-organization',
    },
  },
  {
    id: 'slack',
    label: 'Add Fiddy to your Slack',
    description:
      'Install Fiddy in your Slack workspace to allow your team to give feedback.',
    checked: false,
    action: {
      label: 'Add Fiddy to Slack',
      href: organizationId ? SLACK_OAUTH_URL(organizationId) : '#',
    },
  },
  {
    id: 'licenses',
    label: 'Purchase licenses',
    description: 'Acquire the necessary licenses for your team members.',
    checked: false,
    action: {
      label: 'Go to License Management',
      href: '/licensing',
    },
  },
  {
    id: 'members',
    label: 'Invite members',
    description:
      'Add more members to your organization by clicking the "Invite" button in the Members tab.',
    checked: false,
    action: {
      label: 'Invite members',
      href: '/organization',
    },
  },
  {
    id: 'chart',
    label: 'Define organizational chart',
    description:
      'Create your organization structure to enable proper feedback flows.',
    checked: false,
    action: {
      label: 'Go to Organizational Chart',
      href: '/company/organizational-chart',
    },
  },
  {
    id: 'values',
    label: 'Define company values',
    description:
      "Set 3-10 core values that will guide your organization's culture and decision-making.",
    checked: false,
    action: {
      label: 'Define Values',
      href: '/company/values',
    },
  },
  // {
  //   id: 'objective',
  //   label: 'Set the first objective',
  //   description:
  //     'Create your first set of objectives to align your team around a common goal.',
  //   checked: false,
  //   action: {
  //     label: 'Go to Objectives',
  //     href: '/objectives',
  //   },
  // },
]

const ChecklistItem = ({
  item,
  isLoading,
}: {
  item: ChecklistItem
  isLoading: boolean
}) => {
  const [isExpanded, setIsExpanded] = useState(false)

  // Only set initial expansion state after loading is complete
  useEffect(() => {
    if (!isLoading) {
      setIsExpanded(!item.checked)
    }
  }, [isLoading, item.checked])

  if (isLoading) {
    return (
      <div className="p-4">
        <div className="flex items-center gap-3">
          <Skeleton className="h-5 w-5 rounded-full" />
          <Skeleton className="h-6 flex-1" />
        </div>
      </div>
    )
  }

  const handleAction = async (e: React.MouseEvent) => {
    if (item.action?.onClick) {
      e.preventDefault()
      await item.action.onClick()
    }
  }

  return (
    <div
      className={cn(
        'group transition-colors',
        isExpanded ? 'bg-accent/40' : 'hover:bg-accent/20',
      )}
    >
      <div
        onClick={() => setIsExpanded(!isExpanded)}
        className={cn(
          'flex items-center gap-3 p-2 cursor-pointer',
          item.checked && 'text-muted-foreground',
        )}
      >
        <div
          className={cn(
            'flex h-5 w-5 shrink-0 items-center justify-center rounded-full border-2 transition-colors',
            item.checked
              ? 'border-green-600 bg-green-600'
              : 'border-muted-foreground',
          )}
        >
          {item.checked && <Check className="h-3 w-3 text-white" />}
        </div>

        <span className="flex-1 text-sm font-medium">{item.label}</span>
      </div>

      {isExpanded && item.description && (
        <div className="px-4 pb-4 pl-10">
          <div className="flex flex-col sm:flex-row items-start gap-4">
            <Muted className="text-sm flex-1 sm:pb-2">{item.description}</Muted>
            {item.action && (
              <Button
                size="sm"
                className="shrink-0 sm:mt-0"
                asChild={!!item.action.href}
                onClick={item.action.onClick ? handleAction : undefined}
              >
                {item.action.href ? (
                  <Link
                    to={item.action.href}
                    target={
                      item.action.href.startsWith('http') ? '_blank' : undefined
                    }
                    rel={
                      item.action.href.startsWith('http')
                        ? 'noopener noreferrer'
                        : undefined
                    }
                  >
                    {item.action.label}
                  </Link>
                ) : (
                  <span>{item.action.label}</span>
                )}
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

const CompletionCircle = ({
  completed,
  total,
  isLoading,
}: {
  completed: number
  total: number
  isLoading: boolean
}) => {
  if (isLoading) {
    return (
      <div className="flex items-center gap-2">
        <Skeleton className="h-4 w-4 rounded-full" />
        <Skeleton className="h-4 w-24" />
      </div>
    )
  }

  const percentage = total === 0 ? 0 : (completed / total) * 100
  const strokeWidth = 2
  const size = 16
  const radius = (size - strokeWidth) / 2
  const circumference = 2 * Math.PI * radius
  const strokeDashoffset = circumference - (percentage / 100) * circumference

  return (
    <div className="flex items-center gap-2 text-muted-foreground">
      <div className="relative h-4 w-4">
        <svg
          className="absolute transform -rotate-90"
          width={size}
          height={size}
        >
          <circle
            className="text-border"
            strokeWidth={strokeWidth}
            stroke="currentColor"
            fill="none"
            r={radius}
            cx={size / 2}
            cy={size / 2}
          />
          <circle
            className="text-green-600 transition-all duration-300 ease-in-out"
            strokeWidth={strokeWidth}
            strokeDasharray={circumference}
            strokeDashoffset={strokeDashoffset.toString()}
            strokeLinecap="round"
            stroke="currentColor"
            fill="none"
            r={radius}
            cx={size / 2}
            cy={size / 2}
          />
        </svg>
      </div>
      <span className="text-sm">
        {completed} out of {total} steps
      </span>
    </div>
  )
}

const ChecklistSection = ({
  items,
  title,
  isLoading,
}: {
  items: ChecklistItem[]
  title: string
  isLoading: boolean
}) => {
  const completedCount = items.filter((item) => item.checked).length

  return (
    <div className="rounded-xl border-2 border-border/60">
      <div className="flex items-center justify-between px-4 py-3 border-b-2 border-border/60">
        <P className="font-medium">{title}</P>
        <CompletionCircle
          completed={completedCount}
          total={items.length}
          isLoading={isLoading}
        />
      </div>
      <div>
        {items.map((item, index) => (
          <Fragment key={item.id}>
            <ChecklistItem item={item} isLoading={isLoading} />
            {index < items.length - 1 && <Separator />}
          </Fragment>
        ))}
      </div>
    </div>
  )
}

export const OnboardingPage = () => {
  const { isAdmin } = useUserRole()
  const { user } = useUser()
  const { organization } = useOrganization()
  const { data: licenseInfo, isLoading: isLicenseLoading } =
    useFetchLicenseInfo()
  const { data: memberships = [], isLoading: isMembershipsLoading } =
    useFetchOrganizationMemberships()
  const { data: feedbackPoints = [], isLoading: isFeedbackLoading } =
    useFetchFeedbackPoints()
  const { data: integrations = [], isLoading: isLoadingIntegrations } =
    useFetchIntegrations()
  const { connectGoogle } = useGoogleOAuth({
    redirectUrl: `${window.location.origin}/onboarding`,
  })
  const { data: values = [], isLoading: isValuesLoading } =
    useFetchCompanyValues()

  const [checklist, setChecklist] = useState<ChecklistItem[]>([])
  const initialized = useRef(false)

  // Memoize loading state
  const isLoading = useMemo(
    () =>
      isLicenseLoading ||
      isMembershipsLoading ||
      isFeedbackLoading ||
      isLoadingIntegrations ||
      isValuesLoading,
    [
      isLicenseLoading,
      isMembershipsLoading,
      isFeedbackLoading,
      isLoadingIntegrations,
      isValuesLoading,
    ],
  )

  const hasGoogleConnected = useMemo(
    () =>
      !!user?.externalAccounts?.find(
        (acc) =>
          acc.provider === 'google' && acc.verification?.status === 'verified',
      )?.emailAddress,
    [user],
  )

  const userMembership = useMemo(
    () => memberships.find((m) => m.user.id === user?.id),
    [memberships, user?.id],
  )

  // Create base checklist with Google connect action
  const baseChecklist = useMemo(() => {
    return getUserChecklist().map((item) => {
      if (item.id === 'calendar') {
        return {
          ...item,
          action: {
            label: item.action?.label || '',
            onClick: async () => {
              await connectGoogle()
            },
          },
        } as ChecklistItem
      }
      return item
    })
  }, [connectGoogle])

  // Memoize filtered items
  const userItems = useMemo(
    () =>
      isAdmin
        ? checklist.filter((item) =>
            getUserChecklist().some((userItem) => userItem.id === item.id),
          )
        : checklist,
    [checklist, isAdmin],
  )

  const adminItems = useMemo(
    () =>
      isAdmin
        ? checklist.filter((item) =>
            getAdminChecklist(organization?.id).some(
              (adminItem) => adminItem.id === item.id,
            ),
          )
        : [],
    [checklist, isAdmin, organization?.id],
  )

  // Debounced save to localStorage
  const debouncedSave = useMemo(
    () =>
      debounce((checklist: ChecklistItem[], userId: string) => {
        localStorage.setItem(`onboarding-${userId}`, JSON.stringify(checklist))
      }, 1000),
    [],
  )

  // Initialize checklist once
  useEffect(() => {
    if (initialized.current || isLoading) return

    // Start with base checklist
    let newChecklist = isAdmin
      ? [
          ...baseChecklist.filter((item) => item.id !== 'orgchart'),
          ...getAdminChecklist(organization?.id),
        ]
      : baseChecklist

    // Load saved state from localStorage if available
    if (user?.id) {
      const savedChecklist = localStorage.getItem(`onboarding-${user.id}`)
      if (savedChecklist) {
        const savedItems = JSON.parse(savedChecklist)
        newChecklist = newChecklist.map((item) => ({
          ...item,
          checked:
            savedItems.find((saved: ChecklistItem) => saved.id === item.id)
              ?.checked || false,
        })) as ChecklistItem[]
      }
    }

    setChecklist(newChecklist)
    initialized.current = true
  }, [isLoading, isAdmin, user?.id, organization?.id, baseChecklist])

  // Auto-check items based on system state
  useEffect(() => {
    if (isLoading || !initialized.current) return

    const updatedChecklist = checklist.map((item) => {
      let shouldBeChecked = item.checked

      if (isAdmin) {
        if (item.id === 'org') {
          shouldBeChecked = !!organization
        } else if (item.id === 'licenses') {
          shouldBeChecked = (licenseInfo?.usedLicenses || 0) > 0
        } else if (item.id === 'members') {
          shouldBeChecked = memberships.length > 1
        } else if (item.id === 'chart') {
          const totalUsers = memberships.length
          const usersWithManager = memberships.filter((m) => m.manager).length
          const percentageWithManager = (usersWithManager / totalUsers) * 100
          shouldBeChecked = percentageWithManager >= 50
        } else if (item.id === 'slack') {
          shouldBeChecked =
            Array.isArray(integrations) &&
            integrations.some(
              (integration) =>
                integration.type === 'SLACK' && integration.enabled,
            )
        } else if (item.id === 'values') {
          const validValuesCount = values.length
          shouldBeChecked = validValuesCount >= 3 && validValuesCount <= 10
        }
      }

      if (item.id === 'language') {
        shouldBeChecked =
          userMembership?.preferredLanguage !== null &&
          userMembership?.preferredLanguage !== undefined
      } else if (item.id === 'calendar') {
        shouldBeChecked = hasGoogleConnected
      } else if (item.id === 'feedback') {
        shouldBeChecked = feedbackPoints.length > 0
      } else if (item.id === 'orgchart') {
        shouldBeChecked = !!userMembership?.manager
      }

      return shouldBeChecked !== item.checked
        ? { ...item, checked: shouldBeChecked }
        : item
    })

    if (JSON.stringify(updatedChecklist) !== JSON.stringify(checklist)) {
      setChecklist(updatedChecklist)
    }
  }, [
    isLoading,
    isAdmin,
    organization,
    licenseInfo,
    memberships,
    hasGoogleConnected,
    feedbackPoints,
    userMembership,
    integrations,
    checklist,
    values,
  ])

  // Save checklist state to localStorage with debounce
  useEffect(() => {
    if (user?.id && !isLoading && initialized.current) {
      debouncedSave(checklist, user.id)
    }
  }, [checklist, user?.id, isLoading, debouncedSave])

  // Memoize completion counts
  const { completedCount, totalCount } = useMemo(() => {
    const completed = checklist.filter((item) => item.checked).length
    return {
      completedCount: completed,
      totalCount: checklist.length,
    }
  }, [checklist])

  return (
    <LayoutTile>
      <div className="space-y-2">
        <div className="flex items-center justify-between">
          <H3 className="font-semibold">Get started with FidForward</H3>
          <CompletionCircle
            completed={completedCount}
            total={totalCount}
            isLoading={isLoading}
          />
        </div>
        <P className="text-sm text-muted-foreground">
          Complete the following steps to get your organization ready for
          feedback.
        </P>
      </div>

      <div className="space-y-6 pt-4">
        {isAdmin && (
          <ChecklistSection
            items={adminItems}
            title="Prepare FidForward for your organization (Admin-only)"
            isLoading={isLoading}
          />
        )}
        <ChecklistSection
          items={userItems}
          title="Get your account ready for feedback"
          isLoading={isLoading}
        />
      </div>
    </LayoutTile>
  )
}
