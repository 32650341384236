import { TableCell, TableRow } from '@/components/ui/table'
import { Switch } from '@/components/ui/switch'
import { Muted } from '@/components/ui/typography'

import { usePatchOrganizationMembership } from '@/services/api/organization.api'

import type { OrganizationMembership } from '@/types/UserProfile'

interface UserRowProps {
  membership: OrganizationMembership
  disabled: boolean
}

export const MembershipRow = ({ membership, disabled }: UserRowProps) => {
  const { user } = membership
  const { mutate: patchOrganizationMembership, isPending } =
    usePatchOrganizationMembership()

  const onToggleUserLicense = (newValue: boolean) => {
    patchOrganizationMembership({
      membershipId: membership.id,
      body: { isLicensed: newValue },
    })
  }

  return (
    <TableRow>
      <TableCell className="px-2 py-2 sm:px-2">
        <div className="flex flex-col">
          <span className="text-sm font-medium">
            {user.firstName} {user.lastName}
          </span>
          <span className="text-xs text-muted-foreground sm:hidden">
            {user.emails[0]}
          </span>
        </div>
      </TableCell>
      <TableCell className="px-2 py-2 hidden sm:table-cell">
        <Muted>{user.emails[0]}</Muted>
      </TableCell>
      <TableCell className="px-2 py-2 text-right sm:px-4">
        <Switch
          onCheckedChange={onToggleUserLicense}
          checked={membership.isLicensed}
          disabled={disabled || isPending}
          aria-readonly
        />
      </TableCell>
    </TableRow>
  )
}
