import { useState, useMemo } from 'react'
import { Table, TableBody } from '@/components/ui/table'
import { ObjectiveRow } from './objective-row'
import { ObjectivesHeader } from './header'
import { EditSidebar } from './edit-sidebar'

import { PerformanceCycle } from '@/types/PerformanceCycle'
import {
  type Objective,
  type ObjectiveValue,
  convertToObjectiveUI,
  getMonthId,
} from '@/types/Objective'

interface ObjectivesTableProps {
  objectives: Objective[]
  performanceCycle: PerformanceCycle
}

export function ObjectivesTable({
  objectives,
  performanceCycle,
}: ObjectivesTableProps) {
  const objectivesUI = useMemo(
    () => convertToObjectiveUI(performanceCycle, objectives),
    [performanceCycle, objectives],
  )
  const [selectedValue, setSelectedValue] = useState<ObjectiveValue | null>(
    null,
  )
  const [expandedMonths, setExpandedMonths] = useState<string[]>([])

  const toggleMonth = (year: number, month: number) => {
    const monthId = getMonthId(year, month)
    if (expandedMonths.includes(monthId)) {
      setExpandedMonths((prev) => prev.filter((p) => p !== monthId))
      return
    }
    setExpandedMonths((prev) => [...prev, monthId])
  }

  const handleClickWeek = (value: ObjectiveValue) => {
    setSelectedValue(value)
  }

  return (
    <div className="space-y-4">
      <Table>
        <ObjectivesHeader
          months={objectivesUI[0]?.monthlyProgress || []}
          expandedMonths={expandedMonths}
          onToggleMonth={toggleMonth}
        />
        <TableBody>
          {objectivesUI.map((objective) => (
            <ObjectiveRow
              key={objective.id}
              objective={objective}
              onClickWeek={handleClickWeek}
              expandedMonths={expandedMonths}
            />
          ))}
        </TableBody>
      </Table>
      <EditSidebar
        selectedObjective={objectivesUI.find(
          (objective) => objective.id === selectedValue?.teamObjectiveId,
        )}
        selectedValue={selectedValue}
        setSelectedValue={setSelectedValue}
      />
    </div>
  )
}
