import { useNavigate } from 'react-router-dom'

import { Icon } from '@/components/ui/icon'
import { Muted } from '@/components/ui/typography'
import { Button } from '@/components/ui/button'

export const NoPerformanceCycleTile = () => {
  const navigate = useNavigate()

  return (
    <div className="flex-1 rounded-2xl flex flex-col items-center justify-center gap-4 bg-white">
      <Icon name="BarChart" className="w-16 h-16 text-yellow-500" />
      <Muted className="font-medium">
        You need to configure at least one Performance Cycle
      </Muted>
      <Button onClick={() => navigate('/settings')}>
        Configure Performance Cycle
      </Button>
    </div>
  )
}
