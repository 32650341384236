import { CHATWOOT_HMAC_TOKEN } from '@/services/config/env'

export async function generateChatwootHMAC(
  identifier: string,
): Promise<string> {
  if (!CHATWOOT_HMAC_TOKEN) {
    console.warn('CHATWOOT_HMAC_TOKEN is not set')
    return ''
  }

  // Convert the strings to Uint8Arrays for the crypto API
  const encoder = new TextEncoder()
  const keyData = encoder.encode(CHATWOOT_HMAC_TOKEN)
  const messageData = encoder.encode(identifier)

  // Import the key
  const key = await crypto.subtle.importKey(
    'raw',
    keyData,
    { name: 'HMAC', hash: 'SHA-256' },
    false,
    ['sign'],
  )

  // Sign the message
  const signature = await crypto.subtle.sign('HMAC', key, messageData)

  // Convert the signature to hex string
  return Array.from(new Uint8Array(signature))
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('')
}
