import { useMemo, useState } from 'react'

import { Sheet } from '@/components/common/sheet'
import { Dialog } from '@/components/common/dialog'
import LineChart from '@/components/common/line-chart'
import { MonthlyTargetsForm } from '@/components/objectives/monthly-targets-form'
import { ObjectiveForm } from '@/components/objectives/objective-form'
import { Button } from '@/components/ui/button'
import { Icon } from '@/components/ui/icon'
import { Timeline } from './timeline'
import { ObjectiveValueForm } from './objective-value-form'

import { formatValue } from './objective-row'
import {
  TeamObjectiveCreateSchemaType,
  TeamObjectiveTargetSchemaType,
  TeamObjectiveValueSchemaType,
} from '@/lib/schema/objective-form.schema'
import {
  useDeleteObjective,
  usePatchObjective,
  usePatchObjectiveTarget,
  usePatchObjectiveValue,
} from '@/services/api/objective.api'

import type { ObjectiveUI, ObjectiveValue } from '@/types/Objective'
import type { SetState } from '@/types/globals'

interface EditSidebarProps {
  selectedObjective: ObjectiveUI | undefined
  selectedValue: ObjectiveValue | null
  setSelectedValue: SetState<ObjectiveValue | null>
}

export function EditSidebar({
  selectedObjective,
  selectedValue,
  setSelectedValue,
}: EditSidebarProps) {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)

  const { mutate: deleteObjective, isPending: isDeleting } = useDeleteObjective(
    selectedObjective?.performanceCycle?.id,
  )
  const { mutate: patchObjective, isPending: isSavingObjective } =
    usePatchObjective(selectedObjective?.performanceCycle?.id)
  const { mutate: patchObjectiveTarget, isPending: isSavingMonthlyTarget } =
    usePatchObjectiveTarget(selectedObjective?.performanceCycle?.id)
  const { mutate: patchObjectiveValue, isPending: isSavingObjectiveValue } =
    usePatchObjectiveValue(selectedObjective?.performanceCycle?.id)

  const chartData = useMemo(() => {
    if (!selectedObjective) return []

    const weeks = []
    for (const month of selectedObjective.monthlyProgress) {
      for (const week of month.weeks) {
        weeks.push({
          value: week.value ?? 0,
          label: `W${week.week}`,
          target: month.target,
        })
      }
    }

    if (selectedObjective.type === 'sum') {
      let cumulative = 0
      return weeks.map((week) => ({
        ...week,
        value: (cumulative += week.value),
      }))
    } else if (selectedObjective.type === 'lastColumn') {
      let lastNonNullValue = 0
      return weeks.map((week) => ({
        ...week,
        value:
          week.value !== 0 ? (lastNonNullValue = week.value) : lastNonNullValue,
      }))
    }

    return weeks
  }, [selectedObjective])

  const onClose = () => {
    setSelectedValue(null)
  }

  function onSubmitObjectiveForm(objective: TeamObjectiveCreateSchemaType) {
    if (!selectedObjective) {
      return
    }

    patchObjective({ id: selectedObjective.id, body: objective })
  }

  function onSubmitMonthlyTargetForm(target: TeamObjectiveTargetSchemaType) {
    if (!selectedObjective) {
      return
    }

    patchObjectiveTarget({
      teamObjectiveId: selectedObjective.id,
      body: target,
    })
  }

  function onSubmitObjectiveValueForm(value: TeamObjectiveValueSchemaType) {
    if (!selectedObjective) {
      return
    }

    patchObjectiveValue({
      teamObjectiveId: selectedObjective.id,
      body: value,
    })
  }

  const onDeleteClick = () => {
    if (!selectedObjective) {
      return
    }

    deleteObjective(selectedObjective.id, {
      onSuccess: () => {
        setSelectedValue(null)
        setDeleteDialogOpen(false)
      },
    })
  }

  if (!selectedObjective) return null

  return (
    <Sheet
      isOpen={!!selectedObjective}
      setIsOpen={onClose}
      title="Objective Progress"
      width="w-[800px] sm:max-w-[800px] overflow-auto"
      headerActions={
        <Dialog
          isOpen={deleteDialogOpen}
          setIsOpen={setDeleteDialogOpen}
          trigger={
            <Button
              type="button"
              variant="ghost"
              size="icon"
              onClick={() => setDeleteDialogOpen(true)}
            >
              <Icon name="Trash2" className="text-red-600" />
            </Button>
          }
          title="Delete Objective"
          description="Are you sure you want to delete the selected objective?"
          onConfirm={onDeleteClick}
          confirmText={isDeleting ? 'Deleting...' : 'Delete'}
          isConfirmDisabled={isDeleting}
        />
      }
    >
      <div className="space-y-6 pt-4">
        <ObjectiveForm
          onSubmit={onSubmitObjectiveForm}
          defaultValues={{
            title: selectedObjective.title,
            description: selectedObjective.description,
            type: selectedObjective.type,
            owners: selectedObjective.owners,
            performanceCycleId: selectedObjective.performanceCycle.id,
          }}
          isSaving={isSavingObjective}
          isDisabled={isDeleting}
          autoSave
        />
        <MonthlyTargetsForm
          selectedObjective={selectedObjective}
          onSubmit={onSubmitMonthlyTargetForm}
          isSaving={isSavingMonthlyTarget}
        />
        <LineChart
          data={chartData}
          lines={['value']}
          config={{
            value: {
              label: 'Progress',
              color: 'primary',
            },
          }}
          domain={[Math.min(...chartData.map((d) => d.value))]}
          storageKey="objective-edit-chart"
          className="h-[150px]"
          yAxisFormatter={(value) => formatValue(value, 'number')}
        />
        <div className="flex">
          <Timeline
            selectedObjective={selectedObjective}
            selectedValue={selectedValue}
            setSelectedValue={setSelectedValue}
          />
          {!!selectedValue && (
            <div className="flex-1 px-4">
              <ObjectiveValueForm
                onSubmit={onSubmitObjectiveValueForm}
                defaultValues={{
                  value: selectedValue.value,
                  week: selectedValue.week,
                  year: selectedValue.year,
                }}
                isSaving={isSavingObjectiveValue}
              />
            </div>
          )}
        </div>
      </div>
    </Sheet>
  )
}
