import { Icon } from '@/components/ui/icon'
import { Muted } from '@/components/ui/typography'
import { CustomOrganizationSwitcher } from '../custom-organization-switcher'

export const NoOrganization = () => {
  return (
    <div className="flex-1 rounded-2xl flex flex-col items-center justify-center gap-4 bg-white">
      <Icon name="Building2" className="w-16 h-16 text-yellow-500" />
      <Muted className="font-medium">You need to select an Organization</Muted>
      <div className="max-w-[500px]">
        <CustomOrganizationSwitcher />
      </div>
    </div>
  )
}
