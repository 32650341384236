import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { useOrganization, useAuth } from '@clerk/clerk-react'
import { useToast } from '@/hooks/use-toast'
import { createServerErrorToast } from '@/lib/toast'

import { fetchResolve, postResolve } from './helpers'

import { UpdateCompanyValuesSchemaType } from '@/lib/schema/company-values.schema'

import type { CompanyValue } from '@/types/CompanyValue'

export const useFetchCompanyValues = () => {
  const { organization } = useOrganization()
  const { getToken } = useAuth()

  return useQuery<CompanyValue[]>({
    queryKey: ['useFetchCompanyValues', organization?.id],
    queryFn: () =>
      fetchResolve(`/organization/${organization?.id}/values`, getToken),
  })
}

export const useUpdateCompanyValues = () => {
  const queryClient = useQueryClient()
  const { getToken } = useAuth()
  const { organization } = useOrganization()
  const { toast } = useToast()

  return useMutation({
    mutationFn: async (data: UpdateCompanyValuesSchemaType) =>
      postResolve(
        `/organization/${organization?.id}/value`,
        data.values,
        getToken,
      ),
    onSuccess: (data: CompanyValue[]) => {
      queryClient.setQueryData(
        ['useFetchCompanyValues', organization?.id],
        data,
      )
    },
    onError: (error) => {
      toast(createServerErrorToast(error.message))
    },
  })
}
