import { useOrganization } from '@clerk/clerk-react'
import { Users } from 'lucide-react'

import { Icon } from '@/components/ui/icon'
import { Button } from '@/components/ui/button'

import { useGetPortalUrl } from '@/services/api/billing.api'
import { MOCK_SERVER_ENABLED } from '@/services/config/env'
import { H6, P } from '../ui/typography'

interface ManageLicensesCardProps {
  licensedUsersCount: number
  purchasedLicensesCount: number
}

function ManageLicensesCard({
  licensedUsersCount,
  purchasedLicensesCount,
}: ManageLicensesCardProps) {
  const { organization } = useOrganization()
  const getPortalUrl = useGetPortalUrl()

  async function onManageSubscriptionClicked() {
    const url = await getPortalUrl.mutateAsync()
    window.location.href = url
  }

  if (MOCK_SERVER_ENABLED) {
    return (
      <div className="flex items-center justify-between w-full">
        <div>
          <H6 className="font-medium">License Usage</H6>
          <P className="text-sm text-muted-foreground">
            You are currently using a demo account
          </P>
        </div>
        <Button
          className="bg-primary rounded-lg"
          onClick={() =>
            window.open('https://fidforward.com/pricing/', '_blank')
          }
        >
          <Users className="mr-2 h-4 w-4" />
          View pricing plans
        </Button>
      </div>
    )
  }

  return (
    <div className="flex items-center justify-between w-full">
      <div>
        <H6 className="font-medium">License Usage</H6>
        <P className="text-sm text-muted-foreground">
          {organization?.name} is using {licensedUsersCount} of{' '}
          {purchasedLicensesCount} seats
        </P>
      </div>
      <Button
        className="bg-primary rounded-lg"
        onClick={onManageSubscriptionClicked}
        disabled={getPortalUrl.isPending}
      >
        {getPortalUrl.isPending ? (
          <Icon name="Loader" className="mr-2 h-4 w-4 animate-spin" />
        ) : (
          <Users className="mr-2 h-4 w-4" />
        )}
        Manage your seats
      </Button>
    </div>
  )
}

export default ManageLicensesCard
