import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  ReactFlow,
  Controls,
  Background,
  useNodesState,
  ConnectionLineType,
  Handle,
  Position,
  Edge,
  Node,
  useEdges,
  useEdgesState,
  useReactFlow,
} from '@xyflow/react'
import dagre from '@dagrejs/dagre'

import { Muted, P } from '@/components/ui/typography'
import { AvatarWithFallback } from '@/components/ui/avatar'
import { Separator } from '@/components/ui/separator'
import { Button } from '@/components/ui/button'
import { Icon } from '@/components/ui/icon'
import { Select } from '@/components/common/select'
import { Label } from '@/components/ui/label'
import { Loading } from '@/components/ui/loading'
import { Tooltip } from '@/components/common/tooltip'
import { RightBar } from '@/components/common/right-bar'

import {
  getUserAvatarFallback,
  getUserDisplayName,
  toProperCase,
} from '@/services/utils/formatters'
import { usePatchOrganizationMembership } from '@/services/api/organization.api'
import { cn } from '@/lib/utils'
import { useUserRole } from '@/hooks/useUserRole'
import { MOCK_SERVER_ENABLED } from '@/services/config/env'

import type { OrganizationMembership } from '@/types/UserProfile'

import '@xyflow/react/dist/style.css'

const nodeWidth = 200
const nodeHeight = 120

const OrgChartNode = ({
  data,
}: {
  data: OrganizationMembership & {
    isCollapsed: boolean
    selected: boolean
    onClickExpand: (
      _e: React.MouseEvent<HTMLButtonElement>,
      _nodeId: string,
    ) => void
  }
}) => {
  const [hover, setHover] = useState(false)

  const edges = useEdges()
  const displayName = useMemo(() => getUserDisplayName(data.user), [data])
  const directReports = useMemo(
    () => edges.filter((edge) => edge.source === data.user.id).length,
    [data.user.id, edges],
  )

  return (
    <>
      <div
        className={cn(
          `w-[${nodeWidth}px] p-4 flex flex-col rounded-xl border bg-white cursor-pointer hover:opacity-80`,
          data.selected && 'border-green-600',
          data.selected && 'border-2',
        )}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <div className="flex flex-col gap-3 items-center">
          <AvatarWithFallback
            className="h-12 w-12 border-2 border-primary"
            image={data.user.image}
            fallback={getUserAvatarFallback(data.user)}
          />
          <Muted>{displayName}</Muted>
        </div>
      </div>
      {!!directReports && (
        <Button
          className="absolute -bottom-2 left-1/2 -translate-x-1/2 w-5 h-5 rounded-full bg-primary flex items-center justify-center z-10"
          variant="ghost"
          size="icon"
          onClick={(e) => data.onClickExpand(e, data.user.id)}
        >
          <span className="text-xs font-medium text-white">
            {!hover ? (
              directReports
            ) : (
              <Icon
                name={data.isCollapsed ? 'ChevronDown' : 'ChevronUp'}
                className="w-3 h-3"
              />
            )}
          </span>
        </Button>
      )}
      <Handle
        type="target"
        position={Position.Top}
        style={{ top: 0, opacity: 0 }}
      />
      <Handle
        type="source"
        position={Position.Bottom}
        style={{ bottom: 0, top: 'auto', opacity: 0 }}
      />
    </>
  )
}

const nodeTypes = { member: OrgChartNode }

interface OrgChartProps {
  orgChart: OrganizationMembership[]
}

export const OrgChart = ({ orgChart = [] }: OrgChartProps) => {
  const { getNodes, getEdges } = useReactFlow()
  const [selectedNode, setSelectedNode] = useState<Node | undefined>(undefined)
  const [nodes, setNodes] = useNodesState<Node>([])
  const [, setEdges] = useEdgesState<Edge>([])

  const getLayoutedElements = useCallback(
    (nodes: Node[], edges: Edge[], nodeToKeepPositionId?: string) => {
      const dagreGraph = new dagre.graphlib.Graph().setDefaultEdgeLabel(
        () => ({}),
      )
      dagreGraph.setGraph({ rankdir: 'TB' })

      // Filter visible nodes
      const visibleNodes = nodes.filter((node) => !node.hidden)

      // Only add visible nodes to the dagre graph
      visibleNodes.forEach((node) => {
        dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight })
      })

      // Only add edges where both source and target nodes are visible
      const visibleNodeIds = new Set(visibleNodes.map((node) => node.id))
      const visibleEdges = edges.filter(
        (edge) =>
          visibleNodeIds.has(edge.source) && visibleNodeIds.has(edge.target),
      )

      visibleEdges.forEach((edge) => {
        dagreGraph.setEdge(edge.source, edge.target)
      })

      dagre.layout(dagreGraph)

      // We should see if we need to translate the graph here to keep a given
      // node in the same position
      let positionTranslate = { x: -nodeWidth / 2, y: -nodeHeight / 2 }
      if (nodeToKeepPositionId) {
        const nodeWithPosition = nodes.find(
          (node) => node.id === nodeToKeepPositionId,
        )!

        const newNode = dagreGraph.node(nodeToKeepPositionId)
        positionTranslate = {
          x: nodeWithPosition.position.x - newNode.x,
          y: nodeWithPosition.position.y - newNode.y,
        }
      }

      // Map positions only for visible nodes, keep hidden nodes unchanged
      const newNodes = nodes.map((node) => {
        // If node is hidden (hidden === false), return it unchanged
        if (node.hidden === true) {
          return node
        }

        const nodeWithPosition = dagreGraph.node(node.id)
        const newNode = {
          ...node,
          targetPosition: 'top',
          sourcePosition: 'bottom',
          position: {
            x: nodeWithPosition.x + positionTranslate.x,
            y: nodeWithPosition.y + positionTranslate.y,
          },
        } as Node
        return newNode
      })

      return { nodes: newNodes }
    },
    [],
  )

  /** Helper function to get all descendant node IDs */
  const getDescendants = useCallback(
    (
      nodeId: string,
      edges: Edge[],
      level = 1,
    ): { nodeId: string; level: number }[] => {
      const children = edges
        .filter((edge) => edge.source === nodeId)
        .map((edge) => ({ nodeId: edge.target, level }))

      const descendants = [...children]
      children.forEach((node) => {
        descendants.push(...getDescendants(node.nodeId, edges, level + 1))
      })

      return descendants
    },
    [],
  )

  const handleExpandClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>, nodeId: string) => {
      e.stopPropagation()

      let newNodes = getNodes()
      let newEdges = getEdges()
      const node = newNodes.find((n) => n.id === nodeId)!

      newNodes = newNodes.map((n) => ({
        ...n,
        data: {
          ...n.data,
          isCollapsed:
            n.id === node.id ? !n.data.isCollapsed : n.data.isCollapsed,
        },
      }))
      const descendants = getDescendants(node.id, newEdges)

      if (!node.data.isCollapsed) {
        // Hide all descendant nodes and their edges
        newNodes = newNodes.map((n) => ({
          ...n,
          hidden: descendants.some((d) => d.nodeId === n.id) ? true : n.hidden,
        }))
        newEdges = newEdges.map((e) => ({
          ...e,
          hidden:
            descendants.some((d) => d.nodeId === e.target) ||
            descendants.some((d) => d.nodeId === e.source)
              ? true
              : e.hidden,
        }))
      } else {
        // Show all descendant nodes and their edges
        newNodes = newNodes.map((n) => ({
          ...n,
          hidden: descendants.some((d) => d.level === 1 && d.nodeId === n.id)
            ? false
            : n.hidden,
        }))
        newEdges = newEdges.map((e) => ({
          ...e,
          hidden:
            descendants.some((d) => d.level === 1 && d.nodeId == e.target) ||
            descendants.some((d) => d.level === 1 && d.nodeId === e.source)
              ? false
              : e.hidden,
        }))
      }

      const { nodes: layoutedNodes } = getLayoutedElements(
        newNodes,
        newEdges,
        nodeId,
      )

      setNodes(layoutedNodes)
      setEdges(newEdges)
    },
    [
      setNodes,
      setEdges,
      getEdges,
      getDescendants,
      getNodes,
      getLayoutedElements,
    ],
  )

  const transformedNodes = useMemo<Node[]>(
    () =>
      orgChart.map((o) => ({
        id: o.user.id,
        type: 'member',
        position: { x: 0, y: 0 },
        data: {
          ...o,
          onClickExpand: handleExpandClick,
        } as unknown as Record<string, unknown>,
      })),
    [orgChart, handleExpandClick],
  )
  const transformedEdges = useMemo<Edge[]>(
    () =>
      orgChart
        .filter((n) => !!n.manager?.id)
        .map((n) => ({
          id: crypto.randomUUID(),
          source: n.manager!.id,
          target: n.user.id,
          type: 'smoothstep',
        })),
    [orgChart],
  )
  const { nodes: layoutedNodes } = useMemo(
    () => getLayoutedElements(transformedNodes, transformedEdges),
    [transformedEdges, transformedNodes, getLayoutedElements],
  )

  useEffect(() => {
    setNodes([...layoutedNodes])
  }, [layoutedNodes, setNodes])

  useEffect(() => {
    if (selectedNode) {
      setSelectedNode(
        nodes.find(
          (node) =>
            (node.data as unknown as OrganizationMembership).user.id ===
            (selectedNode.data as unknown as OrganizationMembership).user.id,
        ),
      )
    }
  }, [orgChart, selectedNode, nodes])

  const handleNodeClick = useCallback(
    (_e: unknown, elem: Node) => {
      const nodes = getNodes()
      const newNodes = nodes.map((node) => ({
        ...node,
        data: {
          ...node.data,
          selected: node.id === elem.id,
        },
      }))
      setNodes(newNodes)
      setSelectedNode(elem)
    },
    [getNodes, setNodes],
  )

  const onCloseMemberInfo = () => {
    setNodes((nds) =>
      nds.map((n) => ({
        ...n,
        data: {
          ...n.data,
          selected: false,
        },
      })),
    )
    setSelectedNode(undefined)
  }

  const shouldShowExcelMessage = useMemo(() => {
    if (!orgChart.length) return false
    const usersWithoutManager = orgChart.filter(
      (member) => !member.manager?.id,
    ).length
    return usersWithoutManager / orgChart.length > 0.8
  }, [orgChart])

  return (
    <div className="h-full flex">
      {shouldShowExcelMessage && (
        <div className="absolute top-4 right-4 z-1 bg-white/80 backdrop-blur-sm rounded-lg p-3 shadow-sm">
          <P className="text-sm space-y-1">
            <div>
              <Icon
                name="FileSpreadsheet"
                className="inline-block w-4 h-4 mr-2"
              />
              In case you prefer, send us your org chart in CSV/Excel format to{' '}
              <a
                href="mailto:hey@fidforward.com"
                className="text-primary hover:underline"
              >
                hey@fidforward.com
              </a>
              .
            </div>
            <div>
              Put the members&apos; emails in the first column, with their
              manager&apos;s email in the second column.
            </div>
          </P>
        </div>
      )}
      <ReactFlow
        className="flex-1"
        nodes={nodes}
        edges={transformedEdges}
        nodeTypes={nodeTypes}
        connectionLineType={ConnectionLineType.SmoothStep}
        onNodeClick={handleNodeClick}
        nodesConnectable={false}
        connectOnClick={false}
        maxZoom={1}
        minZoom={0.5}
        fitView
      >
        <Background />
        <Controls />
      </ReactFlow>
      <OrganizationMemberInfo
        orgChart={orgChart}
        selectedNode={selectedNode?.data as unknown as OrganizationMembership}
        onClose={onCloseMemberInfo}
      />
    </div>
  )
}

interface OrganizationMemberInfoProps {
  orgChart: OrganizationMembership[]
  selectedNode?: OrganizationMembership
  onClose?: () => void
}

const OrganizationMemberInfo = ({
  orgChart,
  selectedNode,
  onClose,
}: OrganizationMemberInfoProps) => {
  const { isAdmin } = useUserRole()
  const [isVisible, setIsVisible] = useState(false)
  const { mutate: patchOrganizationMembership, isPending: isLoadingPatch } =
    usePatchOrganizationMembership()

  const managerOptions = useMemo(() => {
    if (!selectedNode) return []

    return orgChart
      .filter((o) => o.user.id !== selectedNode.user.id)
      .map((o) => ({
        value: o.user.id,
        label: getUserDisplayName(o.user),
      }))
  }, [orgChart, selectedNode])

  const handleManagerChange = async (newManagerId: string | null) => {
    if (!selectedNode || MOCK_SERVER_ENABLED) return

    patchOrganizationMembership({
      membershipId: selectedNode.id,
      body: { managerId: newManagerId },
    })
  }

  const handleClose = () => {
    setIsVisible(false)
    onClose && onClose()
  }

  useEffect(() => {
    if (selectedNode) {
      return setIsVisible(true)
    }
    setIsVisible(false)
  }, [selectedNode])

  return (
    <RightBar handleClose={handleClose} isVisible={isVisible}>
      {!!selectedNode && (
        <>
          <div className="flex flex-col gap-3 items-center">
            <AvatarWithFallback
              className="h-12 w-12 border-2 border-primary"
              image={selectedNode.user.image}
              fallback={getUserAvatarFallback(selectedNode.user)}
            />
            <Muted>{getUserDisplayName(selectedNode.user)}</Muted>
          </div>
          <Separator />

          <div className="space-y-4">
            <div className="space-y-2">
              <Label>Email</Label>
              <div className="mt-0">
                {selectedNode.user.emails.map((email) => (
                  <P key={email}>
                    <Muted className="break-all">{email}</Muted>
                  </P>
                ))}
              </div>
            </div>

            <div className="space-y-2">
              <Label>Role</Label>
              <div className="flex gap-2 items-center">
                <Muted className="break-all">
                  {selectedNode?.role
                    ? toProperCase(selectedNode.role.replace('org:', ''))
                    : 'Member'}
                </Muted>
              </div>
            </div>

            <div className="space-y-2">
              <Label>Manager</Label>
              <div className="flex gap-2 items-center">
                {!isAdmin ? (
                  <div className="flex-1">
                    <Tooltip content="Please contact an admin to change manager">
                      <Select
                        options={managerOptions}
                        value={selectedNode.manager?.id ?? ''}
                        onValueChange={handleManagerChange}
                        placeholder="Select manager"
                        className="w-full"
                        disabled={true}
                      />
                    </Tooltip>
                  </div>
                ) : (
                  <Select
                    options={managerOptions}
                    value={selectedNode.manager?.id ?? ''}
                    onValueChange={handleManagerChange}
                    placeholder="Select manager"
                    className="w-full"
                    disabled={isLoadingPatch}
                  />
                )}
                {isLoadingPatch ? (
                  <Loading className="h-4 w-4" />
                ) : (
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={() => handleManagerChange(null)}
                    disabled={!isAdmin || MOCK_SERVER_ENABLED}
                  >
                    <Icon name="Eraser" className="h-4 w-4 text-red-400" />
                  </Button>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </RightBar>
  )
}
