import { H4 } from '@/components/ui/typography'
import { formatValue } from './objective-row'

import { cn } from '@/lib/utils'

import {
  getFlatWeeksFromMonthlyProgress,
  ObjectiveUI,
  ObjectiveValue,
} from '@/types/Objective'
import { SetState } from '@/types/globals'

interface TimelineProps {
  selectedObjective: ObjectiveUI
  selectedValue: ObjectiveValue | null
  setSelectedValue: SetState<ObjectiveValue | null>
}

export const Timeline = ({
  selectedObjective,
  selectedValue,
  setSelectedValue,
}: TimelineProps) => {
  return (
    <div className="w-[160px] pr-2 border-r">
      <H4 className="mb-2 sticky top-0 bg-background">Timeline</H4>
      <div className="space-y-2">
        {getFlatWeeksFromMonthlyProgress(selectedObjective.monthlyProgress)
          .reverse()
          .map((week) => (
            <button
              key={week.week}
              onClick={() => setSelectedValue(week)}
              className={cn(
                'w-full text-left px-3 py-1 rounded-md text-sm transition-colors',
                'hover:bg-accent',
                selectedValue?.week === week.week &&
                  'hover:bg-green-600 bg-green-600 text-white font-medium',
              )}
            >
              <div>Week {week.week}</div>
              <div
                className={cn(
                  'text-xs text-muted-foreground flex items-center gap-2',
                  selectedValue?.week === week.week && 'text-white/50',
                  'group-hover:text-white',
                )}
              >
                <span>
                  Value: {formatValue(week.value, selectedObjective.metricType)}
                </span>
              </div>
            </button>
          ))}
      </div>
    </div>
  )
}
