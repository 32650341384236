import moment from 'moment'

import type { PerformanceCycle } from '@/types/PerformanceCycle'

export interface WeekInterval {
  label: string
  year: number
  week: number
}

export interface MonthInterval {
  label: string
  year: number
  month: number
  weeks: WeekInterval[]
}

const NUMBER_MONTHS_IN_1_YEAR = 12

export function getMonthsFromPerformanceCycle(
  performanceCycle?: PerformanceCycle,
): MonthInterval[] {
  if (!performanceCycle) {
    return []
  }

  const startDate = moment(performanceCycle.startDate)
  const endDate = moment(performanceCycle.endDate)
  const it = startDate.clone()
  const months: MonthInterval[] = []

  while (
    endDate.year() * NUMBER_MONTHS_IN_1_YEAR + endDate.month() >=
    it.year() * NUMBER_MONTHS_IN_1_YEAR + it.month()
  ) {
    const monthStart = it.clone().startOf('month')
    const monthEnd = it.clone().endOf('month')
    const monthEndCapped = moment.min(monthEnd, endDate)
    const weeks: WeekInterval[] = []
    const weekIt = monthStart.clone()

    while (weekIt.isSameOrBefore(monthEndCapped)) {
      // Find the Sunday of the current week
      const weekSunday = weekIt.clone().endOf('week')

      // Only include the week if its Sunday falls within the current month
      if (weekSunday.month() === it.month()) {
        weeks.push({
          label: `W${weekIt.week()}`,
          year: weekIt.year(),
          week: weekIt.week(),
        })
      }

      weekIt.add(1, 'week')
    }

    months.push({
      label: it.format('MMMM'),
      year: it.year(),
      month: it.month(),
      weeks,
    })

    it.add(1, 'month')
  }

  return months
}
