import { useState } from 'react'

import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@/components/ui/collapsible'
import { Card } from '@/components/ui/card'
import { P, H5, Muted } from '@/components/ui/typography'
import { Icon } from '@/components/ui/icon'
import { Badge } from '@/components/ui/badge'

import {
  calculateObjectivesGrade,
  calculateValuesGrade,
} from '@/services/utils/performance-reviews'
import { cn } from '@/lib/utils'

import { PerformanceReview } from '@/types/PerformanceReview'
import { PerformanceCycle } from '@/types/PerformanceCycle'
import { ReviewOverviewItemContent } from './team-review-overview-item'
import { MeReviewSelfAssessment } from './me-review-self-assessment'
import { useFetchSelfAssessment } from '@/services/api/performanceReview.api'

interface MeReviewOverviewItemProps {
  cycle: PerformanceCycle
  review: PerformanceReview | null
}

type ReviewStatus =
  | 'missing_self_assessment'
  | 'pending_manager_review'
  | 'published'

const ReviewBadge = ({ status }: { status: ReviewStatus }) => {
  const OPTIONS: Record<ReviewStatus, { color: string; text: string }> = {
    published: {
      color: 'bg-green-500',
      text: 'Published',
    },
    pending_manager_review: {
      color: 'bg-yellow-500',
      text: 'Pending manager review',
    },
    missing_self_assessment: {
      color: 'bg-red-500',
      text: 'Missing self assessment',
    },
  }
  const option = OPTIONS[status]

  return (
    <Badge className={cn(option.color, `hover:${option.color}`)}>
      {option.text}
    </Badge>
  )
}

export const MeReviewOverviewItem = ({
  cycle,
  review,
}: MeReviewOverviewItemProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const { data: selfAssessment } = useFetchSelfAssessment(cycle?.id)

  const objectivesGrade = review
    ? calculateObjectivesGrade(review.objectives)
    : null
  const valuesGrade = review ? calculateValuesGrade(review.values) : null
  const hasManagerReview = review?.status === 'published'

  // Determine the current status
  const status: ReviewStatus =
    review?.status === 'published'
      ? 'published'
      : selfAssessment
        ? 'pending_manager_review'
        : 'missing_self_assessment'

  return (
    <Card>
      <Collapsible open={isOpen} onOpenChange={setIsOpen}>
        <CollapsibleTrigger>
          <div className="p-4 flex items-center justify-between">
            <div className="flex-grow text-left">
              <div className="flex items-center gap-2">
                <H5 className="text-left">{cycle.label}</H5>
                <ReviewBadge status={status} />
              </div>
              {hasManagerReview && (
                <P className="text-muted-foreground text-left">
                  {`Objectives ${objectivesGrade}/3, Values ${valuesGrade}/3`}
                </P>
              )}
            </div>
            <div className="flex items-center space-x-2 ml-4">
              <Icon
                name="ChevronDown"
                className={cn('h-4 w-4 transition-transform duration-200', {
                  'transform rotate-180': isOpen,
                })}
              />
            </div>
          </div>
        </CollapsibleTrigger>
        <CollapsibleContent className="px-4 pb-4">
          <MeReviewSelfAssessment cycle={cycle} review={review} />
          {review ? (
            <ReviewOverviewItemContent
              review={review}
              performanceCycleId={cycle.id}
            />
          ) : (
            <div className="mt-4 text-center">
              <Muted>
                Your manager&apos;s review will be available once published.
              </Muted>
            </div>
          )}
        </CollapsibleContent>
      </Collapsible>
    </Card>
  )
}
