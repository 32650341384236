import { useMemo } from 'react'
import moment from 'moment'

import { LayoutTile } from '@/components/layout'
import { Muted } from '@/components/ui/typography'
import { Loading } from '@/components/ui/loading'
import { ErrorTile } from '@/components/common/error-tile'
import LineChart from '@/components/common/line-chart'
import { LayoutHeader } from '@/components/common/layout-header'
import { Icon } from '@/components/ui/icon'
import { MeReviewOverviewItem } from '@/components/me-review-overview-item'

import { useFetchMyPerformanceReviews } from '@/services/api/performanceReview.api'
import { useFetchOrganizationPerformanceCycles } from '@/services/api/organization.api'
import {
  calculateObjectivesGrade,
  calculateValuesGrade,
} from '@/services/utils/performance-reviews'
import { PerformanceCycle } from '@/types/PerformanceCycle'
import { PerformanceReview } from '@/types/PerformanceReview'

interface CycleWithReview {
  cycle: PerformanceCycle
  review: PerformanceReview | null
}

export function MyReviewsPage() {
  const {
    data: reviews = [],
    isLoading: isLoadingReviews,
    error: reviewsError,
  } = useFetchMyPerformanceReviews()

  const {
    data: cycles = [],
    isLoading: isLoadingCycles,
    error: cyclesError,
  } = useFetchOrganizationPerformanceCycles()

  const isLoading = isLoadingReviews || isLoadingCycles
  const error = reviewsError || cyclesError

  const chartData = useMemo(() => {
    if (!reviews) return []
    const sortedReviews = [...reviews].sort((a, b) =>
      Number(
        moment(a.performanceCycle.startDate).isAfter(
          moment(b.performanceCycle.startDate),
        ),
      ),
    )
    return sortedReviews.map((review) => ({
      label: review.performanceCycle.label,
      objectives: calculateObjectivesGrade(review.objectives),
      values: calculateValuesGrade(review.values),
    }))
  }, [reviews])

  const cyclesWithReviews = useMemo(() => {
    return cycles
      .map((cycle: PerformanceCycle) => ({
        cycle,
        review: reviews.find((r) => r.performanceCycle.id === cycle.id) || null,
      }))
      .sort((a: CycleWithReview, b: CycleWithReview) =>
        moment(b.cycle.endDate).diff(moment(a.cycle.endDate)),
      )
  }, [cycles, reviews])

  if (error) return <ErrorTile />

  return (
    <LayoutTile>
      <LayoutHeader
        title="My Reviews"
        description={
          <>
            View your performance reviews and track your progress over time.{' '}
            <a
              href="https://youtu.be/yyPvyqrSdpo"
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary hover:underline inline-flex items-center"
            >
              Watch our tutorial
              <Icon name="ExternalLink" className="ml-1 w-4 h-4" />
            </a>
          </>
        }
      />
      {isLoading ? (
        <div className="flex-1 w-full justify-center items-center flex">
          <Loading />
        </div>
      ) : !cycles.length ? (
        <div className="flex-1 w-full justify-center items-center flex">
          <Muted>No Performance Cycles Available</Muted>
        </div>
      ) : (
        <>
          {reviews.length > 0 && (
            <div className="mb-8">
              <LineChart
                data={chartData}
                lines={['objectives', 'values']}
                config={{
                  objectives: { label: 'Objectives' },
                  values: { label: 'Values' },
                }}
                legend
                domain={[1, 3]}
                type="linear"
                margin={{ left: 10, right: 10, top: 20, bottom: 0 }}
              />
            </div>
          )}
          <div className="mt-4 flex flex-col gap-4">
            {cyclesWithReviews.map(({ cycle, review }) => (
              <MeReviewOverviewItem
                key={cycle.id}
                cycle={cycle}
                review={review}
              />
            ))}
          </div>
        </>
      )}
    </LayoutTile>
  )
}
