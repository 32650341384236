import { useState, useMemo } from 'react'
import moment from 'moment'

import { MultiSelect } from '@/components/common/select'
import { Muted, H2 } from '@/components/ui/typography'
import { LayoutTile } from '@/components/layout'
import { DatePicker } from '@/components/common/date-picker'
import { DashboardContent } from '@/components/dashboard-content'

import { useFetchFeedbackStats } from '@/services/api/feedbackPoints.api'
import { useFetchTeamMembers } from '@/services/api/organization.api'
import { useLocalStorage } from '@/hooks/useLocalStorage'
import { useUserRole } from '@/hooks/useUserRole'
import { useFetchUserSentimentStats } from '@/services/api/userSentiment.api'

import { StoredDateState } from '@/types/DateState'
import { DateRange } from 'react-day-picker'

export const ManagementDashboard = () => {
  const { isAdmin } = useUserRole()
  const [selectedMemberIds, setSelectedMemberIds] = useLocalStorage<string[]>(
    'management-dashboard.selected-members',
    ['all'],
  )

  // Calculate default dates
  const defaultDateRange = useMemo(
    () => ({
      from: moment().subtract(3, 'months').toDate(),
      to: new Date(),
    }),
    [],
  )

  const [storedDateState, setStoredDateState] =
    useLocalStorage<StoredDateState>('management-dashboard.date-state', {
      dateRange: defaultDateRange,
      preset: 'last-3-months',
      isAllTime: false,
    })

  // Recalculate relative dates if using a relative preset
  const initialDateRange = useMemo(() => {
    if (storedDateState.preset === 'last-3-months') {
      return defaultDateRange
    }
    return storedDateState.dateRange
  }, [storedDateState, defaultDateRange])

  const [dateRange, setDateRange] = useState<DateRange>(initialDateRange)
  const [selectedPreset, setSelectedPreset] = useState(storedDateState.preset)

  const { data: feedbackStats, isLoading: isLoadingStats } =
    useFetchFeedbackStats(
      selectedMemberIds.includes('all') ? 'all' : selectedMemberIds,
      storedDateState.dateRange,
    )
  const { data: teamMembers, isLoading: isLoadingTeamMembers } =
    useFetchTeamMembers({ teamScope: isAdmin ? 'all' : 'underUser' })

  const { data: userSentimentStats, isLoading: isLoadingUserSentiment } =
    useFetchUserSentimentStats(
      selectedMemberIds.includes('all') ? 'all' : selectedMemberIds,
    )

  return (
    <LayoutTile className="bg-transparent p-0 sm:p-0">
      <div className="flex flex-col sm:flex-row sm:items-center justify-between mb-2">
        <div>
          <H2>Management Dashboard</H2>
          <Muted className="mt-1">
            You can find here the consolidated data for the team under you.
          </Muted>
        </div>
        <div className="management-dashboard-filters flex flex-col sm:flex-row items-stretch sm:items-center gap-4 mt-4 sm:mt-0">
          <div className="flex-shrink-0">
            <DatePicker
              value={dateRange}
              selectedPreset={selectedPreset}
              onChange={(range, preset, isAllTime) => {
                setDateRange(range)
                setSelectedPreset(preset)
                setStoredDateState({
                  dateRange: range,
                  preset: preset,
                  isAllTime: !!isAllTime,
                })
              }}
              includeAllTime
            />
          </div>
          <MultiSelect
            value={selectedMemberIds}
            onValueChange={(value) => {
              if (value.includes('all')) {
                setSelectedMemberIds(['all'])
              } else {
                setSelectedMemberIds(value)
              }
            }}
            options={[
              { value: 'all', label: 'All Team Members' },
              ...(teamMembers?.map((member) => ({
                value: member.id,
                label: `${member.firstName} ${member.lastName}`,
              })) || []),
            ]}
            className="w-full sm:w-[300px] bg-background"
            isLoading={isLoadingTeamMembers || isLoadingStats}
          />
        </div>
      </div>

      <DashboardContent
        feedbackStats={feedbackStats}
        userSentimentStats={userSentimentStats}
        isLoadingStats={isLoadingStats || isLoadingUserSentiment}
        viewType="management"
        dateRange={dateRange}
      />
    </LayoutTile>
  )
}
