import { useState } from 'react'

import { AvatarWithFallback } from '@/components/ui/avatar'
import { Button } from '@/components/ui/button'
import { Check } from 'lucide-react'
import { Icon } from '@/components/ui/icon'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command'

import {
  getUserAvatarFallback,
  getUserDisplayName,
} from '@/services/utils/formatters'
import { useFetchTeamMembers } from '@/services/api/organization.api'
import { cn } from '@/lib/utils'
import { UserProfile } from '@/types/UserProfile'
import { Tooltip } from './common/tooltip'
import { ClassName } from '@/types/globals'

interface TeamMemberSelectorProps {
  value: UserProfile[]
  onChange: (_newValue: UserProfile[]) => void
  classNameTeamUserListAvatar?: ClassName
  classNameTeamUserList?: ClassName
  noPortal?: boolean
}

export const TeamMemberSelector = ({
  value,
  onChange,
  classNameTeamUserListAvatar,
  classNameTeamUserList,
  noPortal,
}: TeamMemberSelectorProps) => {
  const [open, setOpen] = useState(false)
  const { data: teamUsers = [] } = useFetchTeamMembers()

  const handleClickAdd = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()

    setOpen(true)
  }

  const handleDelete = (user: UserProfile) => {
    const newValue = value.some((u) => u.id === user.id)
      ? value.filter((u) => u.id !== user.id)
      : value

    onChange(newValue)
  }

  const handleAdd = (user: UserProfile) => {
    const newValue = value.some((u) => u.id === user.id)
      ? value.filter((u) => u.id !== user.id)
      : [...value, user]

    onChange(newValue)
    setOpen(false)
  }

  const handleKeyDown = (e: React.KeyboardEvent) => {
    e.stopPropagation()
  }

  return (
    <div className="flex gap-2 items-center">
      <Popover open={open} onOpenChange={setOpen}>
        {!!value.length && (
          <TeamMemberList
            users={value}
            handleClick={(e, user) => {
              e.preventDefault()
              handleDelete(user)
            }}
            className={classNameTeamUserList}
            classNameAvatar={classNameTeamUserListAvatar}
          />
        )}
        <PopoverTrigger asChild>
          <Button variant="ghost" size="icon" onClick={handleClickAdd}>
            <Icon name="Plus" className="h-4 w-4 text-green-600" />
          </Button>
        </PopoverTrigger>
        <PopoverContent
          align="start"
          className="w-full p-0 z-50"
          noPortal={noPortal}
        >
          <Command onKeyDown={handleKeyDown}>
            <CommandInput placeholder="Search ..." onKeyDown={handleKeyDown} />
            <CommandEmpty>No item found.</CommandEmpty>
            <CommandGroup>
              <CommandList className="max-h-[200px]">
                {teamUsers.map((user) => (
                  <CommandItem
                    key={crypto.randomUUID()}
                    onSelect={() => handleAdd(user)}
                  >
                    <Check
                      className={cn(
                        'mr-2 h-4 w-4',
                        value.some((u) => u.id === user.id)
                          ? 'opacity-100'
                          : 'opacity-0',
                      )}
                    />
                    {getUserDisplayName(user)}
                  </CommandItem>
                ))}
              </CommandList>
            </CommandGroup>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  )
}

interface TeamMemberListProps {
  users: UserProfile[]
  handleClick?: (
    _e: React.MouseEvent<HTMLButtonElement>,
    _user: UserProfile,
  ) => void
  classNameAvatar?: ClassName
  className?: ClassName
}

export const TeamMemberList = ({
  users,
  handleClick,
  classNameAvatar,
  className,
}: TeamMemberListProps) => {
  return (
    <div className={cn('flex gap-1', className)}>
      {users.map((user) => (
        <Tooltip key={crypto.randomUUID()} content={getUserDisplayName(user)}>
          <button onClick={(e) => handleClick && handleClick(e, user)}>
            <AvatarWithFallback
              image={user.image}
              fallback={getUserAvatarFallback(user)}
              className={cn(
                'border-green-600 border-2 w-8 h-8',
                classNameAvatar,
              )}
            />
          </button>
        </Tooltip>
      ))}
    </div>
  )
}
