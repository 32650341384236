import { useEffect } from 'react'
import { useUser, useOrganization } from '@clerk/clerk-react'
import { generateChatwootHMAC } from '@/lib/chatwoot'
import type { ChatwootSettings, ChatwootUser } from '@/types/Chatwoot'

export function ChatwootWidget() {
  const { user } = useUser()
  const { organization } = useOrganization()

  useEffect(() => {
    // Add Chatwoot Settings
    window.chatwootSettings = {
      hideMessageBubble: true,
      position: 'right',
      locale: 'en',
      type: 'expanded_bubble',
      launcherTitle: 'Chat with us',
      darkMode: 'auto',
      baseDomain: 'fidforward.com',
      showUnreadMessagesDialog: false,
      useBrowserLanguage: true,
    } as ChatwootSettings

    // Initialize Chatwoot SDK
    ;(function (d, t) {
      const BASE_URL = 'https://app.chatwoot.com'
      const g = d.createElement(t) as HTMLScriptElement
      const s = d.getElementsByTagName(t)[0]
      g.src = BASE_URL + '/packs/js/sdk.js'
      g.async = true
      s.parentNode?.insertBefore(g, s)
      g.onload = function () {
        window.chatwootSDK.run({
          websiteToken: 'AhzTa6gpCibgiaFtA2ieMakc',
          baseUrl: BASE_URL,
        })
      }
    })(document, 'script')

    // Listen for Chatwoot ready event
    const handleChatwootReady = async () => {
      if (user) {
        const identifier = user.id
        const identifierHash = await generateChatwootHMAC(identifier)

        const userData: ChatwootUser = {
          name: user.fullName || '',
          email: user.primaryEmailAddress?.emailAddress || '',
          identifier_hash: identifierHash,
          avatar_url: user.imageUrl,
          company_name: organization?.name || '',
        }
        window.$chatwoot.setUser(identifier, userData)
      }
    }

    window.addEventListener('chatwoot:ready', handleChatwootReady)

    return () => {
      window.removeEventListener('chatwoot:ready', handleChatwootReady)
    }
  }, [user, organization])

  return null
}
