import { Outlet } from 'react-router-dom'
import React from 'react'
import Joyride from 'react-joyride'
import { useOrganization } from '@clerk/clerk-react'

import { MenuDesktop, MenuMobile } from '@/components/menu'
import { NoOrganization } from '@/components/common/no-organization'

import { MOCK_SERVER_ENABLED } from '@/services/config/env'
import { cn } from '@/lib/utils'
import { useTour } from '@/lib/tour'

import { ClassName } from '@/types/globals'

export const Layout = () => {
  const { organization } = useOrganization()
  const { steps, isOpen, currentStep, handleCallback } = useTour()

  return (
    <>
      <Joyride
        steps={steps}
        run={isOpen}
        continuous
        showSkipButton
        showProgress
        hideCloseButton
        stepIndex={currentStep}
        callback={handleCallback}
        styles={{
          options: {
            primaryColor: '#17A34A',
          },
        }}
        disableOverlayClose
      />
      <div className="flex-1 w-full flex h-screen">
        <MenuDesktop />
        <div className="flex-1 flex flex-col min-w-0">
          <header className="fixed top-0 w-full flex h-13 items-center gap-4 bg-white border-b bg-muted/40 p-4 md:hidden">
            <MenuMobile />
          </header>
          <div className="flex flex-col !overflow-y-auto mt-[69px] max-h-[calc(100vh-69px)] md:max-h-none md:mt-0 flex-1">
            <main className="flex flex-1">
              {MOCK_SERVER_ENABLED || organization ? (
                <Outlet />
              ) : (
                <NoOrganization />
              )}
            </main>
          </div>
        </div>
      </div>
    </>
  )
}

interface LayoutTileProps {
  children: React.ReactNode | React.ReactNode[]
  className?: ClassName
}

export const LayoutTile = ({ className, children }: LayoutTileProps) => (
  <div
    className={cn(
      'flex-1 p-4 sm:p-6 m-6 sm:m-8 flex flex-col gap-4 bg-white',
      className,
    )}
  >
    {children}
  </div>
)
