import { ReactNode } from 'react'
import {
  Sheet as UISheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from '@/components/ui/sheet'

interface SheetProps {
  isOpen: boolean
  setIsOpen: (_open: boolean) => void
  title: string
  children: ReactNode
  width?: string
  headerActions?: ReactNode
}

export function Sheet({
  isOpen,
  setIsOpen,
  title,
  children,
  width = 'w-[90vw] sm:max-w-[600px]',
  headerActions,
}: SheetProps) {
  return (
    <UISheet open={isOpen} onOpenChange={setIsOpen}>
      <SheetContent className={width}>
        <SheetHeader className="flex flex-row items-center justify-between">
          <SheetTitle>{title}</SheetTitle>
          {headerActions}
        </SheetHeader>
        {children}
      </SheetContent>
    </UISheet>
  )
}
