import { z } from 'zod'
import { useAuth, useOrganization } from '@clerk/clerk-react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import {
  TeamObjectivePatchSchema,
  TeamObjectiveCreateSchema,
  TeamObjectivePatchValueSchema,
  TeamObjectiveTargetSchemaType,
} from '@/lib/schema/objective-form.schema'
import { useToast } from '@/hooks/use-toast'
import { createServerErrorToast } from '@/lib/toast'

import {
  fetchResolve,
  postResolve,
  patchResolve,
  deleteResolve,
  addQueryParams,
} from './helpers'

import { Objective } from '@/types/Objective'

export const useFetchTeamObjectives = (performanceCycleId?: string) => {
  const { getToken } = useAuth()
  const { organization } = useOrganization()

  return useQuery<Objective[]>({
    queryKey: ['useFetchTeamObjectives', performanceCycleId, organization?.id],
    queryFn: async () =>
      await fetchResolve(
        `/organization/${organization?.id}/performance-cycle/${performanceCycleId}/objective`,
        getToken,
      ),
  })
}

export const useFetchTeamObjectivesByUserId = (
  userId: string,
  performanceCycleId?: string,
) => {
  const { getToken } = useAuth()
  const { organization } = useOrganization()

  return useQuery<Objective[]>({
    queryKey: [
      'useFetchTeamObjectivesByUserId',
      userId,
      performanceCycleId,
      organization?.id,
    ],
    queryFn: () =>
      fetchResolve(
        addQueryParams(
          `/organization/${organization?.id}/performance-cycle/${performanceCycleId}/objective`,
          { userId },
        ),
        getToken,
      ),
  })
}

export const useCreateObjective = (performanceCycleId?: string) => {
  const queryClient = useQueryClient()
  const { getToken } = useAuth()
  const { organization } = useOrganization()
  const { toast } = useToast()

  return useMutation({
    mutationFn: async (body: z.infer<typeof TeamObjectiveCreateSchema>) =>
      await postResolve(
        `/organization/${organization?.id}/objective`,
        body,
        getToken,
      ),
    onSuccess: (data: Objective) => {
      queryClient.setQueryData(
        ['useFetchTeamObjectives', performanceCycleId, organization?.id],
        (old: Objective[]) => [...old, data],
      )
    },
    onError: (error) => {
      toast(createServerErrorToast(error.message))
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [
          'useFetchTeamObjectives',
          performanceCycleId,
          organization?.id,
        ],
      })
      queryClient.invalidateQueries({
        queryKey: ['useFetchTeamObjectivesByUserId'],
      })
    },
  })
}

export const usePatchObjective = (performanceCycleId?: string) => {
  const queryClient = useQueryClient()
  const { getToken } = useAuth()
  const { organization } = useOrganization()
  const { toast } = useToast()

  return useMutation({
    mutationFn: async (props: {
      id: string
      body: z.infer<typeof TeamObjectivePatchSchema>
    }) =>
      await patchResolve(
        `/organization/${organization?.id}/objective/${props.id}`,
        props.body,
        getToken,
      ),
    onMutate: async (variables) => {
      await queryClient.cancelQueries({
        queryKey: ['useFetchTeamObjectives', performanceCycleId],
      })
      const previousQuery = queryClient.getQueryData([
        'useFetchTeamObjectives',
        performanceCycleId,
        organization?.id,
      ])

      // Optimistically update to the new value
      queryClient.setQueryData(
        ['useFetchTeamObjectives', performanceCycleId, organization?.id],
        (old: Objective[]) =>
          old?.map((objective) =>
            objective.id === variables.id
              ? {
                  ...objective,
                  ...variables.body,
                }
              : objective,
          ) ?? [],
      )

      // Return a context object with the snapshotted value
      return { previousQuery }
    },
    onError: (error, _variables, context) => {
      queryClient.setQueryData(
        ['useFetchTeamObjectives', organization?.id],
        context?.previousQuery ?? [],
      )
      toast(createServerErrorToast(error.message))
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ['useFetchTeamObjectives', performanceCycleId],
      })
      queryClient.invalidateQueries({
        queryKey: ['useFetchTeamObjectivesByUserId'],
      })
    },
  })
}

export const useDeleteObjective = (performanceCycleId?: string) => {
  const queryClient = useQueryClient()
  const { getToken } = useAuth()
  const { organization } = useOrganization()
  const { toast } = useToast()

  return useMutation({
    mutationFn: async (id: string) =>
      await deleteResolve(
        `/organization/${organization?.id}/objective/${id}`,
        getToken,
      ),
    onMutate: async (id) => {
      await queryClient.cancelQueries({
        queryKey: ['useFetchTeamObjectives', performanceCycleId],
      })
      const previousQuery = queryClient.getQueryData([
        'useFetchTeamObjectives',
        performanceCycleId,
        organization?.id,
      ])

      // Optimistically update to the new value
      queryClient.setQueryData(
        ['useFetchTeamObjectives', performanceCycleId, organization?.id],
        (oldData: Objective[]) =>
          oldData.filter((objective) => objective.id !== id),
      )

      // Return a context object with the snapshotted value
      return { previousQuery }
    },
    onError: (error, _variables, context) => {
      queryClient.setQueryData(
        ['useFetchTeamObjectives', organization?.id],
        context?.previousQuery ?? [],
      )
      toast(createServerErrorToast(error.message))
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ['useFetchTeamObjectives', performanceCycleId],
      })
      queryClient.invalidateQueries({
        queryKey: ['useFetchTeamObjectivesByUserId'],
      })
    },
  })
}

export const usePatchObjectiveValue = (performanceCycleId?: string) => {
  const queryClient = useQueryClient()
  const { getToken } = useAuth()
  const { organization } = useOrganization()
  const { toast } = useToast()

  return useMutation({
    mutationFn: async (props: {
      teamObjectiveId: string
      body: z.infer<typeof TeamObjectivePatchValueSchema>
    }) =>
      await patchResolve(
        `/organization/${organization?.id}/objective/${props.teamObjectiveId}/value`,
        props.body,
        getToken,
      ),
    onMutate: async (variables) => {
      await queryClient.cancelQueries({
        queryKey: [
          'useFetchTeamObjectives',
          performanceCycleId,
          organization?.id,
        ],
      })
      const previousQuery = queryClient.getQueryData([
        'useFetchTeamObjectives',
        performanceCycleId,
        organization?.id,
      ])

      // Optimistically update to the new value
      queryClient.setQueryData(
        ['useFetchTeamObjectives', performanceCycleId, organization?.id],
        (old: Objective[]) =>
          old?.map((objective) =>
            objective.id === variables.teamObjectiveId
              ? {
                  ...objective,
                  values: objective.values.map((v) =>
                    v.year === variables.body.year &&
                    v.week === variables.body.week
                      ? { ...v, ...variables.body }
                      : v,
                  ),
                }
              : objective,
          ) ?? [],
      )

      // Return a context object with the snapshotted value
      return { previousQuery }
    },
    onError: (error, _variables, context) => {
      queryClient.setQueryData(
        ['useFetchTeamObjectives', performanceCycleId, organization?.id],
        context?.previousQuery ?? [],
      )
      toast(createServerErrorToast(error.message))
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [
          'useFetchTeamObjectives',
          performanceCycleId,
          organization?.id,
        ],
      })
    },
  })
}

export const usePatchObjectiveTarget = (performanceCycleId?: string) => {
  const queryClient = useQueryClient()
  const { getToken } = useAuth()
  const { organization } = useOrganization()
  const { toast } = useToast()

  return useMutation({
    mutationFn: async (props: {
      teamObjectiveId: string
      body: TeamObjectiveTargetSchemaType
    }) =>
      await patchResolve(
        `/organization/${organization?.id}/objective/${props.teamObjectiveId}/target`,
        props.body,
        getToken,
      ),
    onMutate: async (variables) => {
      await queryClient.cancelQueries({
        queryKey: [
          'useFetchTeamObjectives',
          performanceCycleId,
          organization?.id,
        ],
      })
      const previousQuery = queryClient.getQueryData([
        'useFetchTeamObjectives',
        performanceCycleId,
        organization?.id,
      ])

      // Optimistically update to the new value
      queryClient.setQueryData(
        ['useFetchTeamObjectives', performanceCycleId, organization?.id],
        (old: Objective[]) =>
          old?.map((objective) =>
            objective.id === variables.teamObjectiveId
              ? {
                  ...objective,
                  values: objective.values.map((v) =>
                    v.year === variables.body.year &&
                    v.week === variables.body.month
                      ? { ...v, ...variables.body }
                      : v,
                  ),
                }
              : objective,
          ) ?? [],
      )

      // Return a context object with the snapshotted value
      return { previousQuery }
    },
    onError: (error, _variables, context) => {
      queryClient.setQueryData(
        ['useFetchTeamObjectives', performanceCycleId, organization?.id],
        context?.previousQuery ?? [],
      )
      toast(createServerErrorToast(error.message))
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [
          'useFetchTeamObjectives',
          performanceCycleId,
          organization?.id,
        ],
      })
    },
  })
}
