import { z } from 'zod'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { useAuth, useOrganization } from '@clerk/clerk-react'
import { useToast } from '@/hooks/use-toast'
import { createServerErrorToast } from '@/lib/toast'

import {
  addQueryParams,
  fetchResolve,
  patchResolve,
  postResolve,
} from './helpers'
import {
  PerformanceReviewCreateSchema,
  PerformanceReviewPatchSchema,
  SelfAssessmentSchema,
} from '@/lib/schema/performance-review.schema'

import {
  PerformanceReview,
  TeamMemberPerformanceReview,
  SelfAssessment,
} from '@/types/PerformanceReview'

interface AnalysisResult {
  score: number
  feedback: string
}

export const useFetchTeamMemberPerformanceReviews = (
  performanceCycleId: string | undefined,
  employeeScope: 'directReports' | 'allUnderMe' | 'all',
) => {
  const { organization } = useOrganization()
  const { getToken } = useAuth()

  return useQuery<TeamMemberPerformanceReview[]>({
    queryKey: [
      'useFetchTeamMemberPerformanceReviews',
      performanceCycleId,
      organization?.id,
      employeeScope,
    ],
    queryFn: () =>
      fetchResolve(
        addQueryParams(
          `/organization/${organization?.id}/performance-cycle/${performanceCycleId}/performance-review`,
          {
            scope: employeeScope,
          },
        ),
        getToken,
      ),
    enabled: !!performanceCycleId,
  })
}

export const useFetchMyPerformanceReviews = () => {
  const { organization } = useOrganization()
  const { getToken } = useAuth()

  return useQuery<PerformanceReview[]>({
    queryKey: ['useFetchMyPerformanceReviews', organization?.id],
    queryFn: () =>
      fetchResolve(
        `/organization/${organization?.id}/performance-review`,
        getToken,
      ),
  })
}

export const useUpdateTeamMemberPerformanceReview = (
  performanceCycleId: string,
) => {
  const queryClient = useQueryClient()
  const { getToken } = useAuth()
  const { organization } = useOrganization()
  const { toast } = useToast()

  return useMutation({
    mutationFn: async (props: {
      id: string
      body: z.infer<typeof PerformanceReviewPatchSchema>
    }) =>
      await patchResolve(
        `/organization/${organization?.id}/performance-review/${props.id}`,
        props.body,
        getToken,
      ),
    onMutate: async (variables) => {
      const previousQueries: Record<string, unknown> = {}

      for (const scope of ['directReports', 'allUnderMe', 'all']) {
        await queryClient.cancelQueries({
          queryKey: [
            'useFetchTeamMemberPerformanceReviews',
            performanceCycleId,
            organization?.id,
            scope,
          ],
        })

        const previousQuery = await queryClient.getQueryData([
          'useFetchTeamMemberPerformanceReviews',
          performanceCycleId,
          organization?.id,
          scope,
        ])

        // Optimistically update to the new value
        queryClient.setQueryData<TeamMemberPerformanceReview[]>(
          [
            'useFetchTeamMemberPerformanceReviews',
            performanceCycleId,
            organization?.id,
            scope,
          ],
          (old) =>
            (old ?? []).map((teamReview) =>
              teamReview.review?.id === variables.id
                ? {
                    ...teamReview,
                    review: { ...teamReview.review, ...variables.body },
                  }
                : teamReview,
            ),
        )

        previousQueries[scope] = previousQuery
      }

      return { previousQueries }
    },
    onError: (error, _variables, context) => {
      const previousQueries = context?.previousQueries ?? {}
      for (const scope of Object.keys(previousQueries)) {
        queryClient.setQueryData(
          [
            'useFetchTeamMemberPerformanceReviews',
            performanceCycleId,
            organization?.id,
            scope,
          ],
          previousQueries[scope] ?? [],
        )
      }
      toast(createServerErrorToast(error.message))
    },
  })
}

export const useCreateTeamMemberPerformanceReview = (
  performanceCycleId: string,
) => {
  const queryClient = useQueryClient()
  const { getToken } = useAuth()
  const { organization } = useOrganization()
  const { toast } = useToast()

  return useMutation({
    mutationFn: async (body: z.infer<typeof PerformanceReviewCreateSchema>) =>
      await postResolve(
        `/organization/${organization?.id}/performance-review`,
        body,
        getToken,
      ),
    onSuccess: (updatedReview: PerformanceReview) => {
      for (const scope of ['directReports', 'allUnderMe', 'all']) {
        queryClient.setQueryData<TeamMemberPerformanceReview[]>(
          [
            'useFetchTeamMemberPerformanceReviews',
            performanceCycleId,
            organization?.id,
            scope,
          ],
          (oldData) =>
            (oldData ?? []).map((teamReview) =>
              teamReview.user.id === updatedReview.user.id
                ? { ...teamReview, review: updatedReview }
                : teamReview,
            ),
        )
      }
    },
    onError: (error) => {
      toast(createServerErrorToast(error.message))
    },
  })
}

export const useFetchSelfAssessment = (
  performanceCycleId?: string,
  userId?: string,
) => {
  const { organization } = useOrganization()
  const { getToken } = useAuth()

  return useQuery<SelfAssessment | null>({
    queryKey: [
      'useFetchSelfAssessment',
      performanceCycleId,
      userId,
      organization?.id,
    ],
    queryFn: async () => {
      if (!performanceCycleId) return null
      const url = `/organization/${organization?.id}/performance-cycle/${performanceCycleId}/self-assessment`
      const params = userId ? { userId } : undefined
      return await fetchResolve<SelfAssessment>(
        addQueryParams(url, params),
        getToken,
      )
    },
    enabled: !!performanceCycleId,
  })
}

export const useUpdateSelfAssessment = () => {
  const queryClient = useQueryClient()
  const { getToken } = useAuth()
  const { organization } = useOrganization()
  const { toast } = useToast()

  return useMutation({
    mutationFn: async (body: z.infer<typeof SelfAssessmentSchema>) => {
      const requestBody = {
        performanceCycleId: body.performanceCycleId,
        objectives: body.objectives.trim(),
        values: body.values.trim(),
      }
      return await patchResolve(
        `/organization/${organization?.id}/performance-cycle/${body.performanceCycleId}/self-assessment`,
        requestBody,
        getToken,
      )
    },
    onError: (error) => {
      toast(createServerErrorToast(error.message))
    },
    onSuccess: (_data, variables) => {
      queryClient.invalidateQueries({
        queryKey: [
          'useFetchSelfAssessment',
          variables.performanceCycleId,
          undefined,
          organization?.id,
        ],
      })
    },
  })
}

export const usePatchPerformanceReviewComment = () => {
  const { getToken } = useAuth()
  const { organization } = useOrganization()
  const { toast } = useToast()

  return useMutation({
    mutationFn: async (props: {
      performanceReviewId: string
      body: { text: string }
    }) =>
      await patchResolve(
        `/organization/${organization?.id}/performance-review/${props.performanceReviewId}/comment`,
        props.body,
        getToken,
      ),
    onError: (error) => {
      toast(createServerErrorToast(error.message))
    },
  })
}

export const useAnalyzeObjectives = () => {
  const { getToken } = useAuth()
  const { organization } = useOrganization()
  const { toast } = useToast()

  return useMutation({
    mutationFn: async ({
      text,
      label,
    }: {
      text: string
      label: string
    }): Promise<AnalysisResult> =>
      await postResolve(
        `/organization/${organization?.id}/performance-review/analyze/objectives`,
        { text, label },
        getToken,
      ),
    onError: (error) => {
      toast(createServerErrorToast(error.message))
    },
  })
}

export const useAnalyzeValues = () => {
  const { getToken } = useAuth()
  const { organization } = useOrganization()
  const { toast } = useToast()

  return useMutation({
    mutationFn: async ({
      text,
      label,
    }: {
      text: string
      label: string
    }): Promise<AnalysisResult> =>
      await postResolve(
        `/organization/${organization?.id}/performance-review/analyze/values`,
        { text, label },
        getToken,
      ),
    onError: (error) => {
      toast(createServerErrorToast(error.message))
    },
  })
}

export const useAnalyzeManagerReview = () => {
  const { getToken } = useAuth()
  const { organization } = useOrganization()
  const { toast } = useToast()

  return useMutation({
    mutationFn: async (text: string): Promise<AnalysisResult> =>
      await postResolve(
        `/organization/${organization?.id}/performance-review/analyze/manager-review`,
        { text },
        getToken,
      ),
    onError: (error) => {
      toast(createServerErrorToast(error.message))
    },
  })
}

export const useAnalyzeActionPlan = () => {
  const { getToken } = useAuth()
  const { organization } = useOrganization()
  const { toast } = useToast()

  return useMutation({
    mutationFn: async (text: string): Promise<AnalysisResult> =>
      await postResolve(
        `/organization/${organization?.id}/performance-review/analyze/action-plan`,
        { text },
        getToken,
      ),
    onError: (error) => {
      toast(createServerErrorToast(error.message))
    },
  })
}
