import { useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useFieldArray, useForm } from 'react-hook-form'

import { Dialog } from '@/components/common/dialog'
import { LayoutTile } from '@/components/layout'
import { Loading } from '@/components/ui/loading'
import { ErrorTile } from '@/components/common/error-tile'
import { ValueCard } from '@/components/common/value-card'
import { LayoutHeader } from '@/components/common/layout-header'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Textarea } from '@/components/ui/textarea'
import { Icon } from '@/components/ui/icon'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'

import { useFetchCompanyValues } from '@/services/api/values.api'
import { useUserRole } from '@/hooks/useUserRole'
import { useUpdateCompanyValues } from '@/services/api/values.api'
import {
  UpdateCompanyValuesSchema,
  UpdateCompanyValuesSchemaType,
} from '@/lib/schema/company-values.schema'

export const CompanyValuesPage = () => {
  const { isAdmin } = useUserRole()
  const { data: values = [], isLoading, error } = useFetchCompanyValues()
  const { mutate: updateValues, isPending: isSaving } = useUpdateCompanyValues()
  const [isEditMode, setIsEditMode] = useState(false)

  function onSubmit(data: UpdateCompanyValuesSchemaType) {
    updateValues(data, {
      onSuccess: () => setIsEditMode(false),
    })
  }

  const handleEnterEditMode = () => {
    setIsEditMode(true)
  }

  if (error) return <ErrorTile />

  if (isLoading) {
    return (
      <LayoutTile>
        <div className="flex-1 flex items-center justify-center">
          <Loading />
        </div>
      </LayoutTile>
    )
  }

  return (
    <LayoutTile className="overflow-y-auto">
      <div className="flex flex-col">
        <div className="flex items-center justify-between">
          <div className="space-y-1">
            <LayoutHeader
              title="Our Company Values"
              description={
                <>
                  These core principles guide our decisions and define who we
                  are as a company. <br />
                  Not sure what your values are? Use our free{' '}
                  <a
                    href="https://fidforward.com/company-values-generator/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-primary hover:underline"
                  >
                    Company Values Generator
                  </a>
                  .
                </>
              }
            />
          </div>
          {isAdmin && !isEditMode && (
            <Button onClick={handleEnterEditMode}>Edit Values</Button>
          )}
        </div>
        {isEditMode ? (
          <div className="space-y-4">
            <CompanyValuesForm
              defaultValues={{
                values: values.map((value) => ({
                  label: value.label,
                  description: value.description,
                  is: value.is,
                  isNot: value.isNot,
                })),
              }}
              onSubmit={onSubmit}
              onCancel={() => setIsEditMode(false)}
              isPending={isSaving}
            />
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {values.map((value) => (
              <ValueCard
                key={value.id}
                label={value.label}
                description={value.description || 'Description coming soon...'}
                is={value.is}
                isNot={value.isNot}
              />
            ))}
          </div>
        )}
      </div>
    </LayoutTile>
  )
}

export const CompanyValuesForm = ({
  defaultValues,
  onSubmit,
  onCancel,
  isPending,
}: {
  defaultValues?: UpdateCompanyValuesSchemaType
  onSubmit: (_values: UpdateCompanyValuesSchemaType) => void
  onCancel: () => void
  isPending?: boolean
}) => {
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false)
  const form = useForm<UpdateCompanyValuesSchemaType>({
    resolver: zodResolver(UpdateCompanyValuesSchema),
    defaultValues: {
      values: defaultValues?.values?.length
        ? defaultValues.values
        : [{ label: '', description: '', is: '', isNot: '' }],
    },
    mode: 'onChange',
  })
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'values',
  })
  const { isDirty, isValid } = form.formState

  const handleConfirm = () => {
    form.handleSubmit(onSubmit)()
    setIsConfirmDialogOpen(false)
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-4"
      >
        {fields.map((field, index) => (
          <div
            key={field.id}
            className="border p-4 rounded-lg relative flex flex-col gap-2"
          >
            {fields.length > 1 && (
              <Button
                type="button"
                variant="ghost"
                size="icon"
                className="absolute top-2 right-2"
                onClick={() => remove(index)}
              >
                <Icon name="Trash2" className="text-red-600" />
              </Button>
            )}
            <FormField
              control={form.control}
              name={`values.${index}.label`}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Name</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name={`values.${index}.description`}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Description</FormLabel>
                  <FormControl>
                    <Textarea {...field} maxLength={100} rows={2} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name={`values.${index}.is`}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>What this value is</FormLabel>
                  <FormControl>
                    <Textarea {...field} maxLength={100} rows={2} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name={`values.${index}.isNot`}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>What this value is not</FormLabel>
                  <FormControl>
                    <Textarea {...field} maxLength={100} rows={2} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        ))}
        <div className="flex justify-between items-center">
          <Button
            type="button"
            variant="secondary"
            onClick={() =>
              append({
                label: '',
                description: '',
                is: '',
                isNot: '',
              })
            }
          >
            Add Another Value
          </Button>
          <div className="flex gap-4">
            <Dialog
              trigger={
                <Button
                  variant="default"
                  disabled={!!isPending || !isDirty || !isValid}
                >
                  Save
                </Button>
              }
              title="Confirm Values update"
              description="Updating organization values will trigger a recalculation of the sentiment analysis for all feedback in the current cycle. This process cannot be undone. Are you sure you want to proceed?"
              confirmText="Confirm"
              onConfirm={handleConfirm}
              isOpen={isConfirmDialogOpen}
              setIsOpen={setIsConfirmDialogOpen}
            />
            <Button type="button" variant="outline" onClick={onCancel}>
              Cancel
            </Button>
          </div>
        </div>
      </form>
    </Form>
  )
}
