import { useAuth, useOrganization } from '@clerk/clerk-react'
import { useQuery } from '@tanstack/react-query'
import { addQueryParams, fetchResolve } from './helpers'
import { UserSentimentStats } from '@/types/UserSentimentStats'

export const useFetchUserSentimentStats = (
  teamMemberIds?: string[] | 'all',
) => {
  const { getToken } = useAuth()
  const { organization } = useOrganization()

  return useQuery<UserSentimentStats[]>({
    queryKey: ['useFetchUserSentimentStats', teamMemberIds, organization?.id],
    queryFn: () =>
      fetchResolve(
        addQueryParams(
          `/organization/${organization?.id}/user-sentiment/stats`,
          {
            teamMemberIds:
              teamMemberIds === 'all' ? 'all' : teamMemberIds?.join(','),
          },
        ),
        getToken,
      ),
  })
}
