import { useOrganization, useUser } from '@clerk/clerk-react'
import { Info } from 'lucide-react'
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@/components/ui/collapsible'
import { ChevronDown } from 'lucide-react'
import { Alert, AlertDescription } from '@/components/ui/alert'

import PurchaseLicensesCard from '@/components/licensing/purchase-licenses-card'
import ManageLicensesCard from '@/components/licensing/manage-license-cards'
import { MembershipRow } from '@/components/licensing/user-row'
import { Loading } from '@/components/ui/loading'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import { Switch } from '@/components/ui/switch'
import { Separator } from '@/components/ui/separator'

import { useFetchLicenseInfo } from '@/services/api/billing.api'
import { useUserRole } from '@/hooks/useUserRole'
import {
  useFetchOrganizationMemberships,
  usePatchOrganizationSettings,
  useFetchOrganizationSettings,
} from '@/services/api/organization.api'
import { H5, H6, Muted, P } from '@/components/ui/typography'
import { LayoutTile } from '@/components/layout'
import { LayoutHeader } from '@/components/common/layout-header'

export default function LicensingPage() {
  const { organization } = useOrganization()
  const { user } = useUser()
  const { isAdmin } = useUserRole()
  const { data: memberships = [] } = useFetchOrganizationMemberships()
  const { data: licenseInfo } = useFetchLicenseInfo()
  const { mutate: patchOrganizationSettings } = usePatchOrganizationSettings()
  const { data: organizationSettings } = useFetchOrganizationSettings()

  if (!organization || !user || !organizationSettings) {
    return (
      <LayoutTile>
        <div className="flex-1 w-full justify-center items-center flex">
          <Loading />
        </div>
      </LayoutTile>
    )
  }

  if (!isAdmin) {
    return (
      <LayoutTile>
        <div className="flex-1 w-full justify-center items-center flex">
          <Muted>You don&apos;t have permission to access this page.</Muted>
        </div>
      </LayoutTile>
    )
  }

  return (
    <LayoutTile>
      <LayoutHeader
        title="License Management"
        description="Manage your organization's licenses and users"
      />
      <Collapsible className="rounded-lg">
        <Alert>
          <CollapsibleTrigger className="flex items-center justify-between w-full">
            <div className="flex items-center">
              <Info className="h-4 w-4 mr-2" />
              <H6 className="font-medium">Setup Guide</H6>
            </div>
            <ChevronDown className="h-4 w-4 transition-transform duration-200" />
          </CollapsibleTrigger>
          <CollapsibleContent>
            <AlertDescription className="pt-4">
              <ol className="space-y-2 text-sm list-decimal list-inside text-muted-foreground">
                <li>
                  Purchase the required number of licenses for your organization
                </li>
                <li>
                  Invite team members by clicking your organization name at the
                  bottom of the left sidebar, selecting &quot;Manage&quot;, and
                  using the &quot;Invite&quot; button under the Members tab
                </li>
                <li>
                  Assign licenses to users using the toggles in the table below
                </li>
              </ol>
            </AlertDescription>
          </CollapsibleContent>
        </Alert>
      </Collapsible>

      {licenseInfo?.totalLicenses === 0 ? (
        <PurchaseLicensesCard />
      ) : (
        <>
          <Card>
            <CardHeader>
              <CardTitle>License Overview</CardTitle>
            </CardHeader>
            <CardContent className="space-y-4">
              <div className="flex items-center justify-between">
                <ManageLicensesCard
                  licensedUsersCount={licenseInfo?.usedLicenses || 0}
                  purchasedLicensesCount={licenseInfo?.totalLicenses || 0}
                />
              </div>
              <Separator />
              <div className="flex items-center justify-between">
                <div>
                  <H6 className="font-medium">Automatic License Management</H6>
                  <P className="text-sm text-muted-foreground">
                    We rely on your messaging app to count users and attribute
                    licenses (recommended).
                  </P>
                </div>
                <Switch
                  checked={organizationSettings?.autoLicenseEnabled}
                  onCheckedChange={() =>
                    patchOrganizationSettings({
                      autoLicenseEnabled:
                        !organizationSettings?.autoLicenseEnabled,
                    })
                  }
                />
              </div>
            </CardContent>
          </Card>

          <div>
            <H5 className="font-medium mt-2 ml-2">Manage User Licenses</H5>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Name</TableHead>
                  <TableHead>Email</TableHead>
                  <TableHead className="text-right">Licensed</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {memberships.map((m) => (
                  <MembershipRow
                    key={m.id}
                    membership={m}
                    disabled={
                      !m.isLicensed &&
                      !organizationSettings.autoLicenseEnabled &&
                      (licenseInfo?.usedLicenses || 0) >=
                        (licenseInfo?.totalLicenses || 0)
                    }
                  />
                ))}
              </TableBody>
            </Table>
          </div>
        </>
      )}
    </LayoutTile>
  )
}
