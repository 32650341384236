import { useState, useEffect } from 'react'
import { useOrganization, useUser } from '@clerk/clerk-react'

import { Label } from '@/components/ui/label'
import { Button } from '@/components/ui/button'
import { LayoutTile } from '@/components/layout'
import { P } from '@/components/ui/typography'
import { TooltipWithIcon } from '@/components/common/tooltip'
import { Card } from '@/components/ui/card'
import { LayoutHeader } from '@/components/common/layout-header'

import { MOCK_SERVER_ENABLED } from '@/services/config/env'
import { useUserRole } from '@/hooks/useUserRole'
import { useFetchIntegrations } from '@/services/api/organization.api'
import { useGoogleOAuth } from '@/hooks/useGoogleOAuth'

import SlackLogo from '@/assets/slack.svg'
import GoogleLogo from '@/assets/google.svg'
import { SLACK_OAUTH_URL } from '@/services/config/env'

import {
  useFetchOrganizationMembershipByUserId,
  usePatchOrganizationMembership,
  type SupportedLanguage,
} from '@/services/api/organization.api'

import { Select } from '@/components/common/select'
import { PerformanceCycleEditor } from '@/components/performance-cycle-editor'

interface Integration {
  id: string
  name: string
  logo: string
  description: string
  isConnected: boolean
  isDisabled?: boolean
  disabledReason?: string
  onConnect: () => void
}

const LANGUAGE_OPTIONS = [
  { value: 'auto', label: 'Auto-detect' },
  { value: 'en-us', label: 'English' },
  { value: 'es-es', label: 'Spanish' },
  { value: 'fr-fr', label: 'French' },
  { value: 'de-de', label: 'German' },
  { value: 'it-it', label: 'Italian' },
  { value: 'pt-pt', label: 'Portuguese' },
] satisfies { value: string; label: string }[]

export function SettingsPage() {
  const { organization } = useOrganization()
  const { user } = useUser()
  const { isAdmin } = useUserRole()
  const { data: integrations = [], isLoading: isLoadingIntegrations } =
    useFetchIntegrations()
  const [slackConnected, setSlackConnected] = useState(false)
  const [googleConnected, setGoogleConnected] = useState(false)
  const { connectGoogle } = useGoogleOAuth({
    redirectUrl: `${window.location.origin}/settings`,
  })

  const { data: membership } = useFetchOrganizationMembershipByUserId(
    user?.id ?? '',
  )
  const { mutate: updateMembership } = usePatchOrganizationMembership()

  useEffect(() => {
    if (!isLoadingIntegrations) {
      setSlackConnected(
        Array.isArray(integrations) &&
          integrations.some(
            (integration) =>
              integration.type === 'SLACK' && integration.enabled,
          ),
      )
    }
  }, [integrations, isLoadingIntegrations])

  useEffect(() => {
    if (user) {
      const googleAccount = user.externalAccounts?.find(
        (account) =>
          account.provider === 'google' &&
          account.verification?.status === 'verified',
      )
      setGoogleConnected(!!googleAccount)
    }
  }, [user])

  const userLanguage = membership?.preferredLanguage

  const handleUserLanguageChange = (value: string) => {
    if (!membership) return

    updateMembership({
      membershipId: membership.id,
      body: {
        preferredLanguage:
          value === 'auto' ? null : (value as SupportedLanguage),
      },
    })
  }

  const organizationIntegrations: Integration[] = [
    {
      id: 'slack',
      name: 'Slack',
      logo: SlackLogo,
      description: 'Enables the Fiddy Slack bot to collect feedback.',
      isConnected: slackConnected,
      isDisabled: MOCK_SERVER_ENABLED,
      disabledReason: 'Slack integration is disabled in demo mode',
      onConnect: () => {
        if (MOCK_SERVER_ENABLED || slackConnected) return
        window.location.href = SLACK_OAUTH_URL(organization?.id || '')
      },
    },
  ]

  const userIntegrations: Integration[] = [
    {
      id: 'google',
      name: 'Google',
      logo: GoogleLogo,
      description:
        'Connect your Google account to enable calendar sync and meeting scheduling.',
      isConnected: googleConnected,
      isDisabled: MOCK_SERVER_ENABLED,
      disabledReason: 'Google integration is disabled in demo mode',
      onConnect: async () => {
        if (MOCK_SERVER_ENABLED || googleConnected) return
        await connectGoogle()
      },
    },
  ]

  const normalizeLanguageValue = (value: string | null | undefined) => {
    return value || 'auto'
  }

  return (
    <LayoutTile className="overflow-y-auto">
      <LayoutHeader
        title="Settings"
        description={
          isAdmin
            ? 'Configure your user and organization preferences.'
            : 'Configure your user preferences.'
        }
      />
      <div className="space-y-6 md:p-0">
        <div className="space-y-6">
          {isAdmin && (
            <Card className="p-4 md:p-6">
              <div className="flex flex-col space-y-1.5 mb-6">
                <h3 className="font-semibold leading-none tracking-tight">
                  Organization settings
                </h3>
                <P className="text-sm text-muted-foreground">
                  This section is only available to organization Admins.
                </P>
              </div>
              <div className="space-y-6">
                <PerformanceCycleEditor />
                <div className="space-y-2">
                  <Label>Integrations</Label>
                  <div className="flex flex-col gap-4">
                    {organizationIntegrations.map((integration) => (
                      <div
                        key={integration.id}
                        className="flex items-center justify-between"
                      >
                        <div className="flex flex-col flex-1">
                          <div className="flex items-center justify-between">
                            <div className="flex items-center gap-3">
                              <img
                                src={integration.logo}
                                alt={integration.name}
                                className="w-4 h-4"
                              />
                              <span className="text-sm">
                                {integration.name}
                              </span>
                            </div>
                            {integration.isDisabled ? (
                              <TooltipWithIcon
                                content={integration.disabledReason || ''}
                                type="warning"
                              >
                                <Button
                                  onClick={integration.onConnect}
                                  variant="outline"
                                  disabled
                                >
                                  Connect
                                </Button>
                              </TooltipWithIcon>
                            ) : (
                              <Button
                                onClick={integration.onConnect}
                                variant={
                                  integration.isConnected
                                    ? 'outline'
                                    : 'default'
                                }
                                disabled={integration.isConnected}
                              >
                                {integration.isConnected
                                  ? 'Connected'
                                  : 'Connect'}
                              </Button>
                            )}
                          </div>
                          <P className="text-xs text-muted-foreground">
                            {integration.description}
                          </P>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </Card>
          )}

          <Card className="p-4 md:p-6">
            <div className="flex flex-col space-y-1.5 mb-6">
              <h3 className="font-semibold leading-none tracking-tight">
                User settings
              </h3>
            </div>
            <div className="space-y-6">
              <div className="space-y-2">
                <div className="flex items-center gap-2">
                  <Label htmlFor="preferred-language">Preferred Language</Label>
                </div>
                <P className="text-xs text-muted-foreground">
                  This is the language used by Fiddy to communicate with you.
                </P>
                <Select
                  id="preferred-language"
                  options={LANGUAGE_OPTIONS}
                  value={normalizeLanguageValue(userLanguage)}
                  onValueChange={handleUserLanguageChange}
                  placeholder="Auto-detect"
                  className="w-full"
                />
              </div>
              <div className="space-y-2">
                <Label>Integrations</Label>
                <div className="flex flex-col gap-4">
                  {userIntegrations.map((integration) => (
                    <div
                      key={integration.id}
                      className="flex items-center justify-between"
                    >
                      <div className="flex flex-col flex-1">
                        <div className="flex items-center justify-between">
                          <div className="flex items-center gap-3">
                            <img
                              src={integration.logo}
                              alt={integration.name}
                              className="w-4 h-4"
                            />
                            <span className="text-sm">{integration.name}</span>
                          </div>
                          {integration.isDisabled ? (
                            <TooltipWithIcon
                              content={integration.disabledReason || ''}
                              type="warning"
                            >
                              <Button
                                onClick={integration.onConnect}
                                variant="outline"
                                disabled
                              >
                                Connect
                              </Button>
                            </TooltipWithIcon>
                          ) : (
                            <Button
                              onClick={integration.onConnect}
                              variant={
                                integration.isConnected ? 'outline' : 'default'
                              }
                              disabled={integration.isConnected}
                            >
                              {integration.isConnected
                                ? 'Connected'
                                : 'Connect'}
                            </Button>
                          )}
                        </div>
                        <P className="text-xs text-muted-foreground">
                          {integration.description}
                        </P>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </LayoutTile>
  )
}
