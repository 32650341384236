import { z } from 'zod'

const PerformanceReviewObjective = z.object({
  id: z.string(),
  completion: z
    .number({ message: 'Invalid' })
    .min(0, { message: 'Must be positive' })
    .int(),
  title: z.string(),
  comments: z.string(),
})

const PerformanceReviewValue = z.object({
  id: z.string(),
  rating: z.number().min(0).max(5).int(),
  label: z.string(),
  comments: z.string(),
})

export const PerformanceReviewCreateSchema = z.object({
  userId: z.string(),
  objectives: z.array(PerformanceReviewObjective),
  values: z.array(PerformanceReviewValue),
  managerReview: z.string().optional(),
  actionPlan: z.string().optional(),
  status: z.enum(['draft', 'submitted', 'published']).optional(),
  performanceCycleId: z.string(),
})

export const PerformanceReviewPatchSchema =
  PerformanceReviewCreateSchema.partial()

export const SelfAssessmentSchema = z.object({
  performanceCycleId: z.string(),
  userId: z.string(),
  objectives: z.string().min(1, 'Objectives self-assessment cannot be empty'),
  values: z.string().min(1, 'Values self-assessment cannot be empty'),
  status: z
    .enum(['pending_manager_review', 'submitted', 'published'])
    .optional(),
})

export const SelfAssessmentPatchSchema = SelfAssessmentSchema.partial()
