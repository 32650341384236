import { ScrollArea } from '@/components/ui/scroll-area'
import { P } from '@/components/ui/typography'
import { Badge } from '@/components/ui/badge'
import { DateRange } from 'react-day-picker'
import { Loading } from '@/components/ui/loading'
import { Sheet } from '@/components/common/sheet'

import {
  useFetchUserFeedbackStats,
  type FeedbackGiverStats,
} from '@/services/api/feedbackPoints.api'
import { getUserDisplayName } from '@/services/utils/formatters'

import { UserProfile } from '@/types/UserProfile'

interface FeedbackPointsSheetProps {
  isOpen: boolean
  setIsOpen: (_open: boolean) => void
  user: UserProfile
  dateRange: DateRange
}

const getReplyRateColor = (rate: number) => {
  if (rate < 30) return 'text-red-300'
  if (rate < 60) return 'text-orange-300'
  return 'text-green-500'
}

const FeedbackGiverItem = ({ giver }: { giver: FeedbackGiverStats }) => {
  const replyRate =
    giver.total_feedback > 0
      ? Math.round(
          (giver.feedback_with_interaction / giver.total_feedback) * 100,
        )
      : 0

  return (
    <div className="py-3 border-b last:border-b-0">
      <div className="flex items-center gap-2">
        <P className="font-medium">
          {giver.firstName} {giver.lastName}
        </P>
        {giver.is_direct_report && (
          <Badge variant="outline" className="text-xs">
            Direct Report
          </Badge>
        )}
      </div>
      <div className="flex items-center gap-1 text-sm text-muted-foreground">
        <span>
          {giver.feedback_with_interaction} responses out of{' '}
          {giver.total_feedback} feedback requests
        </span>
        <span className={getReplyRateColor(replyRate)}>
          ({replyRate}% reply rate)
        </span>
      </div>
    </div>
  )
}

export function FeedbackPointsSheet({
  isOpen,
  setIsOpen,
  user,
  dateRange,
}: FeedbackPointsSheetProps) {
  const { data: givers = [], isLoading } = useFetchUserFeedbackStats(
    user.id,
    dateRange,
    {
      enabled: isOpen,
    },
  )

  const sortedGivers = [...givers].sort(
    (a, b) => b.total_feedback - a.total_feedback,
  )
  const totalFeedback = givers.reduce(
    (sum, giver) => sum + giver.total_feedback,
    0,
  )
  const totalInteractions = givers.reduce(
    (sum, giver) => sum + giver.feedback_with_interaction,
    0,
  )
  const overallReplyRate =
    totalFeedback > 0
      ? Math.round((totalInteractions / totalFeedback) * 100)
      : 0

  return (
    <Sheet
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title={`Feedback Points for ${getUserDisplayName(user)}`}
      width="w-[90vw] sm:max-w-[600px]"
    >
      {!isLoading && (
        <div className="flex items-center gap-1 text-sm text-muted-foreground">
          <span>
            Overall: {totalInteractions} responses out of {totalFeedback}{' '}
            feedback requests
          </span>
          <span className={getReplyRateColor(overallReplyRate)}>
            ({overallReplyRate}% reply rate)
          </span>
        </div>
      )}
      <ScrollArea className="h-[calc(100vh-150px)] mt-6">
        {isLoading ? (
          <div className="flex justify-center items-center h-full">
            <Loading />
          </div>
        ) : (
          <div className="divide-y">
            {sortedGivers.map((giver) => (
              <FeedbackGiverItem key={giver.id} giver={giver} />
            ))}
          </div>
        )}
      </ScrollArea>
    </Sheet>
  )
}
