import { useState } from 'react'
import { Label } from '@radix-ui/react-label'
import { Users, Loader2 } from 'lucide-react'

import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { H6, P } from '@/components/ui/typography'

import { useGetCheckoutUrl } from '@/services/api/billing.api'

function PurchaseLicensesCard() {
  const [count, setCount] = useState(1)
  const getCheckoutUrl = useGetCheckoutUrl()

  const calculatePrice = (userCount: number) => {
    if (userCount < 10) {
      return userCount * 8
    }
    // If 10 or more users, all users are charged at $5
    return userCount * 5
  }

  const pricePerUser = count >= 10 ? 5 : 8
  const totalPrice = calculatePrice(count || 0)

  async function onPurchaseClicked() {
    const promotekitReferral = (
      window as unknown as { promotekit_referral: string }
    ).promotekit_referral

    const url = await getCheckoutUrl.mutateAsync({
      quantity: count,
      promotekitReferral: promotekitReferral,
    })
    window.location.href = url
  }

  return (
    <div className="flex items-center justify-between w-full ml-1">
      <div>
        <H6 className="font-medium">License Usage</H6>
        <div className="flex flex-col sm:flex-row items-start sm:items-center gap-2 mt-1">
          <div className="flex items-center gap-2">
            <Input
              min={1}
              className="w-[120px]"
              type="number"
              onChange={(e) => setCount(e.target.valueAsNumber)}
              value={count}
            />
            <P className="text-sm text-muted-foreground whitespace-nowrap">
              users @ ${pricePerUser}/user/mo
            </P>
          </div>
          <div className="flex items-center gap-2">
            <P className="text-sm text-muted-foreground">Total:</P>
            <Label className="font-bold">${totalPrice}.00/month</Label>
          </div>
        </div>
        {count < 10 && (
          <P className="text-sm text-muted-foreground mt-1">
            Purchase 10 or more licenses to upgrade to the Startup plan
            ($5/user/mo)
          </P>
        )}
      </div>
      <Button
        className="bg-primary rounded-lg whitespace-nowrap"
        disabled={getCheckoutUrl.isPending}
        onClick={onPurchaseClicked}
      >
        {getCheckoutUrl.isPending ? (
          <Loader2 className="mr-2 h-4 w-4 animate-spin" />
        ) : (
          <Users className="mr-2 h-4 w-4" />
        )}
        Purchase licenses
      </Button>
    </div>
  )
}

export default PurchaseLicensesCard
