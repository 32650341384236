import { cn } from '@/lib/utils'
import { MetricType } from '@/types/Objective'
import { formatValue } from './objective-row'

interface WeekValueProps {
  value: number | undefined
  metricType: MetricType
  onClick: () => void
}

export function WeekValue({ value, metricType, onClick }: WeekValueProps) {
  return (
    <button
      onClick={(e) => {
        e.stopPropagation()
        onClick?.()
      }}
      className={cn(
        'flex-1',
        'week-value w-[55px] px-2 py-1 rounded text-sm',
        'hover:bg-green-600 hover:text-white hover:font-medium active:bg-accent',
        'transition-colors cursor-pointer',
        'ring-offset-background',
        'hover:ring-2 hover:ring-green-600/20 hover:ring-offset-2',
        value === null && 'text-muted-foreground',
      )}
    >
      {formatValue(value, metricType)}
    </button>
  )
}
