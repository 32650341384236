import { UserProfile } from '@/types/UserProfile'
import { PerformanceCycle } from '@/types/PerformanceCycle'
import { sum, average } from '@/services/utils/math'
import { getMonthsFromPerformanceCycle } from '@/services/utils/dates'

export type ObjectiveType = 'average' | 'lastColumn' | 'sum'
export type MetricType = 'number' | 'currency' | 'percentage'

export const TYPE_LABELS: Record<ObjectiveType, string> = {
  average: 'Average',
  lastColumn: 'Last Column',
  sum: 'Sum',
}

export type ObjectiveValue = {
  createdAt?: string
  updatedAt?: string
  week: number
  year: number
  value: number
  teamObjectiveId: string
}

export type TeamObjectiveTarget = {
  target: number
  month: number
  year: number
  teamObjectiveId: string
}

export interface Objective {
  id: string
  createdAt: string
  updatedAt: string
  organizationId: string
  title: string
  description: string
  type: ObjectiveType
  metricType: MetricType
  target: number
  values: ObjectiveValue[]
  targets: TeamObjectiveTarget[]
  owners: UserProfile[]
  performanceCycle: PerformanceCycle
}

export interface Comment {
  id: string
  owner: UserProfile
  content: string
  createdAt: string
}

export interface MonthlyProgress {
  label: string
  month: number
  year: number
  target: number
  achieved: number
  weeks: ObjectiveValue[]
}

export interface ObjectiveUI {
  id: string
  title: string
  owners: UserProfile[]
  type: ObjectiveType
  metricType: MetricType
  monthlyProgress: MonthlyProgress[]
  isExpanded?: boolean
  comments?: Comment[]
  targets: TeamObjectiveTarget[]
  performanceCycle: PerformanceCycle
  description: string
}

export const getValueId = (year: number, week: number) => `V-Y${year}-W${week}`
export const getMonthId = (year: number, week: number) => `M-Y${year}-W${week}`

// Helper function to group values by month
export const groupValuesByMonth = (
  objective: Objective,
  performanceCycle: PerformanceCycle,
): MonthlyProgress[] => {
  const months = getMonthsFromPerformanceCycle(performanceCycle)

  // Group values by month
  const valuesRecord: Record<string, ObjectiveValue> = Object.fromEntries(
    objective.values.map((value) => {
      const id = getValueId(value.year, value.week)
      return [id, value]
    }),
  )

  const result = months.map((month) => {
    const weeks: ObjectiveValue[] = month.weeks.map((interval) => ({
      week: interval.week,
      year: interval.year,
      value: valuesRecord[getValueId(interval.year, interval.week)]?.value,
      teamObjectiveId: objective.id,
    }))
    const values = weeks
      .map((week) => week.value)
      .filter((value) => !!value) as number[]
    const achieved =
      objective.type === 'sum'
        ? sum(values)
        : objective.type === 'average'
          ? average(values)
          : (values[values.length - 1] ?? 0)

    return {
      label: month.label,
      month: month.month,
      year: month.year,
      achieved,
      target: objective.target,
      weeks,
    }
  })

  return result
}

export const convertToObjectiveUI = (
  performanceCycle: PerformanceCycle,
  objectives: Objective[],
): ObjectiveUI[] => {
  const arr = []
  for (const objective of objectives) {
    const monthlyProgress = groupValuesByMonth(objective, performanceCycle)

    arr.push({
      id: objective.id,
      title: objective.title,
      owners: objective.owners,
      type: objective.type,
      metricType: objective.metricType,
      monthlyProgress,
      isExpanded: false,
      targets: objective.targets ?? [],
      description: objective.description,
      performanceCycle,
    })
  }

  return arr
}

export function getFlatWeeksFromMonthlyProgress(
  monthlyProgress: MonthlyProgress[],
): ObjectiveValue[] {
  return monthlyProgress.reduce((acc: ObjectiveValue[], month) => {
    return [...acc, ...month.weeks]
  }, [])
}
