import React, { useEffect } from 'react'
import { CallBackProps, STATUS, EVENTS, Step } from 'react-joyride'
import { useLocalStorage } from '@/hooks/useLocalStorage'
import { useNavigate } from 'react-router-dom'
import { useUserRole } from '@/hooks/useUserRole'
import { useFetchTeamMembers } from '@/services/api/organization.api'

const STEP_TO_ROUTE: Record<number, string> = {
  0: '/dashboard', // welcome-title
  1: '/feedback-points', // give-feedback-button
  2: '/feedback-points', // feedback-points-container
  3: '/feedback-sessions', // feedback-sessions-title
  4: '/dashboard', // personal-dashboard-stats
  5: '/performance-review/team', // team-reviews-title
  6: '/performance-review/team', // team-reviews-list
  7: '/performance-review/team', // nine-box-grid
  8: '/management-dashboard', // management-dashboard-filters
  9: '/objectives', // objectives-title
}

export const useTourSteps = () => {
  const { isAdmin } = useUserRole()
  const { data: teamMembersUnderUser = [] } = useFetchTeamMembers({
    teamScope: 'underUser',
  })
  const isManager = teamMembersUnderUser.length > 0 || isAdmin

  const baseSteps: Step[] = [
    {
      target: '.welcome-title',
      content:
        'Welcome to FidForward! Let us walk you through how to get the most out of your performance management experience.',
      disableBeacon: true,
    },
    {
      target: '.give-feedback-button',
      content:
        'Start by giving feedback to Fiddy through our Slack bot. This is the easiest way to capture feedback moments with your colleagues.',
    },
    {
      target: '.feedback-points-container',
      content:
        'All your feedback points are stored here. Click on any feedback to see its summary and our quality assessment.',
    },
    {
      target: '.feedback-sessions-title',
      content:
        'Feedback sessions consolidate all feedback points into comprehensive reports that can be shared with team members.',
    },
    {
      target: '.personal-dashboard-stats',
      content:
        'Track your own performance metrics and feedback trends over time using different date ranges.',
    },
  ]

  const managerSteps: Step[] = [
    {
      target: '.team-reviews-title',
      content:
        "In the Team Reviews page, you can find the final step of the feedback cycle: assessing your team's performance.",
    },
    {
      target: '.team-reviews-list',
      content:
        'Write performance reviews in under a minute using our pre-processed information from feedback sessions.',
    },
    {
      target: '.nine-box-grid',
      content:
        'Use the 9-box grid to calibrate all employees and ensure fair, consistent evaluations across your organization.',
    },
    {
      target: '.management-dashboard-filters',
      content:
        "As a manager, analyze your team's performance by selecting specific team members and time periods.",
    },
    {
      target: '.objectives-title',
      content:
        'Set and track company objectives, allowing managers to cascade goals throughout their teams.',
    },
  ]

  return isManager ? [...baseSteps, ...managerSteps] : baseSteps
}

export const useTour = () => {
  const navigate = useNavigate()
  const [isTourOpen, setIsTourOpen] = React.useState(false)
  const [hasSeenTour, setHasSeenTour] = useLocalStorage('hasSeenTour', false)
  const [isNavigating, setIsNavigating] = React.useState(false)
  const [currentStep, setCurrentStep] = React.useState(0)
  const steps = useTourSteps()

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status, index, type, action } = data
    if (
      [STATUS.FINISHED, STATUS.SKIPPED].includes(
        status as 'finished' | 'skipped',
      )
    ) {
      setIsTourOpen(false)
      setHasSeenTour(true)
      return
    }

    if (type === EVENTS.STEP_AFTER && !isNavigating) {
      setIsNavigating(true)
      const nextIndex = action === 'prev' ? index - 1 : index + 1
      setCurrentStep(nextIndex)

      const targetRoute = STEP_TO_ROUTE[nextIndex]
      if (targetRoute) {
        navigate(targetRoute)
        setTimeout(() => {
          setIsNavigating(false)
        }, 500)
      } else {
        setIsNavigating(false)
      }
    }
  }

  useEffect(() => {
    if (!hasSeenTour) {
      const timer = setTimeout(() => {
        setIsTourOpen(true)
      }, 1000)
      return () => clearTimeout(timer)
    }
  }, [hasSeenTour])

  return {
    steps,
    isOpen: isTourOpen && !isNavigating,
    currentStep,
    handleCallback: handleJoyrideCallback,
  }
}
