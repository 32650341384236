import { useEffect } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'

import { H5 } from '@/components/ui/typography'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Loading } from '@/components/ui/loading'

import {
  TeamObjectiveValueSchema,
  TeamObjectiveValueSchemaType,
} from '@/lib/schema/objective-form.schema'
import { useDebounce } from '@/hooks/useDebouncer'

export const ObjectiveValueForm = ({
  defaultValues,
  onSubmit,
  isSaving,
}: {
  defaultValues: TeamObjectiveValueSchemaType
  onSubmit: (_values: TeamObjectiveValueSchemaType) => void
  isSaving?: boolean
}) => {
  const form = useForm<TeamObjectiveValueSchemaType>({
    resolver: zodResolver(TeamObjectiveValueSchema),
    values: defaultValues,
  })

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    form.handleSubmit(onSubmit)()
  }

  const debouncedSubmit = useDebounce(onSubmit, 200)
  useEffect(() => {
    const subscription = form.watch(() => {
      const values = form.getValues()
      form.trigger().then((isValid) => {
        if (isValid) {
          debouncedSubmit(values)
        }
      })
    })
    return () => subscription.unsubscribe()
  }, [form, debouncedSubmit])

  return (
    <div className="flex flex-col gap-2">
      <div className="flex gap-2 items-center">
        <H5>{`Week ${defaultValues.week}`}</H5>
        {!!isSaving && <Loading className="w-5 h-5" />}
      </div>
      <Form {...form}>
        <form
          onSubmit={handleSubmit}
          className="space-y-3 flex items-end gap-2"
        >
          <FormField
            control={form.control}
            name="value"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Value</FormLabel>
                <FormControl>
                  <Input
                    type="number"
                    {...field}
                    value={field.value ?? ''}
                    onChange={(e) => {
                      const value =
                        e.target.value === '' ? null : Number(e.target.value)
                      field.onChange(value)
                    }}
                  />
                </FormControl>
              </FormItem>
            )}
          />
        </form>
      </Form>
    </div>
  )
}
