import { useState, useEffect } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'

import { Textarea } from '@/components/ui/textarea'
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { SavedBadge } from '@/components/common/saved-badge'

import { useDebounce } from '@/hooks/useDebouncer'
import {
  PerformanceReviewCommentSchema,
  PerformanceReviewCommentType,
} from '@/lib/schema/performance-review-comment.schema'
import { usePatchPerformanceReviewComment } from '@/services/api/performanceReview.api'

export const PerformanceReviewComment = ({
  defaultValues,
}: {
  defaultValues: PerformanceReviewCommentType
}) => {
  const [showSaved, setShowSaved] = useState(false)
  const form = useForm<PerformanceReviewCommentType>({
    resolver: zodResolver(PerformanceReviewCommentSchema),
    defaultValues,
  })

  const { mutate: patchPerformanceReviewComment } =
    usePatchPerformanceReviewComment()

  function onSubmit(comment: PerformanceReviewCommentType) {
    setShowSaved(true)
    patchPerformanceReviewComment({
      performanceReviewId: comment.performanceReviewId,
      body: { text: comment.text },
    })
  }

  const debouncedSubmit = useDebounce(onSubmit, 200)
  useEffect(() => {
    const subscription = form.watch(() => {
      const values = form.getValues()
      form.trigger().then((isValid) => {
        if (isValid) {
          debouncedSubmit(values)
        }
      })
    })
    return () => subscription.unsubscribe()
  }, [form, debouncedSubmit])

  return (
    <Form {...form}>
      <form>
        <FormField
          control={form.control}
          name="text"
          render={({ field }) => (
            <FormItem>
              <div className="flex items-center gap-2">
                <FormLabel>Your Personal Notes</FormLabel>
                <SavedBadge show={showSaved} setShow={setShowSaved} />
              </div>
              <FormControl>
                <Textarea {...field} maxLength={500} rows={4} />
              </FormControl>
              <FormMessage />
              <FormDescription>
                {`Use this space to add any personal notes about the review. These won't be shared with anyone.`}
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
      </form>
    </Form>
  )
}
